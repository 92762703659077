import { Logger } from 'splunk-logging';
import { LoggerClient } from './client';

interface CreateSplunkLoggerConfig {
  splunkEventCollectorToken: string;
  splunkEventCollectorUrl: string;
}

type Method = 'error' | 'info' | 'warn' | 'debug';
type Message = string;
type MsgContext = object;
type LogArgs = [Method, Message, MsgContext?];

/**
 * Creates a Splunk HTTP Event Collector logger for the browser
 * @param CreateSplunkLoggerConfig
 * @returns LoggerClient
 */
export const createSplunkLogger = ({
  splunkEventCollectorToken,
  splunkEventCollectorUrl,
}: CreateSplunkLoggerConfig): LoggerClient | undefined => {
  const isConfigured = splunkEventCollectorToken && splunkEventCollectorUrl;

  if (!isConfigured) {
    console.warn('Splunk settings misconfiguration!!');
    return;
  }

  const logger = new Logger({
    token: splunkEventCollectorToken,
    url: splunkEventCollectorUrl,
    maxRetries: 5,
  });

  // Custom formatting of log events
  logger.eventFormatter = (message, severity) => {
    return {
      name: 'checkout',
      ...message,
      severity,
    };
  };

  /**
   * Send logs to Splunk when in browser context
   * @param args
   * @returns void
   */
  function sendLog(...args: LogArgs): void {
    const [level, message, context] = args;
    // We only need to send logs when on the client
    // stdout is already logged automatically
    if (typeof window === 'undefined') {
      return;
    }

    logger.send({
      severity: level,
      message: {
        msg: message,
        ...context,
      },
      metadata: { ...context },
    });
  }

  const splunkLogger: LoggerClient = {
    error: (msg, context) => {
      sendLog('error', msg, context);
    },
    info: (msg, ctx) => {
      sendLog('info', msg, ctx);
    },
    warn: (msg, ctx) => {
      sendLog('warn', msg, ctx);
    },
    debug: (msg, ctx) => {
      sendLog('debug', msg, ctx);
    },
  };

  return splunkLogger;
};
