import { useRef, useEffect } from 'react';
import { PageNames, track, AnalyticsEvent } from '../../../lib/analytics';
import { useLoadingManager } from '../../LoadingManager';

/**
 * Allows tracking of analytics pages from RouteMachineService.
 * Waits for loading manager to be ready before running tracking queue
 * @returns (analyticsPageName?: PageNames) => void
 */
export const useTrackPageView = (): ((
  analyticsPageName?: PageNames
) => void) => {
  const pagesQueue = useRef<PageNames[]>([]);
  const isReady = useRef<boolean>(false);
  const { loading } = useLoadingManager();

  const trackPageView = (analyticsPageName?: PageNames): void => {
    if (analyticsPageName) {
      pagesQueue.current.push(analyticsPageName);
    }

    // Always read queue when ready
    if (isReady.current) {
      while (pagesQueue.current.length > 0) {
        const pageName = pagesQueue.current.shift();
        track(AnalyticsEvent.PageLoaded, {
          page: { title: pageName },
        });
      }
    }
  };

  useEffect(() => {
    // Track loading manager state
    if (!loading) {
      isReady.current = true;
      trackPageView();
    } else {
      isReady.current = false;
    }
  }, [loading]);

  return trackPageView;
};
