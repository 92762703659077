import { useEffect } from 'react';
import { useApplicationMachine } from '../../ApplicationMachineContext';
import { RouteMachineServiceEventEmitter } from '../types/events';

/**
 * Subscribes to the application machine state and emits events to be handled
 * based on those states.
 * @param routingMachineService RouteMachineServiceEventEmitter
 */
export const useApplicationMachineStateEventEmitter = (
  routingMachineService: RouteMachineServiceEventEmitter
): void => {
  const { state: applicationState } = useApplicationMachine();

  useEffect(() => {
    if (applicationState.matches('errors')) {
      // Application in an error state
      routingMachineService.send({
        type: 'SEND_ROUTE_APPLICATION_ERROR',
        params: {
          state: applicationState,
        },
      });
      return;
    }

    if (
      applicationState.matches({ ready: { needsIDVerification: 'initial' } }) ||
      applicationState.matches({
        ready: { needsIDVerificationAlloy: 'initial' },
      })
    ) {
      // Self cure
      routingMachineService.send({
        type: 'SEND_ROUTE_APPLICATION_NEEDS_ID_VERIFICATION',
      });
    } else if (applicationState.matches({ ready: 'needsFullIIN' })) {
      routingMachineService.send({
        type: 'SEND_ROUTE_APPLICATION_NEEDS_FULL_IIN',
      });
    }
  }, [applicationState]);
};
