import { createContext, useContext, useEffect, useState } from 'react';
import alloy from '@alloyidentity/web-sdk';
import { shouldUseMockAPI } from '@ads-bread/shared/bread/mirage';
import { isLeft } from 'fp-ts/lib/Either';
import { NeuroIDResponse } from '@ads-bread/shared/bread/codecs';
import { FCWithChildren } from '../lib/types';
import { createLogger } from '../lib/logger/pino';
import { getFraudAlloy } from '../lib/handlers/get-fraud-alloy';
import { UnauthorizedError, useFetch } from '../lib/hooks/apiFetch';
import { useCloseModal } from '../lib/hooks/useCloseModal';
import { useApplicationMachine } from './ApplicationMachineContext';
import { useAppConfig } from './AppConfigContext';
import { useMerchantConfig } from './MerchantContext';
import { useAuthentication } from './AuthenticationMachineContext';
import { useFeatureFlags } from './FeatureFlagsContext';
import { useLoadingManager } from './LoadingManager';

declare let window: Window & {
  Cypress?: Record<string, string>;
  handleFromCypress?: (req: never) => Promise<never[]> | Promise<unknown>;
  STORYBOOK_ENV: string;
};

const logger = createLogger({
  name: 'checkout.fraud.alloy',
});

interface AlloyTokens {
  journeyToken: string;
  journeyApplicationToken: string;
  sdkKey: string;
}

interface AlloyInitError {
  state: 'error';
  error: Error | unknown;
  isAuthError: boolean;
}

interface AlloySDKData {
  journey_application_status: 'Denied' | 'Approved';
  status: 'completed';
}

function shouldUseMockProvider() {
  return Boolean(
    process.env.NEXT_PUBLIC_USE_FAKETREE?.toLowerCase() === 'true' ||
      (window.Cypress && window.handleFromCypress) ||
      window.STORYBOOK_ENV ||
      shouldUseMockAPI()
  );
}

type MockAlloy = typeof alloy;
const mockAlloy: MockAlloy = {
  init: (_: unknown) => {
    return Promise.resolve({
      neuroIdSiteId: 'foobarbaz',
      neuroUserId: 'gordon.freeman@blackmesa.gov',
    });
  },
  open: (cb: (data: unknown) => void, anchorElement?: string) => {
    const container = document.getElementById(anchorElement || '');
    if (!container) {
      throw new Error('Could not find container for mock alloy!');
    }

    const buttonClasses = [
      'button-text-theme-primary',
      'bg-theme-primary',
      'border',
      'button-border-theme-primary',
      'm-2',
      'font-bold',
      'p-2',
      'drop-shadow-[0_5px_0px_rgba(78,92,111,0.25)]',
    ];
    const approvedButtonElement = document.createElement('button');
    approvedButtonElement.classList.add(...buttonClasses);
    approvedButtonElement.setAttribute('data-cy', 'alloy-simulate-approved');
    approvedButtonElement.textContent = 'Simulate Approved';
    approvedButtonElement.addEventListener('click', () => {
      const d: AlloySDKData = {
        journey_application_status: 'Approved',
        status: 'completed',
      };
      cb(d);
    });

    const deniedButtonElement = document.createElement('button');
    deniedButtonElement.classList.add(...buttonClasses);
    deniedButtonElement.setAttribute('data-cy', 'alloy-simulate-denied');
    deniedButtonElement.textContent = 'Simulate Denied';
    deniedButtonElement.addEventListener('click', () => {
      const d: AlloySDKData = {
        journey_application_status: 'Denied',
        status: 'completed',
      };
      cb(d);
    });

    container.append(approvedButtonElement);
    container.append(deniedButtonElement);
  },
  close: () => {
    // noOp
  },
  createJourneyApplication: (_: unknown) => {
    return Promise.resolve();
  },
  createEvaluation: (_: unknown) => {
    return Promise.resolve();
  },
  getPublicUrl: () => '',
};

export interface NeuroIdTokens {
  neuroIdSiteId: string;
  neuroUserId: string;
}

interface FraudAlloyContext {
  initializeFraudAlloy: () => Promise<void | AlloyInitError>;
  launchAlloy: (anchor: string, cb?: () => void) => void;
  initializeNeuroId: () => void;
  alloyTokens: AlloyTokens;
  isInit: boolean;
  isLaunched: boolean;
  isNeuroIdInit: boolean;
  neuroIdTokens: NeuroIdTokens;
}

const FraudAlloyContext = createContext<FraudAlloyContext>({
  initializeFraudAlloy: () => {
    throw new Error('initializeFraudAlloy not implemented');
  },
  launchAlloy: () => {
    throw new Error('launchAlloy not implemented');
  },
  initializeNeuroId: () => {
    throw new Error('initializeNeuroId not implemented');
  },
  alloyTokens: {
    journeyToken: '',
    journeyApplicationToken: '',
    sdkKey: '',
  },
  isInit: false,
  isLaunched: false,
  isNeuroIdInit: false,
  neuroIdTokens: {
    neuroIdSiteId: '',
    neuroUserId: '',
  },
});

export const FraudAlloyProvider: FCWithChildren = ({ children }) => {
  const apiFetch = useFetch();
  const { application, alloyIDVerificationSuccess, alloyIDVerificationError } =
    useApplicationMachine();
  const {
    environment,
    fraudAlloyBaseAPIKey,
    fraudAlloyBaseJourneyToken,
    tenant,
  } = useAppConfig();
  const { checkoutPrimaryColor, checkoutSecondaryColor } = useMerchantConfig();
  const { logout, logoutUnauthorized } = useAuthentication();
  const { closeModal } = useCloseModal();

  const [isInit, setIsInit] = useState<boolean>(false);
  const [isLaunched, setIsLaunched] = useState<boolean>(false);
  const [alloyTokens, setAlloyTokens] = useState<AlloyTokens>({
    journeyToken: '',
    journeyApplicationToken: '',
    sdkKey: '',
  });
  const [isNeuroIdInit, setIsNeuroIdInit] = useState<boolean>(false);
  const [neuroIdTokens, setNeuroIdTokens] = useState<{
    neuroIdSiteId: string;
    neuroUserId: string;
  }>({
    neuroIdSiteId: '',
    neuroUserId: '',
  });
  const { enableAlloyJourney } = useFeatureFlags();
  const { isLoaded } = useLoadingManager();

  /**
   * Makes a request to the Fraud system for the credentials needed
   * for Alloy and exposes those via the context. Fraud response
   * values are snake case and this also converts them to lower camel.
   */
  const initializeFraudAlloy = async (): Promise<void> => {
    try {
      if (isInit) {
        throw new Error('initializeFraudAlloy called when already initialized');
      }

      if (!application || !application.id) {
        throw new Error('Application ID not found');
      }

      const res = await getFraudAlloy(application.id, apiFetch);

      if (res.error) {
        if (res.error instanceof UnauthorizedError) {
          logoutUnauthorized();
        }

        throw res.error;
      }

      const {
        journey_token: journeyToken,
        journey_application_token: journeyApplicationToken,
        journey_sdk_key: sdkKey,
      } = res.result;

      setIsInit(true);
      setAlloyTokens({
        journeyToken,
        journeyApplicationToken,
        sdkKey,
      });
    } catch (error) {
      logger.error({ error }, 'Error initializing Fraud Alloy');
    }
  };

  /**
   * Calls alloy init without params to initialize NeuroID monitors
   */
  const initializeNeuroId = async (): Promise<void> => {
    if (tenant !== 'ADS') {
      return;
    }

    if (isNeuroIdInit) {
      logger.warn('initializeNeuroId called when already initialized');
      return;
    }

    if (!fraudAlloyBaseAPIKey || !fraudAlloyBaseJourneyToken) {
      logger.error('Unable to retrieve Alloy NeuroID credentials.');
      return;
    }

    const alloyLib = shouldUseMockProvider() ? mockAlloy : alloy;

    const alloyInit = await alloyLib.init({
      key: fraudAlloyBaseAPIKey,
      production: true,
      journeyToken: fraudAlloyBaseJourneyToken,
    });

    const decoded = NeuroIDResponse.decode(alloyInit);

    if (isLeft(decoded)) {
      logger.warn(
        'Unexpected response from Alloy init. Continuing without NeuroID'
      );
      return;
    }

    const decodedNeuroID: NeuroIDResponse = decoded.right;
    const { neuroIdSiteId, neuroUserId } = decodedNeuroID;

    setIsNeuroIdInit(true);
    setNeuroIdTokens({
      neuroIdSiteId,
      neuroUserId,
    });
  };

  const areFlagsFetched = isLoaded('FEATURE_FLAGS');

  useEffect(() => {
    try {
      if (!isNeuroIdInit && areFlagsFetched && enableAlloyJourney) {
        initializeNeuroId();
      }
    } catch (error) {
      logger.error('Unexpected error initializing NeuroID.', error);
    }
  }, [areFlagsFetched]);

  /**
   * Launches the Alloy SDK using the credentials retrieved by the
   * initializeFraudAlloy function. This function should proceed that
   * call.
   *
   * @param anchor the element ID you would like the Alloy SDK to anchor
   * within.
   */
  const launchAlloy = async (anchor: string) => {
    if (isLaunched) {
      return;
    }
    if (!isInit) {
      logger.warn('launchAlloy called before Fraud Alloy is initialized');
      return;
    }

    const alloyLib = shouldUseMockProvider() ? mockAlloy : alloy;

    await alloyLib.init({
      key: alloyTokens.sdkKey,
      production: environment === 'production',
      journeyApplicationToken: alloyTokens.journeyApplicationToken,
      journeyToken: alloyTokens.journeyToken,
      /**
       * This flag is for multi applicant flows. If set to true we would need to
       * init alloy with the Entity ID of the applicant so that only their portion
       * would be displayed. As of 07/2024 we do not support multi applicant flows.
       */
      isSingleEntity: false,
      showHeader: true,
      apiUrl: 'https://docv.alloy.co/',
      appUrl: 'https://alloysdk.alloy.co',
      color: {
        primary: checkoutPrimaryColor || '',
        secondary: checkoutSecondaryColor || '',
      },
    });

    setIsLaunched(true);

    /**
     * Callback which is invoked when the Alloy modal is closed.
     *
     * @param data - The data returned by the Alloy SDK on close
     * of their modal. Set to unknown because it varies quite a bit.
     * If you terminate a mid-flow application it will be a fragmented
     * krakenjs/post-robot message. It can be null but this one is hard
     * to reproduce and I believe related to timeouts. It can also be
     * an object with timestamps and other data. I've trimmed the type
     * def down to the relevant bits in type AlloySDKData but you can
     * log the response to see the full dataset.
     */
    const openAlloyCB = (data: unknown) => {
      /*  explicitly closes the Alloy SDK to instruct Alloy to indicate to
          the dependent sub-systems that the process has completed. Without
          this the user will be stuck in a loop of ID verification. */
      alloyLib.close();
      setIsLaunched(false);

      const isAlloySDKData = (d: unknown): d is AlloySDKData =>
        typeof (d as AlloySDKData).journey_application_status === 'string';

      if (isAlloySDKData(data)) {
        if (data.journey_application_status === 'Approved') {
          alloyIDVerificationSuccess();
        } else {
          logout();

          if (closeModal) {
            closeModal();
          }
        }
      } else {
        logger.error('Alloy SDK closed with unknown data', data);
        alloyIDVerificationError();
      }
    };

    alloyLib.open(openAlloyCB, anchor);
  };

  return (
    <FraudAlloyContext.Provider
      value={{
        initializeFraudAlloy,
        alloyTokens,
        isInit,
        launchAlloy,
        isLaunched,
        initializeNeuroId,
        isNeuroIdInit,
        neuroIdTokens,
      }}
    >
      {children}
    </FraudAlloyContext.Provider>
  );
};

export const useFraudAlloy = (): FraudAlloyContext =>
  useContext(FraudAlloyContext);
