import { ParsedUrlQuery } from 'querystring';
import { AnyEventObject, MetaObject, StateValue } from 'xstate';
import { ReadonlyURLSearchParams } from 'next/navigation';
import { NamespacePath } from '@ads-bread/shared/bread/util';
import { getNamespacedPath } from '../../lib/get-namespaced-path';
import { PageNames } from '../../lib/analytics';
import { logger } from '../../lib/logger';
import { RouteMachineServiceSnapshots } from './RouteMachineService';

/**
 * Gets the route for a given state value and query
 * @param stateValue
 * @param query
 * @returns string
 */
export const getRouteFromStateValue = (
  stateValue: StateValue,
  query: ParsedUrlQuery
): string => {
  if (typeof stateValue === 'string') {
    return getNamespacedPath(stateValue, query);
  }
  return getNamespacedPath(`${Object.values(stateValue)?.[0]}`, query);
};

/**
 * Gets the state value string from a given location path
 * @param pathname
 * @param namespace
 * @param query
 * @returns string
 */
export const getStateValueFromRoute = (
  pathname: string,
  namespace: NamespacePath,
  query: ParsedUrlQuery
): string => {
  const path = removeSearchParamQueryStrings(pathname);
  const namespacePath = getNamespacedPath(namespace, query);

  return path.replace(namespacePath, namespace);
};

/**
 * Removes search param query strings from a URL
 * @param url - URL to remove search param query strings
 * @returns string
 */
export const removeSearchParamQueryStrings = (url: string): string => {
  const queryIndex = url.indexOf('?');
  if (queryIndex < 0) {
    return url;
  }
  return url.substring(0, queryIndex);
};

/**
 * Builds a query string from NextJS search params and allowable keys
 * @param searchParams ReadonlyURLSearchParams
 * @param keys string[]
 * @returns string
 */
export const getSearchParamQueryStringByKeys = (
  searchParams: ReadonlyURLSearchParams | null,
  keys: string[]
): string => {
  if (!keys.length) {
    return '';
  }

  let queryString = '';
  let matchIndex = 0;
  keys.forEach((key) => {
    const searchValue = searchParams?.get(key);
    if (searchValue) {
      queryString +=
        matchIndex === 0 ? `?${key}=${searchValue}` : `&${key}=${searchValue}`;
      matchIndex++;
    }
  });

  return queryString;
};

/**
 * Determine if the event was a logout action
 * @param event RouteMachineServiceEvents
 * @returns boolean
 */
export const isLogoutEvent = (
  inspectionEventType?: AnyEventObject
): boolean => {
  if (!inspectionEventType) {
    return false;
  }
  return inspectionEventType.type === 'SEND_RESET_ROUTER_STATE';
};

/**
 * Determine if the event was an unauthorized logout action
 * @param event RouteMachineServiceEvents
 * @returns boolean
 */
export const isLogoutUnauthorizedEvent = (
  inspectionEventType?: AnyEventObject
): boolean => {
  if (!inspectionEventType) {
    return false;
  }
  return inspectionEventType.type === 'SEND_RESET_ROUTER_STATE_UNAUTHORIZED';
};

/**
 * Interface for state meta data in routing machines
 */
export interface RouteStateMeta {
  analyticsPageName?: PageNames;
  prefetchRoutes: string[];
}

/**
 * Type guard for RouteStateMeta
 * @param meta
 * @returns boolean
 */
const isRouteStateMeta = (
  meta: MetaObject | undefined
): meta is RouteStateMeta => {
  return !!(
    meta &&
    typeof meta === 'object' &&
    Array.isArray(meta.prefetchRoutes) &&
    (typeof meta.analyticsPageName === 'string' ||
      meta.analyticsPageName === undefined)
  );
};

/**
 * Gets meta data from a router state node for processing
 * @param routeMachineId string
 * @param state Record<string, any>
 * @returns RouteStateMeta
 */
export const getRouteStateMeta = (
  routeMachineId: string,
  state: RouteMachineServiceSnapshots
): RouteStateMeta => {
  const meta: Record<string, MetaObject | undefined> = state.getMeta();

  const routeStateMeta = meta[`${routeMachineId}.${state.value}`];

  if (isRouteStateMeta(routeStateMeta)) {
    return routeStateMeta;
  }

  return {
    prefetchRoutes: [],
  };
};

/**
 * Logs the state values and event raised to reach that state to
 * help debug router issues
 * @param stateValue StateValue
 * @param event RouteMachineServiceEvents
 */
export const handleRouteServiceDebugLogging = (
  stateValue: StateValue,
  event: AnyEventObject
): void => {
  if (process.env.NEXT_PUBLIC_DEBUG_ROUTE_MACHINE_SERVICE === 'true') {
    logger.info({ stateValue, event });
  }
};
