import { Application } from '@ads-bread/shared/bread/codecs';
import { APPLICATION_STATUS_CODES } from '@ads-bread/shared/bread/util';

const ALLOWED_CHECKOUT_STATUS_CODES = [
  APPLICATION_STATUS_CODES.DecisionApprovedApprovedFull,
  APPLICATION_STATUS_CODES.DecisionApprovedApprovedPartial,
  APPLICATION_STATUS_CODES.DecisionApprovedApprovedContingent,
];

/** These status codes require ID verification if feature is enabled. */
export const APPLICATION_ID_VERIFICATION_STATUS_CODES = [
  APPLICATION_STATUS_CODES.FraudIncompleteExtendedFraudAlert,
  APPLICATION_STATUS_CODES.FraudIncompleteHighRisk,
  APPLICATION_STATUS_CODES.FraudIncompleteLowRisk,
  APPLICATION_STATUS_CODES.KYCIncompleteNeedsMoreInfo,
];

/** Returns true if the application is approved */
export const isApproved = (application: Application): boolean => {
  return Boolean(
    application.statusCodes?.some((statusCode) =>
      ALLOWED_CHECKOUT_STATUS_CODES.includes(statusCode)
    )
  );
};

/**
 * Checks an application status code and returns true if the application requires
 * a full IIN from the buyer.
 * @param application The application to check
 */
export const needsFullIIN = (application: Application): boolean => {
  return Boolean(
    application.statusCodes?.some(
      (statusCode) =>
        statusCode === APPLICATION_STATUS_CODES.DecisionIncompleteNeedsFullIIN
    )
  );
};

/**
 * Checks an application status code and returns true if the application requires
 * users to complete id verification.
 * @param application The application to check
 */
export const needsIDVerification = (application: Application): boolean => {
  return Boolean(
    application.statusCodes?.some((statusCode) =>
      APPLICATION_ID_VERIFICATION_STATUS_CODES.includes(statusCode)
    )
  );
};

/**
 * Checks an application for ineligible buyer address
 * @param application
 * @returns boolean
 */
export const isIneligibleAddress = (
  application: Application | null
): boolean => {
  return !!application?.statusCodes?.some(
    (statusCode) =>
      APPLICATION_STATUS_CODES.EligibilityIneligibleInvalidAddressPOBox ===
      statusCode
  );
};

export const APPLICATION_STATUS_CODE_ERROR_MAP = Object.entries(
  APPLICATION_STATUS_CODES
).reduce((acc, [key, val]): Record<string, string> => {
  return {
    ...acc,
    [val]: key,
  };
}, {} as Record<string, string>);
