import { useContext } from 'react';
import { StateFrom } from 'xstate';
import { useSelector } from '@xstate/react';
import {
  Application,
  Offer,
  PaymentAgreementDocument,
  IDVerificationEvaluationResponse,
} from '@ads-bread/shared/bread/codecs';
import { FetchHandlerWrapper, HandlerWrapper } from '../../lib/handlers/base';
import { ApplicationContext, applicationMachine } from './applicationMachine';
import {
  ApplicationCheckoutOptions,
  ApplicationPrepareCheckoutOptions,
  ReCreateApplicationOptions,
  SendEvaluateIDVerificationImagesOptions,
} from './types/events';
import { ApplicationMachineContext } from './ApplicationMachineContext';

export type ApplicationMachineSnapshot = StateFrom<typeof applicationMachine>;

export interface UseApplicationMachineValues extends ApplicationContext {
  state: ApplicationMachineSnapshot;
  fetchApplication: FetchHandlerWrapper<Application, string>;
  applicationCheckout: HandlerWrapper<ApplicationCheckoutOptions, Application>;
  applicationPrepareCheckout: HandlerWrapper<
    ApplicationPrepareCheckoutOptions,
    Application
  >;
  reCreateApplication: HandlerWrapper<ReCreateApplicationOptions, Application>;
  evaluateIDVerificationImages: HandlerWrapper<
    SendEvaluateIDVerificationImagesOptions,
    IDVerificationEvaluationResponse
  >;
  resetApplicationState: () => void;
  assignSelectedOffer: (selectedOffer: Offer) => void;
  assignPaymentAgreementDocument: (
    paymentAgreementDocument: PaymentAgreementDocument
  ) => void;
  assignIDVerificationFrontImageData: (frontImageData: Blob | null) => void;
  assignIDVerificationBackImageData: (backImageData: Blob | null) => void;
  alloyIDVerificationSuccess: () => void;
  alloyIDVerificationError: () => void;
}

/**
 * A hook to allow interaction with the application machine and its state
 * @returns UseApplicationValues
 */
export const useApplicationMachine = (): UseApplicationMachineValues => {
  const applicationService = useContext(ApplicationMachineContext);

  if (!applicationService) {
    throw new Error('Application machine service is null!');
  }

  const state = useSelector(applicationService, (s) => s);

  const fetchApplication: FetchHandlerWrapper<Application, string> = async (
    applicationID
  ) => {
    return new Promise((resolve, reject) => {
      applicationService.send({
        type: 'SEND_FETCH_APPLICATION',
        params: {
          applicationID,
          resolve,
          reject,
        },
      });
    });
  };

  const applicationCheckoutWrapper: HandlerWrapper<
    ApplicationCheckoutOptions,
    Application
  > = async (options) => {
    return new Promise((resolve, reject) => {
      applicationService.send({
        type: 'SEND_APPLICATION_CHECKOUT',
        params: {
          context: state.context,
          options,
          resolve,
          reject,
        },
      });
    });
  };

  const applicationPrepareCheckoutWrapper: HandlerWrapper<
    ApplicationPrepareCheckoutOptions,
    Application
  > = async (options) => {
    return new Promise((resolve, reject) => {
      applicationService.send({
        type: 'SEND_APPLICATION_PREPARE_CHECKOUT',
        params: {
          context: state.context,
          options,
          resolve,
          reject,
        },
      });
    });
  };

  const reCreateApplication: HandlerWrapper<
    ReCreateApplicationOptions,
    Application
  > = async (options) => {
    return new Promise((resolve, reject) => {
      applicationService.send({
        type: 'SEND_RECREATE_APPLICATION',
        params: {
          options,
          resolve,
          reject,
        },
      });
    });
  };

  const evaluateIDVerificationImagesWrapper: HandlerWrapper<
    SendEvaluateIDVerificationImagesOptions,
    IDVerificationEvaluationResponse
  > = async (options) => {
    return new Promise((resolve, reject) => {
      applicationService.send({
        type: 'SEND_EVALUATE_ID_VERIFICATION_IMAGES',
        params: {
          context: state.context,
          options,
          resolve,
          reject,
        },
      });
    });
  };

  const resetApplicationState = (): void => {
    applicationService.send({ type: 'SEND_RESET_APPLICATION_STATE' });
  };

  const assignSelectedOffer = (offer: Offer): void => {
    applicationService.send({
      type: 'SEND_ASSIGN_SELECTED_OFFER',
      params: {
        selectedOffer: offer,
      },
    });
  };

  const assignPaymentAgreementDocument = (
    document: PaymentAgreementDocument
  ): void => {
    applicationService.send({
      type: 'SEND_ASSIGN_PAYMENT_AGREEMENT_DOCUMENT',
      params: {
        paymentAgreementDocument: document,
      },
    });
  };

  const assignIDVerificationFrontImageData = (
    frontImageData: Blob | null
  ): void => {
    applicationService.send({
      type: 'SEND_ASSIGN_ID_VERIFICATION_FRONT_IMAGE_DATA',
      params: {
        frontImageData,
      },
    });
  };

  const assignIDVerificationBackImageData = (
    backImageData: Blob | null
  ): void => {
    applicationService.send({
      type: 'SEND_ASSIGN_ID_VERIFICATION_BACK_IMAGE_DATA',
      params: {
        backImageData,
      },
    });
  };

  const alloyIDVerificationSuccess = (): void => {
    applicationService.send({
      type: 'SEND_ID_VERIFICATION_ALLOY_SUCCESS',
    });
  };

  const alloyIDVerificationError = (): void => {
    applicationService.send({
      type: 'SEND_ID_VERIFICATION_ALLOY_ERROR',
    });
  };

  return {
    state,
    application: state.context.application,
    selectedOffer: state.context.selectedOffer,
    paymentAgreementDocument: state.context.paymentAgreementDocument,
    idVerification: state.context.idVerification,
    resetApplicationState,
    fetchApplication,
    applicationCheckout: applicationCheckoutWrapper,
    applicationPrepareCheckout: applicationPrepareCheckoutWrapper,
    evaluateIDVerificationImages: evaluateIDVerificationImagesWrapper,
    reCreateApplication,
    assignSelectedOffer,
    assignPaymentAgreementDocument,
    assignIDVerificationFrontImageData,
    assignIDVerificationBackImageData,
    alloyIDVerificationSuccess,
    alloyIDVerificationError,
  };
};
