import { assertEvent, ErrorActorEvent } from 'xstate';
import { ActionEventParams, ActorErrorParams } from '../../../apply/lib/types';
import { ApplicationContext } from './applicationMachine';
import {
  ApplicationCheckoutResult,
  ApplicationPrepareCheckoutResult,
  CreateApplicationResult,
  EvaluateApplicationErrorResult,
  EvaluateIDVerificationImagesResult,
  FetchApplicationResult,
  FetchLatestApplicationResult,
  PrepareInStoreApplicationResult,
  ReCreateApplicationResult,
  ReCreateInStoreContingentApplicationResult,
} from './types/actors';
import {
  ApplicationCheckoutActorDone,
  ApplicationPrepareCheckoutActorDone,
  CreateApplicationActorDone,
  EvaluateIDVerificationImagesActorDone,
  FetchApplicationActorDone,
  FetchLatestApplicationActorDone,
  PrepareInStoreApplicationActorDone,
  ReCreateApplicationActorDone,
  ReCreateInStoreContingentApplicationActorDone,
  SendAssignIDVerificationBackImageData,
  SendAssignIDVerificationBackImageDataParams,
  SendAssignIDVerificationFrontImageData,
  SendAssignIDVerificationFrontImageDataParams,
  SendAssignPaymentAgreementDocument,
  SendAssignPaymentAgreementDocumentParams,
  SendAssignSelectedOffer,
  SendAssignSelectedOfferParams,
  SendEvaluateApplicationErrorActorDone,
} from './types/events';

/**
 * Get SendAssignPaymentAgreementDocumentParams from SendAssignPaymentAgreementDocument event
 * @param ActionEventParams<ApplicationContext, SendAssignPaymentAgreementDocument>
 * @returns SendAssignPaymentAgreementDocumentParams
 */
export const getAssignPaymentAgreementDocument = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  SendAssignPaymentAgreementDocument
>): SendAssignPaymentAgreementDocumentParams => {
  assertEvent(event, 'SEND_ASSIGN_PAYMENT_AGREEMENT_DOCUMENT');

  return event.params;
};

/**
 * Gets SendAssignSelectedOfferParams from SendAssignSelectedOffer event
 * @param ActionEventParams<ApplicationContext, SendAssignSelectedOffer>
 * @returns SendAssignSelectedOfferParams
 */
export const getAssignSelectedOfferParams = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  SendAssignSelectedOffer
>): SendAssignSelectedOfferParams => {
  assertEvent(event, 'SEND_ASSIGN_SELECTED_OFFER');

  return event.params;
};

/**
 * Gets SendAssignIDVerificationFrontImageDataParams from
 * SendAssignIDVerificationFrontImageData event
 * @param ActionEventParams<ApplicationContext, SendAssignIDVerificationFrontImageData>
 * @returns SendAssignIDVerificationFrontImageDataParams
 */
export const getAssignIDVerificationFrontImageDataParams = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  SendAssignIDVerificationFrontImageData
>): SendAssignIDVerificationFrontImageDataParams => {
  assertEvent(event, 'SEND_ASSIGN_ID_VERIFICATION_FRONT_IMAGE_DATA');

  return event.params;
};

/**
 * Gets SendAssignIDVerificationBackImageDataParams from
 * SendAssignIDVerificationBackImageData event
 * @param ActionEventParams<ApplicationContext, SendAssignIDVerificationBackImageData>
 * @returns SendAssignIDVerificationBackImageDataParams
 */
export const getAssignIDVerificationBackImageDataParams = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  SendAssignIDVerificationBackImageData
>): SendAssignIDVerificationBackImageDataParams => {
  assertEvent(event, 'SEND_ASSIGN_ID_VERIFICATION_BACK_IMAGE_DATA');

  return event.params;
};

/**
 * Get FetchApplicationResult from FetchApplicationActorDone event
 * @param ActionEventParams<ApplicationContext,  FetchApplicationActorDone>
 * @returns FetchApplicationResult
 */
export const getFetchApplicationParams = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  FetchApplicationActorDone
>): FetchApplicationResult => {
  return event.output;
};

/**
 * Get FetchLatestApplicationResult from FetchLatestApplicationActorDone event
 * @param ActionEventParams<ApplicationContext,  FetchLatestApplicationActorDone>
 * @returns FetchLatestApplicationResult
 */
export const getFetchLatestApplicationParams = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  FetchLatestApplicationActorDone
>): FetchLatestApplicationResult => {
  return event.output;
};

/**
 * Get CreateApplicationResult from CreateApplicationDone event
 * @param ActionEventParams<ApplicationContext, CreateApplicationDone>
 * @returns CreateApplicationResult
 */
export const getCreateApplicationParams = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  CreateApplicationActorDone
>): CreateApplicationResult => {
  return event.output;
};

/**
 * Gets ReCreateApplicationResult from ReCreateApplicationActorDone event
 * @param ActionEventParams<ApplicationContext, ReCreateApplicationActorDone>
 * @returns ReCreateApplicationResult
 */
export const getReCreateApplicationParams = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  ReCreateApplicationActorDone
>): ReCreateApplicationResult => {
  return event.output;
};

/**
 * Gets EvaluateIDVerificationImagesResult from EvaluateIDVerificationImagesActorDone event
 * @param @param ActionEventParams<ApplicationContext, EvaluateIDVerificationImagesActorDone>
 * @returns EvaluateIDVerificationImagesResult
 */
export const getEvaluateIDVerificationImagesParams = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  EvaluateIDVerificationImagesActorDone
>): EvaluateIDVerificationImagesResult => {
  return event.output;
};

/**
 * Gets ApplicationCheckoutResult from ApplicationCheckoutActorDone event
 * @param ActionEventParams<ApplicationContext, ApplicationCheckoutActorDone>
 * @returns ApplicationCheckoutResult
 */
export const getApplicationCheckoutParams = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  ApplicationCheckoutActorDone
>): ApplicationCheckoutResult => {
  return event.output;
};

/**
 * Gets ApplicationPrepareCheckoutResult from ApplicationPrepareCheckoutActorDone event
 * @param ActionEventParams<ApplicationContext, ApplicationPrepareCheckoutActorDone>
 * @returns ApplicationPrepareCheckoutResult
 */
export const getApplicationPrepareCheckoutParams = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  ApplicationPrepareCheckoutActorDone
>): ApplicationPrepareCheckoutResult => {
  return event.output;
};

/**
 * Gets PrepareInStoreApplicationResult from PrepareInStoreApplicationActorDone event
 * @param ActionEventParams<ApplicationContext, PrepareInStoreApplicationActorDone>
 * @returns PrepareInStoreApplicationResult
 */
export const getPrepareInStoreApplicationParams = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  PrepareInStoreApplicationActorDone
>): PrepareInStoreApplicationResult => {
  return event.output;
};

/**
 * Gets ReCreateInStoreContingentApplicationResult from
 * ReCreateInStoreContingentApplicationActorDone event
 * @param ActionEventParams<ApplicationContext, ReCreateInStoreContingentApplicationActorDone>
 * @returns ReCreateInStoreContingentApplicationResult
 */
export const getReCreatingInStoreContingentApplication = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  ReCreateInStoreContingentApplicationActorDone
>): ReCreateInStoreContingentApplicationResult => {
  return event.output;
};

/**
 * Gets ActorErrorParams from error event
 * @param ActionEventParams<ApplicationContext, ErrorActorEvent>
 * @returns ActorErrorParams
 */
export const getServiceErrorParams = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  ErrorActorEvent
>): ActorErrorParams => {
  const errorOutput = event.error as ActorErrorParams;

  return {
    type: event.type,
    error: errorOutput.error,
    reject: errorOutput.reject,
  };
};

/**
 *
 * @param ActionEventParams<ApplicationContext, SendEvaluateApplicationErrorActorDone>
 * @returns EvaluateApplicationErrorResult
 */
export const getEvaluateApplicationErrorParams = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  SendEvaluateApplicationErrorActorDone
>): EvaluateApplicationErrorResult => {
  return event.output;
};
