import { useRef, useEffect } from 'react';
import { StateValue } from 'xstate';
import { useApplicationMachine } from '../../ApplicationMachineContext';
import { useBuyerMachine } from '../../BuyerMachineContext';
import { RouteMachineServiceEventEmitter } from '../types/events';

/**
 * Subscribes to the buyer and application machine state and emits
 * and event with the buyer is complete, and the application is approved
 * @param routingMachineService RouteMachineServiceEventEmitter
 */
export const useBuyerApplicationEventEmitter = (
  routingMachineService: RouteMachineServiceEventEmitter
): void => {
  const { state: buyerState } = useBuyerMachine();
  const { state: applicationState } = useApplicationMachine();
  const lastApplicationStateRef = useRef<StateValue>();
  const isReturningBuyerState = useRef<boolean>(false);

  // Compound state subscriber
  useEffect(() => {
    // Prevents a race condition where application finishes before buyer on initialization
    if (
      buyerState.matches('fetchingBuyer') &&
      applicationState.matches('fetchingLatestApplication')
    ) {
      isReturningBuyerState.current = true;
    }

    if (applicationState.matches({ ready: 'invalid' })) {
      // Complete buyer with invalid application
      if (buyerState.matches({ ready: { complete: 'initial' } })) {
        routingMachineService.send({
          type: 'SEND_ROUTE_INVALID_APPLICATION_COMPLETE_BUYER',
        });
        isReturningBuyerState.current = false;
        // Move buyer to next step and instead of creating another potentially invalid application
      } else if (
        buyerState.matches({ ready: { complete: 'contactUpdated' } }) ||
        buyerState.matches({
          ready: { complete: 'contactUpdatedWithoutChange' },
        })
      ) {
        routingMachineService.send({
          type: 'SEND_ROUTE_INVALID_APPLICATION_COMPLETE_BUYER',
        });
        isReturningBuyerState.current = false;
      }
      return;
    }

    // Prevents over firing of complete event when updating buyer contact information
    if (
      lastApplicationStateRef.current &&
      applicationState.matches(
        lastApplicationStateRef.current as Record<string, string>
      ) &&
      !isReturningBuyerState.current
    ) {
      return;
    }

    if (applicationState.matches({ ready: 'approved' })) {
      // Has complete buyer and approved application
      if (
        buyerState.matches({
          ready: {
            complete: 'initial',
          },
        })
      ) {
        routingMachineService.send({
          type: 'SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER',
        });
        isReturningBuyerState.current = false;
        // Updated billing address or contact information
      } else if (
        buyerState.matches({ ready: { complete: 'contactUpdated' } }) ||
        buyerState.matches({
          ready: { complete: 'contactUpdatedWithoutChange' },
        })
      ) {
        routingMachineService.send({
          type: 'SEND_ROUTE_APPROVED_COMPLETE_CONTACT_UPDATED_BUYER',
        });
        isReturningBuyerState.current = false;
        // Updated iin dob
      } else if (
        buyerState.matches({ ready: { complete: 'dobUpdated' } }) ||
        buyerState.matches({ ready: { complete: 'dobUpdatedWithoutChange' } })
      ) {
        routingMachineService.send({
          type: 'SEND_ROUTE_APPROVED_COMPLETE_DOB_UPDATED_BUYER',
        });
        isReturningBuyerState.current = false;
      } else if (buyerState.matches({ ready: { complete: 'emailUpdated' } })) {
        routingMachineService.send({
          type: 'SEND_ROUTE_APPROVED_COMPLETE_EMAIL_UPDATED_BUYER',
        });
        isReturningBuyerState.current = false;
      }
    }
    lastApplicationStateRef.current = applicationState.value;
  }, [buyerState, applicationState]);
};
