import { useRouter } from 'next/router';
import { getRouteFromStateValue } from '../utils';

/**
 * Prefetches an array of state value routes
 * @returns (prefetchRoutes: string[]) => void
 */
export const usePrefetch = (): ((prefetchRoutes: string[]) => void) => {
  const { prefetch, query } = useRouter();

  return (prefetchRoutes: string[]) => {
    for (const route of prefetchRoutes) {
      prefetch(getRouteFromStateValue(route, query));
    }
  };
};
