import * as yup from 'yup';
import isEqual from 'lodash.isequal';
import {
  Buyer,
  ClientAppConfig,
  Contact,
} from '@ads-bread/shared/bread/codecs';
import { hasAddressChanged } from '../../lib/address';

/**
 * Determines if buyer has a valid primary address and all required identity attributes
 * @param buyer Buyer
 * @param buyerIdentityAttributes ClientAppConfig['buyerIdentityAttributes']
 * @param addressSchema  yup.ObjectSchema
 * @returns boolean
 */
export function isCompleteBuyer(
  buyer: Buyer,
  buyerIdentityAttributes: ClientAppConfig['buyerIdentityAttributes'],
  addressSchema: yup.ObjectSchema
): boolean {
  return (
    buyerIdentityAttributes.every((attr) => !!buyer.identity[attr]) &&
    hasCompleteContact(buyer, addressSchema)
  );
}

/**
 * Determines if a buyer has a valid primary address
 * @param buyer Buyer
 * @param addressSchema yup.ObjectSchema
 * @returns boolean
 */
export function hasCompleteContact(
  buyer: Buyer,
  addressSchema: yup.ObjectSchema
): boolean {
  const primaryContact = buyer?.contacts?.[buyer?.primaryContactID];

  if (!primaryContact) {
    return false;
  }

  return addressSchema.isValidSync(primaryContact.address);
}

/**
 * Determine if buyer contact has been changed in comparison to contact supplied in params
 * @param buyer Buyer
 * @param contact Contact
 * @returns boolean
 */
export const isPrimaryContactUpdated = (
  buyer: Buyer | null,
  contact: Contact
): boolean => {
  const primaryContact = buyer?.contacts?.[buyer?.primaryContactID];
  if (!primaryContact) {
    return !!contact;
  }

  const isPrimaryAddressUpdated = hasAddressChanged(
    primaryContact.address,
    contact.address
  );
  const isPrimaryContactNameUpdated = !isEqual(
    primaryContact.name,
    contact.name
  );

  return isPrimaryAddressUpdated || isPrimaryContactNameUpdated;
};
