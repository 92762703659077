import { assertEvent, ErrorActorEvent } from 'xstate';
import { ActionEventParams, ActorErrorParams } from '../../../apply/lib/types';
import {
  CreateBuyerActorDone,
  FetchBuyerActorDone,
  FetchPartialBuyerActorDone,
  PrepareCartBuyerActorDone,
  SendEvaluateBuyerErrorActorDone,
  SendUpdateBuyerBillingAddress,
  SendUpdateBuyerBillingAddressParams,
  SendUpdateBuyerDOB,
  SendUpdateBuyerDOBParams,
  SendUpdateBuyerEmail,
  SendUpdateBuyerEmailParams,
  SendUpdateBuyerIIN,
  SendUpdateBuyerIINParams,
  SendUpdateBuyerName,
  SendUpdateBuyerNameParams,
  SendUpdateBuyerPhone,
  SendUpdateBuyerPhoneParams,
  SendUpdateBuyerShippingAddress,
  SendUpdateBuyerShippingAddressParams,
  UpdateBuyerActorDone,
} from './types/events';
import {
  PrepareCartBuyerResult,
  SendCreateBuyerResult,
  SendFetchBuyerResult,
  SendFetchPartialBuyerResult,
  SendUpdateBuyerResult,
  EvaluateBuyerErrorResult,
} from './types/actors';
import { BuyerContext } from './buyerMachine';

/**
 * Get SendUpdateBuyerNameParams from SendUpdateBuyerName event
 * @param ActionEventParams<BuyerContext, SendUpdateBuyerName>
 * @returns SendUpdateBuyerNameParams
 */
export const getUpdateBuyerNameParams = ({
  event,
}: ActionEventParams<
  BuyerContext,
  SendUpdateBuyerName
>): SendUpdateBuyerNameParams => {
  assertEvent(event, 'SEND_UPDATE_BUYER_NAME');

  return event.params;
};

/**
 * Get SendUpdateBuyerEmailParams from SendUpdateBuyerEmail event
 * @param ActionEventParams<BuyerContext, SendUpdateBuyerEmail>
 * @returns SendUpdateBuyerEmailParams
 */
export const getUpdateBuyerEmailParams = ({
  event,
}: ActionEventParams<
  BuyerContext,
  SendUpdateBuyerEmail
>): SendUpdateBuyerEmailParams => {
  assertEvent(event, 'SEND_UPDATE_BUYER_EMAIL');

  return event.params;
};

/**
 * Get SendUpdateBuyerPhoneParams from SendUpdateBuyerPhone event
 * @param ActionEventParams<BuyerContext, SendUpdateBuyerPhone>
 * @returns SendUpdateBuyerPhoneParams
 */
export const getUpdateBuyerPhoneParams = ({
  event,
}: ActionEventParams<
  BuyerContext,
  SendUpdateBuyerPhone
>): SendUpdateBuyerPhoneParams => {
  assertEvent(event, 'SEND_UPDATE_BUYER_PHONE');

  return event.params;
};

/**
 * Get SendUpdateBuyerIINParams from SendUpdateBuyerIIN event
 * @param ActionEventParams<BuyerContext, SendUpdateBuyerIIN>
 * @returns SendUpdateBuyerIINParams
 */
export const getUpdateBuyerIINParams = ({
  event,
}: ActionEventParams<
  BuyerContext,
  SendUpdateBuyerIIN
>): SendUpdateBuyerIINParams => {
  assertEvent(event, 'SEND_UPDATE_BUYER_IIN');

  return event.params;
};

/**
 * Get SendUpdateBuyerDOBParams from SendUpdateBuyerDOB event
 * @param ActionEventParams<BuyerContext, SendUpdateBuyerDOB>
 * @returns SendUpdateBuyerDOBParams
 */
export const getUpdateBuyerDOBParams = ({
  event,
}: ActionEventParams<
  BuyerContext,
  SendUpdateBuyerDOB
>): SendUpdateBuyerDOBParams => {
  assertEvent(event, 'SEND_UPDATE_BUYER_DOB');

  return event.params;
};

/**
 * Get SendUpdateBuyerShippingAddressParams from SendUpdateBuyerShippingAddress event
 * @param ActionEventParams<BuyerContext, SendUpdateBuyerShippingAddress>
 * @returns SendUpdateBuyerShippingAddressParams
 */
export const getUpdateBuyerShippingAddressParams = ({
  event,
}: ActionEventParams<
  BuyerContext,
  SendUpdateBuyerShippingAddress
>): SendUpdateBuyerShippingAddressParams => {
  assertEvent(event, 'SEND_UPDATE_BUYER_SHIPPING_ADDRESS');

  return event.params;
};

/**
 *
 * @param ActionEventParams<BuyerContext, SendUpdateBuyerBillingAddress>
 * @returns SendUpdateBuyerBillingAddressParams
 */
export const getUpdateBuyerBillingAddressParams = ({
  event,
}: ActionEventParams<
  BuyerContext,
  SendUpdateBuyerBillingAddress
>): SendUpdateBuyerBillingAddressParams => {
  assertEvent(event, 'SEND_UPDATE_BUYER_BILLING_ADDRESS');

  return event.params;
};

/**
 * Gets SendCreateBuyerResult from createBuyer DoneStateEvent
 * @param ActionEventParams<BuyerContext, ActorDoneEvent<SendCreateBuyerResult>>
 * @returns SendCreateBuyerResult
 */
export const getCreateBuyerParams = ({
  event,
}: ActionEventParams<
  BuyerContext,
  CreateBuyerActorDone
>): SendCreateBuyerResult => {
  return event.output;
};

/**
 *
 * @param ActionEventParams<BuyerContext, UpdateBuyerActorDone>
 * @returns SendUpdateBuyerResult
 */
export const getUpdateBuyerParams = ({
  event,
}: ActionEventParams<
  BuyerContext,
  UpdateBuyerActorDone
>): SendUpdateBuyerResult => {
  return event.output;
};

/**
 * Gets SendFetchBuyerResult from fetchBuyer DoneStateEvent
 * @param ActionEventParams<BuyerContext, FetchBuyerActorDone>
 * @returns SendFetchBuyerResult
 */
export const getFetchBuyerParams = ({
  event,
}: ActionEventParams<
  BuyerContext,
  FetchBuyerActorDone
>): SendFetchBuyerResult => {
  return event.output;
};

/**
 * Gets SendFetchPartialBuyerResult from fetchPartialBuyer DoneStateEvent
 * @param ActionEventParams<BuyerContext,  FetchPartialBuyerActorDone>
 * @returns SendFetchPartialBuyerResult
 */
export const getFetchPartialBuyerParams = ({
  event,
}: ActionEventParams<
  BuyerContext,
  FetchPartialBuyerActorDone
>): SendFetchPartialBuyerResult => {
  return event.output;
};

/**
 * Gets ActorErrorParams from error event
 * @param ActionEventParams<BuyerContext, ErrorActorEvent>
 * @returns ActorErrorParams
 */
export const getServiceErrorParams = ({
  event,
}: ActionEventParams<BuyerContext, ErrorActorEvent>): ActorErrorParams => {
  const errorOutput = event.error as ActorErrorParams;

  return {
    type: event.type,
    error: errorOutput.error,
    reject: errorOutput.reject,
  };
};

/**
 * Gets PrepareCartBuyerResult from prepareCartBuyer DoneStateEvent
 * @param ActionEventParams<BuyerContext, PrepareCartBuyerActorDone>
 * @returns PrepareCartBuyerResult
 */
export const getPreparingCartParams = ({
  event,
}: ActionEventParams<
  BuyerContext,
  PrepareCartBuyerActorDone
>): PrepareCartBuyerResult => {
  return event.output;
};

/**
 *
 * @param ActionEventParams<BuyerContext, SendEvaluateBuyerErrorActorDone>
 * @returns EvaluateBuyerErrorParams
 */
export const getEvaluateBuyerErrorParams = ({
  event,
}: ActionEventParams<
  BuyerContext,
  SendEvaluateBuyerErrorActorDone
>): EvaluateBuyerErrorResult => {
  return event.output;
};
