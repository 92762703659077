import { ActionEventParams } from '../../../../apply/lib/types';
import {
  RouteMachineServiceEvents,
  SendRouteBuyerErrorParams,
} from '../types/events';

type DefaultBuyerErrorRouteGuards = {
  isBuyerVerificationError: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteBuyerErrorParams
  ) => boolean;
};

/**
 * Shared default guard conditions to route for Buyer state errors
 * responding to RouteMachineService event SendRouteBuyerError
 * Note: These can be overridden in the routing machine context if needed
 * @returns DefaultBuyerErrorRouteGuards
 */
export const getDefaultBuyerErrorRouteGuards =
  (): DefaultBuyerErrorRouteGuards => {
    return {
      isBuyerVerificationError: (_, params) => {
        return params.state.matches({ errors: 'verifiedEmail' });
      },
    };
  };
