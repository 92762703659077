import { assign } from 'xstate';
import { addCountryCodeToPhoneNumber } from '../../lib/format-number';
import { BuyerContext } from './buyerMachine';
import {
  BuyerMachineEvents,
  SendUpdateBuyerBillingAddressParams,
  SendUpdateBuyerDOBParams,
  SendUpdateBuyerEmailParams,
  SendUpdateBuyerIINParams,
  SendUpdateBuyerNameParams,
  SendUpdateBuyerPhoneParams,
  SendUpdateBuyerShippingAddressParams,
} from './types/events';
import {
  PrepareCartBuyerResult,
  SendCreateBuyerResult,
  SendFetchBuyerResult,
  SendFetchPartialBuyerResult,
} from './types/actors';

/**
 * Assigns buyer to context for event `SEND_CREATE_BUYER` | `SEND_UPDATE_BUYER`
 */
export const assignBuyer = assign<
  BuyerContext,
  BuyerMachineEvents,
  SendCreateBuyerResult | SendFetchBuyerResult,
  BuyerMachineEvents,
  never
>({
  buyer: (_, params) => {
    return params.buyerRes.result;
  },
});

/**
 * Assigns partial buyer to context for event `SEND_FETCHING_PARTIAL_BUYER`
 */
export const assignPartialBuyer = assign<
  BuyerContext,
  BuyerMachineEvents,
  SendFetchPartialBuyerResult,
  BuyerMachineEvents,
  never
>({
  phone: (_, params) => {
    return params.buyerRes.result?.phone || '';
  },
  email: (_, params) => {
    return params.buyerRes.result?.email || '';
  },
});

/**
 * Assigns buyerName to context for event `SEND_UPDATE_BUYER_NAME`
 */
export const assignBuyerName = assign<
  BuyerContext,
  BuyerMachineEvents,
  SendUpdateBuyerNameParams,
  BuyerMachineEvents,
  never
>({
  buyerName: (_, params) => {
    return params.buyerName;
  },
});

/**
 * Assigns email to context for event `SEND_UPDATE_BUYER_EMAIL`
 */
export const assignBuyerEmail = assign<
  BuyerContext,
  BuyerMachineEvents,
  SendUpdateBuyerEmailParams,
  BuyerMachineEvents,
  never
>({
  email: (_, params) => {
    return params.email;
  },
});

/**
 * Assigns phone to context for event `SEND_UPDATE_BUYER_PHONE`
 */
export const assignBuyerPhone = assign<
  BuyerContext,
  BuyerMachineEvents,
  SendUpdateBuyerPhoneParams,
  BuyerMachineEvents,
  never
>({
  phone: (_, params) => {
    return params.phone;
  },
});

/**
 * Assigns iin to context for event `SEND_UPDATE_BUYER_IIN`
 */
export const assignBuyerIIN = assign<
  BuyerContext,
  BuyerMachineEvents,
  SendUpdateBuyerIINParams,
  BuyerMachineEvents,
  never
>({
  iin: (_, params) => {
    return params.iin;
  },
});

/**
 * Assigns dob to context for event `SEND_UPDATE_BUYER_DOB`
 */
export const assignBuyerDOB = assign<
  BuyerContext,
  BuyerMachineEvents,
  SendUpdateBuyerDOBParams,
  BuyerMachineEvents,
  never
>({
  dob: (_, params) => {
    return params.dob;
  },
});

/**
 * Assigns shippingAddress to context for event `SEND_UPDATE_BUYER_SHIPPING_ADDRESS`
 */
export const assignBuyerShippingAddress = assign<
  BuyerContext,
  BuyerMachineEvents,
  SendUpdateBuyerShippingAddressParams,
  BuyerMachineEvents,
  never
>({
  shippingAddress: (_, params) => {
    return params.shippingAddress;
  },
});

/**
 * Assigns billingAddress to context for event `SEND_UPDATE_BUYER_BILLING_ADDRESS`
 */
export const assignBuyerBillingAddress = assign<
  BuyerContext,
  BuyerMachineEvents,
  SendUpdateBuyerBillingAddressParams,
  BuyerMachineEvents,
  never
>({
  billingAddress: (_, params) => {
    return params.billingAddress;
  },
});

/**
 * Assigns cart buyer to context for event `SEND_BUYER_READY`
 */
export const assignCartBuyer = assign<
  BuyerContext,
  BuyerMachineEvents,
  PrepareCartBuyerResult,
  BuyerMachineEvents,
  never
>({
  buyerName: ({ context }, params) => {
    return params.cartRes.result?.contact.name || context.buyerName;
  },
  phone: (_, params) => {
    return addCountryCodeToPhoneNumber(
      params.cartRes.result?.contact.phone || ''
    );
  },
  email: (_, params) => {
    return params.cartRes.result?.contact.email || null;
  },
  shippingAddress: (_, params) => {
    return params.cartRes.result?.contact.shippingAddress || null;
  },
  billingAddress: (_, params) => {
    return params.cartRes.result?.contact.billingAddress || null;
  },
});

/**
 * Resets context to null for event `RESET_CONTEXT`
 */
export const resetContext = assign<
  BuyerContext,
  BuyerMachineEvents,
  undefined,
  BuyerMachineEvents,
  never
>({
  buyerName: null,
  buyerID: null,
  email: null,
  phone: null,
  iin: null,
  dob: null,
  shippingAddress: null,
  billingAddress: null,
});
