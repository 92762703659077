import { setup } from 'xstate';
import { PageNames } from '../../../../lib/analytics';
import { RouteMachineServiceEvents } from '../../types/events';
import { getRoutePathConditionGuards } from '../../guards/routePathConditions';
import { getRoutePathConditionGuardProps } from '../../actions';
import { getDefaultBuyerErrorRouteGuards } from '../../guards/buyerMachineErrors';
import { getDefaultApplicationErrorRouteGuards } from '../../guards/applicationMachineErrors';
import {
  getRoutingMachineLevelEvents,
  isRouteEqualToStateNode,
} from '../../transitions';

export enum Routes {
  Intro = '/in-store/[uuid]',
  Email = '/in-store/[uuid]/email',
  Mobile = '/in-store/[uuid]/mobile',
  Verification = '/in-store/[uuid]/verification',
  BillingAddress = '/in-store/[uuid]/billing-address',
  Eligibility = '/in-store/[uuid]/eligibility',
  Terms = '/in-store/[uuid]/terms',
  Review = '/in-store/[uuid]/review',
  Payment = '/in-store/[uuid]/payment',
  Confirmation = '/in-store/[uuid]/confirmation',
  LoginEligibility = '/in-store/[uuid]/login-eligibility',
  ConfirmEmail = '/in-store/[uuid]/confirm-email',
  NeedsFullIIN = '/in-store/[uuid]/needs-full-iin',
  IDVerificationInstructions = '/in-store/[uuid]/id-verification/instructions',
  IDVerificationUploadFront = '/in-store/[uuid]/id-verification/upload-front',
  IDVerificationUploadBack = '/in-store/[uuid]/id-verification/upload-back',
  IDVerificationReview = '/in-store/[uuid]/id-verification/review',
  IDVerificationNeedsAction = '/in-store/[uuid]/id-verification/needs-action',
  IDVerificationAlloyNeedsAction = '/in-store/[uuid]/id-verification/alloy-needs-action',
  IDVerificationAlloy = '/in-store/[uuid]/id-verification/alloy',
  // Error Routes
  AgeAlabamaMilitary = '/in-store/[uuid]/errors/age-alabama-military',
  AgeIneligible = '/in-store/[uuid]/errors/age-ineligible',
  BuyerHashFailed = '/in-store/[uuid]/errors/buyer-hash-failed',
  BuyerSkippedInstallments = '/in-store/[uuid]/errors/buyer-skipped-installments',
  BuyerStatusIneligible = '/in-store/[uuid]/errors/buyer-status-ineligible',
  CreditDenial = '/in-store/[uuid]/errors/credit-denial',
  CreditFreeze = '/in-store/[uuid]/errors/credit-freeze',
  IDVerificationFailure = '/in-store/[uuid]/errors/id-verification-failure',
  IDVerificationMaxAttemptsExceeded = '/in-store/[uuid]/errors/id-verification-max-attempts-exceeded',
  IDVerificationNeedsActionWarning = '/in-store/[uuid]/errors/id-verification-needs-action-warning',
  InvalidAddressNoMatchIneligible = '/in-store/[uuid]/errors/invalid-address-no-match-ineligible',
  KycDenial = '/in-store/[uuid]/errors/kyc-denial',
  LoanStatusIneligible = '/in-store/[uuid]/errors/loan-status-ineligible',
  LocationIneligible = '/in-store/[uuid]/errors/location-ineligible',
  MaxCardIneligible = '/in-store/[uuid]/errors/max-card-ineligible',
  NeedsActionWarning = '/in-store/[uuid]/errors/needs-action-warning',
  OFACDenial = '/in-store/[uuid]/errors/ofac-denial',
  SanctionDenial = '/in-store/[uuid]/errors/sanctions-denial',
  OutstandingLoansIneligible = '/in-store/[uuid]/errors/outstanding-loans-ineligible',
  POBoxAddressIneligible = '/in-store/[uuid]/errors/po-box-address-ineligible',
  PreviousDenialIneligible = '/in-store/[uuid]/errors/previous-denial-ineligible',
  VerifiedEmail = '/in-store/[uuid]/errors/verified-email',
  FraudAlertDenial = '/in-store/[uuid]/errors/fraud-alert-denial',
  FraudDenial = '/in-store/[uuid]/errors/fraud-denial',
  VirtualCardError = '/in-store/[uuid]/errors/card-error',
  VirtualCardErrorFailed = '/in-store/[uuid]/errors/card-error-failed',
  VirtualCardErrorInvalidConfig = '/in-store/[uuid]/errors/card-error-invalid-config',
  VirtualCardErrorUnsupportedDevice = '/in-store/[uuid]/errors/card-error-unsupported-device',
  CapacityRecheck = '/in-store/[uuid]/errors/capacity-recheck',
  LocationDisabled = '/in-store/[uuid]/errors/location-disabled',
  Unknown = '/in-store/[uuid]/errors/unknown-error',
}

export const routingMachine = setup({
  types: {
    events: {} as RouteMachineServiceEvents,
  },
  guards: {
    isVirtualCardWithActiveWallet: (): boolean => {
      throw new Error('Not implemented');
    },
    isVirtualCardWithTerminatedWallet: (): boolean => {
      throw new Error('Not implemented');
    },
    isVirtualCardApprovedApplication: (): boolean => {
      throw new Error('Not implemented');
    },
    isPreparedInStoreApplication: (): boolean => {
      throw new Error('Not implemented');
    },
    isExchangedStreamlinedWithReviewedApplication: (): boolean => {
      throw new Error('Not implemented');
    },
    isExchangedNonStreamlined: (): boolean => {
      throw new Error('Not implemented');
    },
    isExchangedStreamlined: (): boolean => {
      throw new Error('Not implemented');
    },
    isVirtualCardInvalidConfig: (): boolean => {
      throw new Error('Not implemented');
    },
    isVirtualCardUnsupportedDevice: (): boolean => {
      throw new Error('Not implemented');
    },
    isLocationDisabled: (): boolean => {
      throw new Error('Not implemented');
    },
    isUnauthenticatedInitial: (): boolean => {
      throw new Error('Not implemented');
    },
    isBuyerReadyComplete: (): boolean => {
      throw new Error('Not implemented');
    },
    isSplitPaySelected: (): boolean => {
      throw new Error('Not implemented');
    },
    isIDVerificationAlloy: (): boolean => {
      throw new Error('Not implemented');
    },
    hasBackImageData: (): boolean => {
      throw new Error('Not implemented');
    },
    isAuthenticatedCompleteMismatchedBuyerPII: (): boolean => {
      throw new Error('Not implemented');
    },
    isRouteEqualToStateNode,
    ...getRoutePathConditionGuards(),
    ...getDefaultBuyerErrorRouteGuards(),
    ...getDefaultApplicationErrorRouteGuards(),
  },
  actions: {
    addLoadingIndicator: (): void => {
      throw new Error('Not implemented');
    },
    removeLoadingIndicator: (): void => {
      throw new Error('Not implemented');
    },
  },
}).createMachine({
  id: 'router',
  initial: 'initializing',
  on: {
    ...getRoutingMachineLevelEvents(Routes),
  },
  states: {
    initializing: {
      on: {
        SEND_RESET_ROUTER_STATE: {
          target: Routes.Intro,
        },
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: [
          {
            guard: {
              type: 'isVirtualCardWithActiveWallet',
            },
            target: Routes.Confirmation,
          },
          {
            guard: {
              type: 'isVirtualCardWithTerminatedWallet',
            },
            target: Routes.VirtualCardError,
          },
          {
            guard: {
              type: 'isVirtualCardApprovedApplication',
            },
            target: Routes.Confirmation,
          },
          {
            guard: {
              type: 'isPreparedInStoreApplication',
            },
            target: Routes.Confirmation,
          },
          {
            guard: {
              type: 'isExchangedNonStreamlined',
            },
            target: Routes.BillingAddress,
          },
          {
            target: Routes.Terms,
          },
        ],
        SEND_ROUTE_EXCHANGED_BUYER: {
          target: Routes.Mobile,
        },
        SEND_ROUTE_APPLICATION_NEEDS_FULL_IIN: [
          {
            guard: {
              type: 'isExchangedStreamlined',
            },
            target: Routes.NeedsFullIIN,
          },
          // TODO - Add correct error page (Design Pending)
          {
            target: Routes.Unknown,
          },
        ],
        SEND_ROUTE_INCOMPLETE_BUYER: [
          {
            guard: {
              type: 'isExchangedStreamlined',
            },
            target: Routes.Eligibility,
          },
          {
            target: Routes.BillingAddress,
          },
        ],
        SEND_ROUTE_INVALID_APPLICATION_COMPLETE_BUYER: {
          target: Routes.BillingAddress,
        },
      },
      always: [
        {
          guard: {
            type: 'isVirtualCardInvalidConfig',
          },
          target: Routes.VirtualCardErrorInvalidConfig,
        },
        {
          guard: {
            type: 'isVirtualCardUnsupportedDevice',
          },
          target: Routes.VirtualCardErrorUnsupportedDevice,
        },
        {
          guard: {
            type: 'isLocationDisabled',
          },
          target: Routes.LocationDisabled,
        },
        {
          guard: {
            type: 'isUnauthenticatedInitial',
          },
          target: Routes.Intro,
        },
      ],
      exit: [
        {
          type: 'removeLoadingIndicator',
        },
      ],
    },
    [Routes.Intro]: {
      meta: {
        analyticsPageName: PageNames.Intro,
        prefetchRoutes: [Routes.Email],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.Email,
        },
      },
    },
    [Routes.Email]: {
      meta: {
        analyticsPageName: PageNames.Email,
        prefetchRoutes: [Routes.Mobile],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.Mobile,
        },
        SEND_BACK: {
          target: Routes.Intro,
        },
      },
    },
    [Routes.Mobile]: {
      meta: {
        analyticsPageName: PageNames.Mobile,
        prefetchRoutes: [Routes.Verification],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.Verification,
        },
        SEND_BACK: {
          target: Routes.Email,
        },
      },
    },
    [Routes.Verification]: {
      meta: {
        analyticsPageName: PageNames.Verification,
        prefetchRoutes: [Routes.BillingAddress, Routes.Terms],
      },
      on: {
        SEND_BACK: {
          target: Routes.Mobile,
        },
        SEND_ROUTE_NEW_BUYER: {
          target: Routes.BillingAddress,
        },
        SEND_ROUTE_AUTH_RETRY_WITH_PII: [
          {
            target: Routes.LoginEligibility,
          },
        ],
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: [
          {
            guard: {
              type: 'isVirtualCardWithActiveWallet',
            },
            target: Routes.Confirmation,
          },
          {
            guard: {
              type: 'isVirtualCardWithTerminatedWallet',
            },
            target: Routes.VirtualCardError,
          },
          {
            guard: {
              type: 'isVirtualCardApprovedApplication',
            },
            target: Routes.Confirmation,
          },
          {
            guard: {
              type: 'isPreparedInStoreApplication',
            },
            target: Routes.Confirmation,
          },
          {
            guard: {
              type: 'isExchangedNonStreamlined',
            },
            target: Routes.BillingAddress,
          },
          {
            target: Routes.Terms,
          },
        ],
        SEND_ROUTE_COMPLETE_IDENTITY_BUYER: {
          target: Routes.BillingAddress,
        },
        SEND_ROUTE_INCOMPLETE_BUYER: [
          {
            guard: {
              type: 'isExchangedStreamlined',
            },
            target: Routes.Eligibility,
          },
          {
            target: Routes.BillingAddress,
          },
        ],
        SEND_ROUTE_INVALID_APPLICATION_COMPLETE_BUYER: {
          target: Routes.BillingAddress,
        },
      },
    },
    [Routes.BillingAddress]: {
      meta: {
        analyticsPageName: PageNames.BillingAddress,
        prefetchRoutes: [Routes.Terms],
      },
      on: {
        SEND_ROUTE_INCOMPLETE_BUYER: {
          target: Routes.Eligibility,
        },
        SEND_ROUTE_APPROVED_COMPLETE_CONTACT_UPDATED_BUYER: [
          {
            guard: {
              type: 'isExchangedNonStreamlined',
            },
            target: Routes.Eligibility,
          },
          {
            target: Routes.Terms,
          },
        ],
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: [
          {
            guard: {
              type: 'isVirtualCardWithActiveWallet',
            },
            target: Routes.Confirmation,
          },
          {
            guard: {
              type: 'isVirtualCardWithTerminatedWallet',
            },
            target: Routes.VirtualCardError,
          },
          {
            guard: {
              type: 'isVirtualCardApprovedApplication',
            },
            target: Routes.Confirmation,
          },
          {
            guard: {
              type: 'isPreparedInStoreApplication',
            },
            target: Routes.Confirmation,
          },
          {
            guard: {
              type: 'isExchangedNonStreamlined',
            },
            target: Routes.Eligibility,
          },
          {
            target: Routes.Terms,
          },
        ],
        SEND_ROUTE_INVALID_APPLICATION_COMPLETE_BUYER: {
          target: Routes.Eligibility,
        },
      },
    },
    [Routes.Eligibility]: {
      meta: {
        analyticsPageName: PageNames.Eligibility,
        prefetchRoutes: [Routes.Terms],
      },
      on: {
        SEND_ROUTE_APPROVED_COMPLETE_DOB_UPDATED_BUYER: [
          {
            guard: {
              type: 'isVirtualCardWithActiveWallet',
            },
            target: Routes.Confirmation,
          },
          {
            guard: {
              type: 'isVirtualCardWithTerminatedWallet',
            },
            target: Routes.VirtualCardError,
          },
          {
            guard: {
              type: 'isVirtualCardApprovedApplication',
            },
            target: Routes.Confirmation,
          },
          {
            guard: {
              type: 'isPreparedInStoreApplication',
            },
            target: Routes.Confirmation,
          },
          {
            target: Routes.Terms,
          },
        ],
        SEND_ROUTE_APPLICATION_NEEDS_FULL_IIN: [
          {
            guard: {
              type: 'isExchangedStreamlined',
            },
            target: Routes.NeedsFullIIN,
          },
          // TODO - Add correct error page (Design Pending)
          {
            target: Routes.Unknown,
          },
        ],
        SEND_BACK: {
          target: Routes.BillingAddress,
        },
      },
    },
    [Routes.LoginEligibility]: {
      meta: {
        analyticsPageName: PageNames.LoginEligibility,
        prefetchRoutes: [Routes.Terms, Routes.BillingAddress],
      },
      on: {
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: [
          {
            guard: {
              type: 'isAuthenticatedCompleteMismatchedBuyerPII',
            },
            target: Routes.ConfirmEmail,
          },
          {
            guard: {
              type: 'isVirtualCardWithActiveWallet',
            },
            target: Routes.Confirmation,
          },
          {
            guard: {
              type: 'isVirtualCardWithTerminatedWallet',
            },
            target: Routes.VirtualCardError,
          },
          {
            guard: {
              type: 'isVirtualCardApprovedApplication',
            },
            target: Routes.Confirmation,
          },
          {
            guard: {
              type: 'isPreparedInStoreApplication',
            },
            target: Routes.Confirmation,
          },
          {
            guard: {
              type: 'isExchangedStreamlined',
            },
            target: Routes.Terms,
          },
          {
            target: Routes.ConfirmEmail,
          },
        ],
        SEND_ROUTE_NEW_BUYER: {
          target: Routes.BillingAddress,
        },
        SEND_ROUTE_INCOMPLETE_BUYER: {
          target: Routes.BillingAddress,
        },
        SEND_ROUTE_COMPLETE_IDENTITY_BUYER: {
          target: Routes.BillingAddress,
        },
        SEND_BACK: {
          target: Routes.Verification,
        },
      },
    },
    [Routes.ConfirmEmail]: {
      meta: {
        analyticsPageName: PageNames.ConfirmEmail,
        prefetchRoutes: [Routes.Terms],
      },
      on: {
        SEND_ROUTE_APPROVED_COMPLETE_EMAIL_UPDATED_BUYER: {
          target: Routes.BillingAddress,
        },
        SEND_ROUTE_APPROVED_COMPLETE_DOB_UPDATED_BUYER: {
          target: Routes.BillingAddress,
        },
      },
    },
    [Routes.NeedsFullIIN]: {
      meta: {
        analyticsPageName: PageNames.NeedsFullIIN,
        prefetchRoutes: [Routes.Terms],
      },
      on: {
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: {
          target: Routes.Terms,
        },
        SEND_ROUTE_APPROVED_COMPLETE_DOB_UPDATED_BUYER: {
          target: Routes.Terms,
        },
      },
    },
    [Routes.Terms]: {
      meta: {
        analyticsPageName: PageNames.Terms,
        prefetchRoutes: [Routes.Review],
      },
      on: {
        SEND_FORWARD: [
          {
            guard: {
              type: 'isSplitPaySelected',
            },
            target: Routes.Payment,
          },
          {
            target: Routes.Review,
          },
        ],
        SEND_ROUTE_WITH_CONDITION: {
          guard: {
            type: 'isUnknownErrorRouteCondition',
            params: getRoutePathConditionGuardProps,
          },
          target: Routes.Unknown,
        },
      },
    },
    [Routes.Payment]: {
      meta: {
        analyticsPageName: PageNames.Payment,
        prefetchRoutes: [Routes.Review],
      },
      on: {
        SEND_BACK: {
          target: Routes.Terms,
        },
        SEND_FORWARD: {
          target: Routes.Review,
        },
      },
    },
    [Routes.Review]: {
      meta: {
        analyticsPageName: PageNames.Review,
        prefetchRoutes: [Routes.Confirmation],
      },
      on: {
        SEND_BACK: {
          target: Routes.Terms,
        },
        SEND_FORWARD: {
          target: Routes.Confirmation,
        },
        SEND_ROUTE_APPLICATION_NEEDS_ID_VERIFICATION: [
          {
            guard: {
              type: 'isIDVerificationAlloy',
            },
            target: Routes.IDVerificationAlloyNeedsAction,
          },
          {
            target: Routes.IDVerificationNeedsAction,
          },
        ],
        SEND_ROUTE_WITH_CONDITION: [
          {
            guard: {
              type: 'isBillingAddressRouteCondition',
              params: getRoutePathConditionGuardProps,
            },
            target: Routes.BillingAddress,
          },
          {
            guard: {
              type: 'isVirtualCardFailedRouteCondition',
              params: getRoutePathConditionGuardProps,
            },
            target: Routes.VirtualCardErrorFailed,
          },
          {
            guard: {
              type: 'isPaymentRouteCondition',
              params: getRoutePathConditionGuardProps,
            },
            target: Routes.Payment,
          },
        ],
      },
    },
    [Routes.Confirmation]: {
      meta: {
        analyticsPageName: PageNames.Confirmation,
        prefetchRoutes: [],
      },
      on: {
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: {
          target: Routes.Terms,
        },
        SEND_ROUTE_APPROVED_COMPLETE_DOB_UPDATED_BUYER: {
          target: Routes.Terms,
        },
        SEND_ROUTE_APPROVED_COMPLETE_CONTACT_UPDATED_BUYER: {
          target: Routes.Terms,
        },
        SEND_BACK: {
          target: Routes.Payment,
        },
      },
    },
    [Routes.IDVerificationNeedsAction]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationNeedsAction,
        prefetchRoutes: [Routes.IDVerificationInstructions],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.IDVerificationInstructions,
        },
      },
    },
    [Routes.IDVerificationInstructions]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationInstructions,
        prefetchRoutes: [Routes.IDVerificationUploadFront],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.IDVerificationUploadFront,
        },
        SEND_BACK: {
          target: Routes.IDVerificationNeedsAction,
        },
      },
    },
    [Routes.IDVerificationUploadFront]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationUploadFront,
        prefetchRoutes: [Routes.IDVerificationUploadBack],
      },
      on: {
        SEND_FORWARD: [
          {
            guard: {
              type: 'hasBackImageData',
            },
            target: Routes.IDVerificationReview,
          },
          {
            target: Routes.IDVerificationUploadBack,
          },
        ],
        SEND_BACK: {
          target: Routes.IDVerificationInstructions,
        },
      },
    },
    [Routes.IDVerificationUploadBack]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationUploadBack,
        prefetchRoutes: [Routes.IDVerificationReview],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.IDVerificationReview,
        },
        SEND_BACK: {
          target: Routes.IDVerificationUploadFront,
        },
      },
    },
    [Routes.IDVerificationReview]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationReview,
        prefetchRoutes: [],
      },
      on: {
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: {
          target: Routes.Review,
        },
        SEND_ROUTE_APPROVED_COMPLETE_CONTACT_UPDATED_BUYER: {
          target: Routes.Review,
        },
        SEND_ROUTE_APPROVED_COMPLETE_DOB_UPDATED_BUYER: {
          target: Routes.Review,
        },
        SEND_ROUTE_WITH_CONDITION: [
          {
            guard: {
              type: 'isIDVerificationUploadFrontRouteCondition',
              params: getRoutePathConditionGuardProps,
            },
            target: Routes.IDVerificationUploadFront,
          },
          {
            guard: {
              type: 'isIDVerificationUploadBackRouteCondition',
              params: getRoutePathConditionGuardProps,
            },
            target: Routes.IDVerificationUploadBack,
          },
        ],
      },
    },
    [Routes.IDVerificationAlloyNeedsAction]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationAlloyNeedsAction,
        prefetchRoutes: [],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.IDVerificationAlloy,
        },
      },
    },
    [Routes.IDVerificationAlloy]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationAlloy,
        prefetchRoutes: [],
      },
      on: {
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: {
          target: Routes.Review,
        },
        SEND_ROUTE_APPROVED_COMPLETE_CONTACT_UPDATED_BUYER: {
          target: Routes.Review,
        },
        SEND_ROUTE_APPROVED_COMPLETE_DOB_UPDATED_BUYER: {
          target: Routes.Review,
        },
      },
    },
    // Error Routes
    [Routes.AgeAlabamaMilitary]: {
      meta: {
        analyticsPageName: PageNames.AgeAlabamaMilitaryError,
        prefetchRoutes: [],
      },
    },
    [Routes.AgeIneligible]: {
      meta: {
        analyticsPageName: PageNames.BuyerAgeIneligibleError,
        prefetchRoutes: [],
      },
    },
    [Routes.BuyerHashFailed]: {
      meta: {
        analyticsPageName: PageNames.BuyerHashError,
        prefetchRoutes: [],
      },
      on: {
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: {
          target: Routes.Terms,
        },
        SEND_ROUTE_APPROVED_COMPLETE_CONTACT_UPDATED_BUYER: {
          target: Routes.Terms,
        },
        SEND_ROUTE_APPROVED_COMPLETE_DOB_UPDATED_BUYER: {
          target: Routes.Terms,
        },
      },
    },
    [Routes.BuyerSkippedInstallments]: {
      meta: {
        analyticsPageName: PageNames.BuyerSkippedInstallmentsError,
        prefetchRoutes: [],
      },
    },
    [Routes.BuyerStatusIneligible]: {
      meta: {
        analyticsPageName: PageNames.BuyerStatusIneligible,
        prefetchRoutes: [],
      },
    },
    [Routes.CreditDenial]: {
      meta: {
        analyticsPageName: PageNames.CreditDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.CreditFreeze]: {
      meta: {
        analyticsPageName: PageNames.CreditFreezeError,
        prefetchRoutes: [],
      },
    },
    [Routes.IDVerificationFailure]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationFailure,
        prefetchRoutes: [],
      },
    },
    [Routes.IDVerificationMaxAttemptsExceeded]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationMaxAttempts,
        prefetchRoutes: [],
      },
    },
    [Routes.IDVerificationNeedsActionWarning]: {
      meta: {
        analyticsPageName: PageNames.IDVerificationNeedsActionWarningError,
        prefetchRoutes: [],
      },
    },
    [Routes.KycDenial]: {
      meta: {
        analyticsPageName: PageNames.KYCDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.LoanStatusIneligible]: {
      meta: {
        analyticsPageName: PageNames.LoanStatusIneligibleError,
        prefetchRoutes: [],
      },
    },
    [Routes.LocationIneligible]: {
      meta: {
        analyticsPageName: PageNames.LocationIneligibleError,
        prefetchRoutes: [],
      },
    },
    [Routes.MaxCardIneligible]: {
      meta: {
        analyticsPageName: PageNames.MaxCardIneligibleError,
        prefetchRoutes: [],
      },
    },
    [Routes.NeedsActionWarning]: {
      meta: {
        analyticsPageName: PageNames.NeedsActionWarningError,
        prefetchRoutes: [],
      },
    },
    [Routes.OFACDenial]: {
      meta: {
        analyticsPageName: PageNames.OFACError,
        prefetchRoutes: [],
      },
    },
    [Routes.OutstandingLoansIneligible]: {
      meta: {
        analyticsPageName: PageNames.OutstandingLoanIneligibleError,
        prefetchRoutes: [],
      },
    },
    [Routes.POBoxAddressIneligible]: {
      meta: {
        analyticsPageName: PageNames.POBoxAddressIneligibleError,
        prefetchRoutes: [],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.BillingAddress,
        },
      },
    },
    [Routes.InvalidAddressNoMatchIneligible]: {
      meta: {
        analyticsPageName: PageNames.InvalidAddressNoMatchIneligibleError,
        prefectchRoutes: [],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.BillingAddress,
        },
      },
    },
    [Routes.PreviousDenialIneligible]: {
      meta: {
        analyticsPageName: PageNames.PreviousDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.VerifiedEmail]: {
      meta: {
        analyticsPageName: PageNames.VerifiedEmailError,
        prefetchRoutes: [],
      },
    },
    [Routes.SanctionDenial]: {
      meta: {
        analyticsPageName: PageNames.SanctionDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.FraudAlertDenial]: {
      meta: {
        analyticsPageName: PageNames.FraudAlertDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.FraudDenial]: {
      meta: {
        analyticsPageName: PageNames.FraudDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.CapacityRecheck]: {
      meta: {
        analyticsPageName: PageNames.CapacityRecheckError,
        prefetchRoutes: [],
      },
      on: {
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: {
          target: Routes.Terms,
        },
        SEND_ROUTE_APPROVED_COMPLETE_CONTACT_UPDATED_BUYER: {
          target: Routes.Terms,
        },
        SEND_ROUTE_APPROVED_COMPLETE_DOB_UPDATED_BUYER: {
          target: Routes.Terms,
        },
      },
    },
    [Routes.VirtualCardError]: {
      meta: {
        analyticsPageName: PageNames.VirtualCardFraudStopError,
        prefetchRoutes: [],
      },
    },
    [Routes.VirtualCardErrorFailed]: {
      meta: {
        analyticsPageName: PageNames.VirtualCardFailedError,
        prefetchRoutes: [],
      },
    },
    [Routes.VirtualCardErrorInvalidConfig]: {
      meta: {
        analyticsPageName: PageNames.VirtualCardInvalidMerchantConfigError,
        prefetchRoutes: [],
      },
    },
    [Routes.VirtualCardErrorUnsupportedDevice]: {
      meta: {
        analyticsPageName: PageNames.VirtualCardUnsupportedDeviceError,
        prefetchRoutes: [],
      },
    },
    [Routes.LocationDisabled]: {
      meta: {
        analyticsPageName: PageNames.LocationDisabled,
        prefetchRoutes: [],
      },
    },
    [Routes.Unknown]: {
      meta: {
        analyticsPageName: PageNames.UnknownError,
        prefetchRoutes: [],
      },
    },
  },
});
