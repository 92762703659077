import { useEffect } from 'react';
import { useBuyerMachine } from '../../BuyerMachineContext';
import { RouteMachineServiceEventEmitter } from '../types/events';

/**
 * Subscribes to the buyer machine state and emits events to be handled
 * based on those states.
 * @param routingMachineService RouteMachineServiceEventEmitter
 */
export const useBuyerMachineStateEventEmitter = (
  routingMachineService: RouteMachineServiceEventEmitter
): void => {
  const { state: buyerState } = useBuyerMachine();

  // Buyer machine subscriber
  useEffect(() => {
    if (buyerState.matches('errors')) {
      // Buyer in an error state
      routingMachineService.send({
        type: 'SEND_ROUTE_BUYER_ERROR',
        params: {
          state: buyerState,
        },
      });
      return;
    }

    if (buyerState.matches({ ready: 'completeIdentity' })) {
      // Complete identity buyer
      routingMachineService.send({
        type: 'SEND_ROUTE_COMPLETE_IDENTITY_BUYER',
      });
    } else if (
      buyerState.matches({ ready: 'initial' }) ||
      buyerState.matches({ ready: 'cartRetrieved' })
    ) {
      // New buyer
      routingMachineService.send({ type: 'SEND_ROUTE_NEW_BUYER' });
    } else if (buyerState.matches({ ready: 'incomplete' })) {
      // Incomplete buyer
      routingMachineService.send({ type: 'SEND_ROUTE_INCOMPLETE_BUYER' });
    } else if (buyerState.matches({ ready: 'exchanging' })) {
      routingMachineService.send({ type: 'SEND_ROUTE_EXCHANGED_BUYER' });
    }
  }, [buyerState]);
};
