import { assertEvent, ErrorActorEvent } from 'xstate';
import { ActionEventParams, ActorErrorParams } from '../../../apply/lib/types';
import {
  SendAuthenticationRequest,
  AuthenticationRequestParams,
  SendCodeActorDone,
  AuthorizeActorDone,
} from './types/events';
import { AuthorizeResult, SendCodeResult } from './types/actors';
import { AuthenticationContext } from './authenticationMachine';

/**
 * Get AuthenticationRequestParams from SendAuthenticationRequest event
 * @param ActionEventParams<AuthenticationContext, SendAuthenticationRequest>
 * @returns AuthenticationRequestParams
 */
export const getAuthenticationRequestEventParams = ({
  event,
  context,
}: ActionEventParams<
  AuthenticationContext,
  SendAuthenticationRequest
>): AuthenticationRequestParams => {
  assertEvent(event, 'SEND_AUTHENTICATION_REQUEST');

  return {
    context,
    authorizeOptions: event.params.authorizeOptions,
    resolve: event.params.resolve,
    reject: event.params.reject,
  };
};

/**
 * Get SendCodeOutput from sendingCode DoneStateEvent
 * @param param ActionEventParams<AuthenticationContext, SendCodeActorDone>
 * @returns @returns SendCodeOutput
 */
export const getSendCodeEventParams = ({
  event,
}: ActionEventParams<
  AuthenticationContext,
  SendCodeActorDone
>): SendCodeResult => {
  return event.output;
};

/**
 * Gets AuthorizeResult from authorize DoneStateEvent
 * @param ActionEventParams<AuthenticationContext, AuthorizeActorDone>
 * @returns AuthorizeResult
 */
export const getAuthorizeEventParams = ({
  event,
}: ActionEventParams<
  AuthenticationContext,
  AuthorizeActorDone
>): AuthorizeResult => {
  return event.output;
};

/**
 * Gets ActorErrorParams from error event
 * @param ActionEventParams<AuthenticationContext, ErrorActorEvent>
 * @returns ActorErrorParams
 */
export const getServiceErrorParams = ({
  event,
}: ActionEventParams<
  AuthenticationContext,
  ErrorActorEvent
>): ActorErrorParams => {
  const errorOutput = event.error as ActorErrorParams;

  return {
    type: event.type,
    error: errorOutput.error,
    reject: errorOutput.reject,
  };
};
