import { FunctionComponent, createContext, useMemo } from 'react';
import { ActorRefFrom, InspectionEvent, StateFrom } from 'xstate';
import { useActorRef } from '@xstate/react';
import { useLoadingManager } from '../../../LoadingManager';
import { useAuthentication } from '../../../AuthenticationMachineContext';
import { getLoadingIndicatorActions } from '../../actions';
import {
  RouteMachineContextProps,
  RouteMachineServiceProps,
} from '../../RouteMachineService';
import { getDefaultApplicationErrorRouteGuards } from '../../guards/applicationMachineErrors';
import { getDefaultBuyerErrorRouteGuards } from '../../guards/buyerMachineErrors';
import { getRoutePathConditionGuards } from '../../guards/routePathConditions';
import { useApplicationMachineStateEventEmitter } from '../../hooks/useApplicationMachineStateEventEmitter';
import { useAuthenticationMachineStateEventEmitter } from '../../hooks/useAuthenticationMachineStateEventEmitter';
import { useBuyerApplicationEventEmitter } from '../../hooks/useBuyerApplicationEventEmitter';
import { useBuyerMachineStateEventEmitter } from '../../hooks/useBuyerMachineStateEventEmitter';
import { useHandleStateRouteTransition } from '../../hooks/useHandleStateRouteTransition';
import { useRouteTransitionActions } from '../../hooks/useRouteTransitionActions';
import { useSyncRouterStateEventEmitter } from '../../hooks/useSyncRouterStateEventEmitter';
import { useInspectLogoutRouterEvent } from '../../hooks/useInspectLogoutRouterEvent';
import { routingMachine } from './rbcRoutingMachine';

export type RBCRoutingMachineSnapshot = StateFrom<typeof routingMachine>;

type RBCRoutingMachineActorRef = ActorRefFrom<typeof routingMachine>;

export const RBCRoutingMachineContext =
  createContext<RouteMachineContextProps<RBCRoutingMachineActorRef | null> | null>(
    null
  );

export const RBCRoutingMachineProvider: FunctionComponent<RouteMachineServiceProps> =
  ({ children }) => {
    const { state: authState } = useAuthentication();
    const { addLoader, removeLoader } = useLoadingManager();
    const inspectLogoutRouterEvent = useInspectLogoutRouterEvent();
    // Handle state route transitions
    const { isTransitioning, handleStateRouteTransition } =
      useHandleStateRouteTransition();

    const routingMachineService = useActorRef(
      routingMachine.provide({
        guards: {
          isUnauthenticatedInitial: () => {
            return authState.matches({ unAuthenticated: 'initial' });
          },
          isAuthenticatedCompleteMismatchedBuyerPII: () => {
            return authState.matches({
              authenticated: { complete: 'mismatchedBuyerPII' },
            });
          },
          // Route path condition guards
          ...getRoutePathConditionGuards(),
          // Application Error condition routing checks
          ...getDefaultApplicationErrorRouteGuards(),
          // Buyer Error condition routing checks
          ...getDefaultBuyerErrorRouteGuards(),
        },
        actions: {
          ...getLoadingIndicatorActions({ addLoader, removeLoader }),
        },
      }),
      {
        inspect: (inspectionEvent: InspectionEvent) => {
          handleStateRouteTransition(inspectionEvent);
          inspectLogoutRouterEvent(inspectionEvent);
        },
      }
    );

    // Shared event emitters
    useSyncRouterStateEventEmitter(routingMachineService);
    useBuyerApplicationEventEmitter(routingMachineService);
    useApplicationMachineStateEventEmitter(routingMachineService);
    useBuyerMachineStateEventEmitter(routingMachineService);
    useAuthenticationMachineStateEventEmitter(routingMachineService);

    const { forward, back, routeWithCondition } = useRouteTransitionActions(
      routingMachineService
    );

    const value = useMemo(() => {
      return { service: routingMachineService, isTransitioning };
    }, [routingMachineService, isTransitioning]);

    return (
      <RBCRoutingMachineContext.Provider value={value}>
        {children({
          isTransitioning,
          forward,
          back,
          routeWithCondition,
        })}
      </RBCRoutingMachineContext.Provider>
    );
  };
