import { FunctionComponent } from 'react';
import { useBuyerMachine } from './useBuyerMachine';

export const BuyerSubscriber: FunctionComponent = () => {
  const { state } = useBuyerMachine();
  return (
    <>
      <pre className="text-sm">
        {JSON.stringify({ value: state.value }, null, 2)}
      </pre>
      <pre className="text-sm">
        {JSON.stringify({ context: state.context }, null, 2)}
      </pre>
    </>
  );
};
