import { fromPromise, setup } from 'xstate';
import { ActorErrorParams } from '../../../apply/lib/types';
import {
  AuthenticationMachineEvents,
  AuthenticationRequestParams,
  SendCodeParams,
  SendLogout,
  SendLogoutUnauthorized,
} from './types/events';
import { assignOtpCode, assignRefID, resetContext } from './assigns';
import { AuthorizeResult, SendCodeResult } from './types/actors';
import {
  getAuthenticationRequestEventParams,
  getAuthorizeEventParams,
  getSendCodeEventParams,
  getServiceErrorParams,
} from './actions';
import { getAuthorizeInput, getLogoutInput, getSendCodeInput } from './inputs';

export interface AuthenticationContext {
  refID: string | null;
  otpCode: string | null;
}

export const authenticationMachine = setup({
  types: {
    context: {} as AuthenticationContext,
    events: {} as AuthenticationMachineEvents,
  },
  guards: {
    isExchangedBuyer: (): boolean => {
      throw new Error('Not implemented');
    },
    isAuthTokenActive: (): boolean => {
      throw new Error('Not implemented');
    },
    isAuthenticatedWithBuyer: (_, __: AuthorizeResult): boolean => {
      throw new Error('Not implemented');
    },
    isRetryWithPIIError: (_, __: AuthorizeResult) => {
      throw new Error('Not implemented');
    },
    isAuthenticatedAnonymous: (_, __: AuthorizeResult) => {
      throw new Error('Not implemented');
    },
    isAuthenticatedAnonymousMismatchedBuyerPII: (_, __: AuthorizeResult) => {
      throw new Error('Not implemented');
    },
    isAuthenticatedMatchedBuyerPII: (_, __: AuthorizeResult) => {
      throw new Error('Not implemented');
    },
    isMismatchedBuyerToken: (): boolean => {
      throw new Error('Not implemented');
    },
  },
  actions: {
    assignOtpCode,
    assignRefID,
    resetContext,
    identifyAuthenticatedBuyer: (): void => {
      throw new Error('Not implemented');
    },
    handleLogout: (): void => {
      throw new Error('Not implemented');
    },
    handleSendCodeDone: (_, __: SendCodeResult): void => {
      throw new Error('Not implemented');
    },
    handleAuthenticatingDone: (_, __: AuthorizeResult): void => {
      throw new Error('Not implemented');
    },
    identifyReturningBuyer: (_, __: AuthorizeResult) => {
      throw new Error('Not implemented');
    },
    handleServiceError: (_, __: ActorErrorParams): void => {
      throw new Error('Not implemented');
    },
    logError: (_, __: ActorErrorParams): void => {
      throw new Error('Not implemented');
    },
  },
  actors: {
    logout: fromPromise<void, SendLogout | SendLogoutUnauthorized>(() => {
      throw new Error('Not implemented');
    }),
    sendCode: fromPromise<SendCodeResult, SendCodeParams>(() => {
      throw new Error('Not implemented');
    }),
    authorize: fromPromise<AuthorizeResult, AuthenticationRequestParams>(() => {
      throw new Error('Not implemented');
    }),
  },
}).createMachine({
  id: 'authentication',
  initial: 'initialization',
  context: {
    refID: null,
    otpCode: null,
  },
  on: {
    SEND_LOGOUT_UNAUTHORIZED: {
      target: '.loggingOutUnauthorized',
    },
  },
  states: {
    initialization: {
      always: [
        {
          guard: {
            type: 'isExchangedBuyer',
          },
          target: 'validatingExchangedBuyer',
        },
        {
          guard: 'isAuthTokenActive',
          actions: ['identifyAuthenticatedBuyer'],
          target: 'authenticated.complete.initial',
        },
        {
          target: 'unAuthenticated',
        },
      ],
    },
    authenticated: {
      on: {
        SEND_LOGOUT: {
          target: 'loggingOut',
        },
      },
      initial: 'complete',
      states: {
        complete: {
          initial: 'initial',
          states: {
            initial: {},
            withBuyer: {},
            mismatchedBuyerPII: {},
          },
        },
        anonymous: {
          initial: 'initial',
          states: {
            initial: {},
            noMismatch: {},
            mismatchedBuyerPII: {},
          },
        },
      },
    },
    unAuthenticated: {
      on: {
        SEND_AUTHENTICATION_REQUEST: {
          target: 'authenticating',
          actions: [
            {
              type: 'assignOtpCode',
              params: getAuthenticationRequestEventParams,
            },
          ],
        },
        SEND_CODE: {
          target: '.sendingCode',
        },
      },
      initial: 'initial',
      states: {
        initial: {},
        complete: {},
        unauthorized: {},
        exchanging: {},
        sendingCode: {
          invoke: {
            src: 'sendCode',
            input: getSendCodeInput,
            onDone: {
              target: 'codeSent',
              actions: [
                {
                  type: 'assignRefID',
                  params: getSendCodeEventParams,
                },
                {
                  type: 'handleSendCodeDone',
                  params: getSendCodeEventParams,
                },
              ],
            },
            onError: {
              target: 'attempting',
              actions: [
                {
                  type: 'handleServiceError',
                  params: getServiceErrorParams,
                },
                {
                  type: 'logError',
                  params: getServiceErrorParams,
                },
              ],
            },
          },
        },
        codeSent: {},
        attempting: {},
        retryWithPII: {
          on: {
            SEND_AUTHENTICATION_REQUEST: {
              target: '.authenticatingWithPII',
            },
          },
          initial: 'initial',
          states: {
            initial: {},
            authenticatingWithPII: {
              invoke: {
                src: 'authorize',
                input: getAuthorizeInput,
                onDone: [
                  {
                    guard: {
                      type: 'isAuthenticatedAnonymousMismatchedBuyerPII',
                      params: getAuthorizeEventParams,
                    },
                    target:
                      '#authentication.authenticated.anonymous.mismatchedBuyerPII',
                    actions: [
                      {
                        type: 'handleAuthenticatingDone',
                        params: getAuthorizeEventParams,
                      },
                    ],
                  },
                  {
                    guard: {
                      type: 'isAuthenticatedMatchedBuyerPII',
                      params: getAuthorizeEventParams,
                    },
                    target:
                      '#authentication.authenticated.complete.mismatchedBuyerPII',
                    actions: [
                      {
                        type: 'handleAuthenticatingDone',
                        params: getAuthorizeEventParams,
                      },
                    ],
                  },
                  {
                    target: '#authentication.unAuthenticated.attempting',
                    actions: [
                      {
                        type: 'handleAuthenticatingDone',
                        params: getAuthorizeEventParams,
                      },
                    ],
                  },
                ],
                onError: [
                  {
                    target: '#authentication.unAuthenticated.attempting',
                    actions: [
                      {
                        type: 'handleServiceError',
                        params: getServiceErrorParams,
                      },
                      {
                        type: 'logError',
                        params: getServiceErrorParams,
                      },
                    ],
                  },
                ],
              },
            },
          },
        },
      },
    },
    authenticating: {
      invoke: {
        src: 'authorize',
        input: getAuthorizeInput,
        onDone: [
          {
            guard: {
              type: 'isAuthenticatedWithBuyer',
              params: getAuthorizeEventParams,
            },
            target: 'authenticated.complete.withBuyer',
            actions: [
              {
                type: 'identifyReturningBuyer',
                params: getAuthorizeEventParams,
              },
              {
                type: 'handleAuthenticatingDone',
                params: getAuthorizeEventParams,
              },
            ],
          },
          {
            guard: {
              type: 'isRetryWithPIIError',
              params: getAuthorizeEventParams,
            },
            target: 'unAuthenticated.retryWithPII',
            actions: [
              {
                type: 'handleAuthenticatingDone',
                params: getAuthorizeEventParams,
              },
            ],
          },
          {
            guard: {
              type: 'isAuthenticatedAnonymous',
              params: getAuthorizeEventParams,
            },
            target: 'authenticated.anonymous.noMismatch',
            actions: [
              {
                type: 'identifyReturningBuyer',
                params: getAuthorizeEventParams,
              },
              {
                type: 'handleAuthenticatingDone',
                params: getAuthorizeEventParams,
              },
            ],
          },
          {
            target: 'unAuthenticated.attempting',
            actions: [
              {
                type: 'handleAuthenticatingDone',
                params: getAuthorizeEventParams,
              },
            ],
          },
        ],
        onError: [
          {
            target: 'unAuthenticated.attempting',
            actions: [
              {
                type: 'handleServiceError',
                params: getServiceErrorParams,
              },
              {
                type: 'logError',
                params: getServiceErrorParams,
              },
            ],
          },
        ],
      },
    },
    validatingExchangedBuyer: {
      always: [
        {
          guard: {
            type: 'isMismatchedBuyerToken',
          },
          target: 'unAuthenticated.exchanging',
          actions: ['handleLogout'],
        },
        {
          guard: {
            type: 'isAuthTokenActive',
          },
          target: 'authenticated.complete.initial',
          actions: ['identifyAuthenticatedBuyer'],
        },
        {
          guard: {
            type: 'isExchangedBuyer',
          },
          target: 'unAuthenticated.exchanging',
        },
        {
          target: 'loggingOut',
        },
      ],
    },
    loggingOut: {
      invoke: {
        src: 'logout',
        input: getLogoutInput,
        onDone: {
          target: 'unAuthenticated.complete',
          actions: ['handleLogout', 'resetContext'],
        },
        onError: {
          target: 'unAuthenticated.complete',
          actions: ['handleLogout', 'resetContext'],
        },
      },
    },
    loggingOutUnauthorized: {
      invoke: {
        src: 'logout',
        input: getLogoutInput,
        onDone: {
          target: 'unAuthenticated.unauthorized',
          actions: ['handleLogout', 'resetContext'],
        },
        onError: {
          target: 'unAuthenticated.unauthorized',
          actions: ['handleLogout', 'resetContext'],
        },
      },
    },
  },
});
