import { assign } from 'xstate';
import { ApplicationContext } from './applicationMachine';
import {
  ApplicationMachineEvents,
  SendAssignIDVerificationBackImageDataParams,
  SendAssignIDVerificationFrontImageDataParams,
  SendAssignPaymentAgreementDocumentParams,
  SendAssignSelectedOfferParams,
} from './types/events';
import {
  ApplicationCheckoutResult,
  ApplicationPrepareCheckoutResult,
  CreateApplicationResult,
  EvaluateIDVerificationImagesResult,
  FetchApplicationResult,
  FetchLatestApplicationResult,
  PrepareInStoreApplicationResult,
  ReCreateApplicationResult,
  ReCreateInStoreContingentApplicationResult,
} from './types/actors';

/**
 * Assigns selected offer to context for event `SEND_ASSIGN_SELECTED_OFFER`
 */
export const assignSelectedOffer = assign<
  ApplicationContext,
  ApplicationMachineEvents,
  SendAssignSelectedOfferParams,
  ApplicationMachineEvents,
  never
>({
  selectedOffer: (_, event) => {
    return event.selectedOffer;
  },
});

/**
 * Assigns front image data to context for event `SEND_ASSIGN_ID_VERIFICATION_FRONT_IMAGE_DATA`
 */
export const assignIDVerificationFrontImageData = assign<
  ApplicationContext,
  ApplicationMachineEvents,
  SendAssignIDVerificationFrontImageDataParams,
  ApplicationMachineEvents,
  never
>({
  idVerification: ({ context }, event) => {
    return {
      ...context.idVerification,
      frontImageData: event.frontImageData,
    };
  },
});

/**
 * Assigns back image data to context for event `SEND_ASSIGN_ID_VERIFICATION_BACK_IMAGE_DATA`
 */
export const assignIDVerificationBackImageData = assign<
  ApplicationContext,
  ApplicationMachineEvents,
  SendAssignIDVerificationBackImageDataParams,
  ApplicationMachineEvents,
  never
>({
  idVerification: ({ context }, event) => {
    return {
      ...context.idVerification,
      backImageData: event.backImageData,
    };
  },
});

/**
 * Assigns isIDVerified to context for event EvaluateIDVerificationImagesActorDone
 */
export const assignIDVerificationIsIDVerified = assign<
  ApplicationContext,
  ApplicationMachineEvents,
  EvaluateIDVerificationImagesResult,
  ApplicationMachineEvents,
  never
>({
  idVerification: ({ context }) => {
    return {
      ...context.idVerification,
      isIDVerified: true,
    };
  },
});

/**
 * Assigns application to context for event `SEND_FETCH_LATEST_APPLICATION`
 */
export const assignApplication = assign<
  ApplicationContext,
  ApplicationMachineEvents,
  | FetchLatestApplicationResult
  | FetchApplicationResult
  | CreateApplicationResult
  | ReCreateApplicationResult
  | ApplicationCheckoutResult
  | ApplicationPrepareCheckoutResult
  | PrepareInStoreApplicationResult
  | ReCreateInStoreContingentApplicationResult,
  ApplicationMachineEvents,
  never
>({
  application: (_, params) => {
    return params.applicationRes.result;
  },
});

/**
 * Assigns application with appended status codes to context for event `SEND_CREATE_APPLICATION`
 */
export const assignApplicationWithAppendedStatusCodes = assign<
  ApplicationContext,
  ApplicationMachineEvents,
  | FetchLatestApplicationResult
  | CreateApplicationResult
  | ReCreateApplicationResult
  | ApplicationCheckoutResult
  | ApplicationPrepareCheckoutResult
  | ReCreateInStoreContingentApplicationResult,
  ApplicationMachineEvents,
  never
>({
  application: ({ context }, params) => {
    const { application } = context;
    const applicationResult = params.applicationRes.result;

    if (!applicationResult) {
      return null;
    }
    return {
      ...applicationResult,
      statusCodes: [
        ...(application?.statusCodes || []),
        ...(applicationResult?.statusCodes || []),
      ],
    };
  },
});

/**
 * Assigns paymentAgreementDocument to context for event `SEND_ASSIGN_PAYMENT_AGREEMENT_DOCUMENT`
 */
export const assignPaymentAgreementDocument = assign<
  ApplicationContext,
  ApplicationMachineEvents,
  SendAssignPaymentAgreementDocumentParams,
  ApplicationMachineEvents,
  never
>({
  paymentAgreementDocument: (_, params) => {
    return params.paymentAgreementDocument;
  },
});

/**
 * Resets context to null for event `SEND_RESET_APPLICATION_STATE`
 */
export const resetContext = assign<
  ApplicationContext,
  ApplicationMachineEvents,
  undefined,
  ApplicationMachineEvents,
  never
>({
  application: null,
  selectedOffer: null,
  paymentAgreementDocument: null,
  idVerification: {
    frontImageData: null,
    backImageData: null,
    isIDVerified: false,
  },
});
