import { InspectionEvent } from 'xstate';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useState, useMemo, useCallback } from 'react';
import {
  getSearchParamQueryStringByKeys,
  getRouteFromStateValue,
  getRouteStateMeta,
  handleRouteServiceDebugLogging,
} from '../utils';
import { RouteMachineServiceSnapshots } from '../RouteMachineService';
import { usePrefetch } from './usePrefetch';
import { useTrackPageView } from './useTrackPageView';

// Intermediate routing states that should not route
export const transitionalStates: string[] = ['initializing'];

interface UseHandleOnTransition {
  isTransitioning: boolean;
  handleStateRouteTransition: (inspectionEvent: InspectionEvent) => void;
}

/**
 * Handle route transitions based on the current machine state
 * @param persistedSearchParams array for query params to persist
 * @returns UseHandleOnTransition
 */
export const useHandleStateRouteTransition = (
  persistedSearchParams: string[] | undefined = []
): UseHandleOnTransition => {
  const { push, query } = useRouter();
  const searchParams = useSearchParams();
  const prefetch = usePrefetch();
  const trackPageView = useTrackPageView();
  const [isTransitioning, setIsTransitioning] = useState<boolean>(true);

  const searchParamQuery = useMemo((): string => {
    // No need to rebuild query string if nothing changes
    return getSearchParamQueryStringByKeys(searchParams, persistedSearchParams);
  }, [searchParams, persistedSearchParams]);

  const handleStateRouteTransition = useCallback(
    async (inspectionEvent: InspectionEvent) => {
      if (inspectionEvent.type !== '@xstate.microstep') {
        return;
      }
      const state = inspectionEvent.snapshot as RouteMachineServiceSnapshots;

      setIsTransitioning(true);

      const stateRoute = getRouteFromStateValue(state.value, query);
      // Transitional routes are not routeable
      if (!transitionalStates.includes(stateRoute)) {
        await push(`${stateRoute}${searchParamQuery}`);

        const meta = getRouteStateMeta('router', state);

        trackPageView(meta.analyticsPageName);
        prefetch(meta.prefetchRoutes);
      }

      handleRouteServiceDebugLogging(state.value, inspectionEvent.event);

      setIsTransitioning(false);
    },
    []
  );

  return { isTransitioning, handleStateRouteTransition };
};
