import { FunctionComponent } from 'react';
import { useAuthentication } from './useAuthentication';

/**
 * Displays state debugging information to DOM for the AuthenticationMachineContext
 * @returns FunctionComponent
 */
export const AuthenticationSubscriber: FunctionComponent = () => {
  const { state } = useAuthentication();
  return (
    <>
      <pre className="text-sm">
        {JSON.stringify({ value: state.value }, null, 2)}
      </pre>
      <pre className="text-sm">
        {JSON.stringify({ context: state.context }, null, 2)}
      </pre>
    </>
  );
};
