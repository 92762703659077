import { assign } from 'xstate';
import { AuthenticationContext } from './authenticationMachine';
import {
  AuthenticationRequestParams,
  AuthenticationMachineEvents,
} from './types/events';
import { SendCodeResult } from './types/actors';

/**
 * Assigns otpCode to context for event `SEND_AUTHENTICATION_REQUEST`
 */
export const assignOtpCode = assign<
  AuthenticationContext,
  AuthenticationMachineEvents,
  AuthenticationRequestParams,
  AuthenticationMachineEvents,
  never
>({
  otpCode: ({ context }, params: AuthenticationRequestParams) => {
    return params.authorizeOptions.otpCode || context.otpCode;
  },
});

/**
 * Assigns refID to context for event `SEND_CODE`
 */
export const assignRefID = assign<
  AuthenticationContext,
  AuthenticationMachineEvents,
  SendCodeResult,
  AuthenticationMachineEvents,
  never
>({
  refID: ({ context }, params: SendCodeResult) => {
    return params.sendCodeRes.result?.referenceID || context.refID;
  },
});

/**
 * Assigns context values back to defaults
 */
export const resetContext = assign<
  AuthenticationContext,
  AuthenticationMachineEvents,
  undefined,
  AuthenticationMachineEvents,
  never
>({
  refID: null,
  otpCode: null,
});
