import React, { FunctionComponent, useState } from 'react';
import App, { AppContext, AppProps, NextWebVitalsMetric } from 'next/app';
import { useRouter } from 'next/router';
import { ClientAppConfig } from '@ads-bread/shared/bread/codecs';
import { IntlProvider } from '../components/IntlContext';
import { XPropsProvider } from '../components/XPropsContext';
import { AppDataContextProvider } from '../components/AppDataContext';
import { MerchantConfigProvider } from '../components/MerchantContext';
import { ToastProvider } from '../components/Toast';
import { FAQs } from '../components/screens/FAQs';
import { Header } from '../components/Header';
import { Modal } from '../components/Modal';
import { configureValidationLocale } from '../lib/yup';
import '../styles/index.css';
import { ThemeStyles } from '../components/ThemeStyles';
import { Footer } from '../components/footer';
import { Datadog } from '../components/analytics/Datadog';
import { AppConfigProvider } from '../components/AppConfigContext';
import { Spinner } from '../components/svgs/Spinner';
import { Analytics } from '../components/analytics/Analytics';
import { ClientSideRouteGuard } from '../components/ClientSideGuard';
import { GlobalErrorBoundary } from '../components/GlobalErrorBoundary';
import { LoadingManager } from '../components/LoadingManager';
import * as rum from '../lib/rum';
import { MirageLoader } from '../components/MirageLoader';
import { AdobeTMSScript } from '../components/analytics/AdobeTMSScript';
import { ApplicationMachineProvider } from '../components/ApplicationMachineContext';
import { BuyerMachineProvider } from '../components/BuyerMachineContext';
import { AuthenticationMachineProvider } from '../components/AuthenticationMachineContext';
import { RouteMachineService } from '../components/RouteMachineService';
import { FeatureFlagsProvider } from '../components/FeatureFlagsContext';
import { MachineSubscribers } from '../components/MachineSubscribers';
import { FraudAlloyProvider } from '../components/FraudAlloyContext';

// App initialization tasks, to run as early as possible...
appInit();

export function reportWebVitals(metric: NextWebVitalsMetric): void {
  const name = metric.name;
  if (metric.value === undefined) {
    metric = { ...metric, value: metric.startTime };
  }
  rum.addAction(name, metric);
}

export async function appInit(): Promise<void> {
  configureValidationLocale();
}

export function MyApp({
  Component,
  pageProps,
  config,
}: AppProps & { config?: ClientAppConfig }): JSX.Element | null {
  // `/host` page is meant to emulate a third-party site. This allows us to
  // have a small demo environment within the checkout app in the short term. We
  // expect this to migrate to a separate app or host in the medium term.
  // `/disclosure/...` route is meant to render a stand-alone, full-page html
  // document. We do not want to wrap it in our application layout or contexts.
  const router = useRouter();
  const isHostPageRoute = router.pathname.includes('host');
  const isDisclosureRoute = router.pathname.includes('disclosure');

  const [showFAQs, setShowFAQs] = useState(false);

  if (isHostPageRoute) {
    return (
      <AppConfigProvider config={config}>
        <Component {...pageProps} />
      </AppConfigProvider>
    );
  }

  if (isDisclosureRoute) {
    return (
      <AppConfigProvider config={config}>
        <Component {...pageProps} />
      </AppConfigProvider>
    );
  }

  if (router.pathname === '/_error') {
    return <Component {...pageProps} />;
  }

  return (
    <GlobalErrorBoundary>
      <AppConfigProvider config={config}>
        <AdobeTMSScript />
        <IntlProvider>
          <LoadingManager>
            {({ isLoaded, loading, removeLoader }) => (
              <MirageLoader removeLoader={removeLoader}>
                {isLoaded('MIRAGE') ? (
                  <XPropsProvider removeLoader={removeLoader}>
                    <MerchantConfigProvider>
                      <ThemeStyles />
                      {isLoaded('X_PROPS') ? (
                        <FeatureFlagsProvider removeLoader={removeLoader}>
                          <AppDataContextProvider>
                            <AuthenticationMachineProvider>
                              <BuyerMachineProvider>
                                <ApplicationMachineProvider>
                                  <FraudAlloyProvider>
                                    <Modal>
                                      <ToastProvider>
                                        <div>
                                          <Analytics />
                                          <Header setShowFAQs={setShowFAQs} />
                                          <ClientSideRouteGuard>
                                            <Datadog />

                                            <RouteMachineService>
                                              {(props) =>
                                                loading ? (
                                                  <LoadingContent />
                                                ) : (
                                                  <>
                                                    <Component
                                                      {...pageProps}
                                                      {...props}
                                                    />
                                                    <MachineSubscribers />
                                                  </>
                                                )
                                              }
                                            </RouteMachineService>
                                          </ClientSideRouteGuard>
                                          <Footer />
                                        </div>
                                        {showFAQs && (
                                          <FAQs
                                            onBack={() => setShowFAQs(false)}
                                          />
                                        )}
                                      </ToastProvider>
                                    </Modal>
                                  </FraudAlloyProvider>
                                </ApplicationMachineProvider>
                              </BuyerMachineProvider>
                            </AuthenticationMachineProvider>
                          </AppDataContextProvider>
                        </FeatureFlagsProvider>
                      ) : (
                        <LoadingContent />
                      )}
                    </MerchantConfigProvider>
                  </XPropsProvider>
                ) : (
                  <LoadingContent />
                )}
              </MirageLoader>
            )}
          </LoadingManager>
        </IntlProvider>
      </AppConfigProvider>
    </GlobalErrorBoundary>
  );
}

MyApp.getInitialProps = async (context: AppContext) => {
  const initialProps = App.getInitialProps(context);
  // Run only on server
  if (typeof window === 'undefined') {
    const { clientAppConfig } = await import('../lib/config');
    return { ...initialProps, config: clientAppConfig };
  }
  return { ...initialProps };
};

export default MyApp;

const LoadingContent: FunctionComponent = () => (
  <div className="flex justify-center items-center h-full w-full top-0 left-0 absolute">
    <Spinner aria-hidden="true" className="w-16 h-16 text-theme-primary" />
  </div>
);
