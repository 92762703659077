import { isBefore, parseISO } from 'date-fns';
import {
  Application,
  APPLICATION_STATUS,
  FilteredApplication,
  FilteredOffer,
  isLocalizedString,
  MerchantPaymentProduct,
  Order,
  OrderItem,
  Locale,
} from '@ads-bread/shared/bread/codecs';
import {
  NamespacePath,
  NAMESPACE_MAP,
  APPLICATION_STATUS_CODES,
  hasValidOffers,
} from '@ads-bread/shared/bread/util';
import { totalPriceIsCovered } from '../../lib/application';
import { orderEqual } from '../../lib/order';
import { APPLICATION_ID_VERIFICATION_STATUS_CODES } from '../../lib/applicationStatus';

/**
 * Determine if an application is an in store application
 * @param application
 * @returns boolean
 */
export function isUnexpiredInStoreApplication(
  namespace: NamespacePath,
  application: Application
): boolean {
  return !!(
    namespace === NAMESPACE_MAP.InStore &&
    application.offers?.some(
      (offer) =>
        offer.shortCode?.value &&
        isBefore(new Date(), parseISO(offer.shortCode.expiresAt))
    )
  );
}

/**
 * Checks an application for ineligible buyer address
 * @param application
 * @returns boolean
 */
export const isIneligibleAddress = (
  application: Application | null
): boolean => {
  return !!application?.statusCodes?.some(
    (statusCode) =>
      APPLICATION_STATUS_CODES.EligibilityIneligibleInvalidAddressPOBox ===
      statusCode
  );
};

/**
 * Determine if an order on an application matches the current order
 * @param validApplication Application
 * @param order Order
 * @returns boolean
 */
export function matchesPreviouslyApproved(
  validApplication: Application,
  order: Order
): boolean {
  return (
    order &&
    totalPriceIsCovered(validApplication, order) &&
    orderEqual(validApplication.order, order)
  );
}

/**
 * Ensures an application is approved with valid offers
 * @param application Application
 * @param merchantPaymentProducts
 * @returns boolean
 */
export function isValidApplication(
  application: Application,
  merchantPaymentProducts: MerchantPaymentProduct[]
): boolean {
  const isValidApp = !!(
    application &&
    application.status === APPLICATION_STATUS.APPROVED &&
    application.offers?.some(
      (offer) =>
        offer.paymentAgreement.status === 'OFFERED' &&
        isBefore(new Date(), parseISO(offer.paymentAgreement.expiresAt))
    )
  );
  const isValidPaymentProducts =
    !!application.offers &&
    hasValidOffers(application.offers, merchantPaymentProducts);
  return isValidApp && isValidPaymentProducts;
}

/**
 * Filters capacity from application if merchant does not have permission to view capacity
 * @param app Application
 * @param canViewCapacity boolean
 * @returns FilteredApplication
 */
export const filterApplicationCapacity = (
  app: Application,
  canViewCapacity: boolean
): FilteredApplication => {
  if (canViewCapacity) {
    return app;
  }
  const capacityToggleOff: FilteredApplication = {
    ...app,
    offers: (app?.offers || []).map((offer) => {
      const appOffer: FilteredOffer = {
        ...offer,
      };
      const { capacity, overflowCapacity, ...filteredOffer } = appOffer;
      return filteredOffer;
    }),
  };

  const { capacity, ...filteredApplication } = capacityToggleOff;

  return filteredApplication;
};

/**
 * Returns shipping descriptions as localized string
 * @param orderItems
 * @param locale
 * @returns OrderItem[]
 */
export function stringifyShippingDescription(
  orderItems: OrderItem[],
  locale: Locale
): OrderItem[] {
  return orderItems.map((item) => ({
    ...item,
    shippingDescription: isLocalizedString(item.shippingDescription)
      ? item.shippingDescription[locale]
      : item.shippingDescription,
  }));
}

/**
 * Gets origin code from an application status code for ID Verification
 * @param statusCodes
 * @returns '04' | '05'
 */
export const getOriginFromStatus = (statusCodes: string[]): '04' | '05' => {
  const idVerificationStatusCode = statusCodes.find((statusCode) => {
    return APPLICATION_ID_VERIFICATION_STATUS_CODES.includes(statusCode);
  });

  if (!idVerificationStatusCode) {
    throw new Error('Unable to get origin from application status code!');
  }

  return idVerificationStatusCode.toString().slice(0, 2) as '04' | '05';
};
