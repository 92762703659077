import { FunctionComponent } from 'react';
import { useApplicationMachine } from './useApplicationMachine';

export const ApplicationSubscriber: FunctionComponent = () => {
  const { state } = useApplicationMachine();
  return (
    <pre className="text-sm">
      {JSON.stringify({ value: state.value }, null, 2)}
      {JSON.stringify({ value: state?.context }, null, 2)}
    </pre>
  );
};
