import { FunctionComponent, useMemo } from 'react';
import { useNamespace } from '../../lib/hooks/useNamespace';
import {
  EcommRoutingMachineProvider,
  EcommRoutingMachineSnapshot,
} from './contexts/EcommRoutingMachineContext/EcommRoutingMachineContext';
import {
  InStoreRoutingMachineProvider,
  InStoreRoutingMachineSnapshot,
} from './contexts/InStoreRoutingMachineContext/InStoreRoutingMachineContext';
import {
  CartRoutingMachineProvider,
  CartRoutingMachineSnapshot,
} from './contexts/CartRoutingMachineContext/CartRoutingMachineContext';
import {
  RBCRoutingMachineProvider,
  RBCRoutingMachineSnapshot,
} from './contexts/RBCRoutingMachineContext/RBCRoutingMachineContext';
import { RoutePathCondition } from './constants/routePathCondition';

export type RouteMachineServiceSnapshots =
  | EcommRoutingMachineSnapshot
  | InStoreRoutingMachineSnapshot
  | RBCRoutingMachineSnapshot
  | CartRoutingMachineSnapshot;
export interface RouteMachineServiceScreenProps {
  isTransitioning: boolean;
  /**
   * Sends forward event
   * @returns void
   */
  forward: () => void;
  /**
   * Sends back event
   * @returns void
   */
  back: () => void;
  /**
   * Raises an event that can be handled to route conditionally
   * within the state of a router
   * @param cond RoutePathCondition
   * @returns void
   */
  routeWithCondition: (cond: RoutePathCondition) => void;
}

// Used to implement routing machine render props
export interface RouteMachineServiceProps {
  children: (options: RouteMachineServiceScreenProps) => JSX.Element;
}

// Used to implement routing machine context
export interface RouteMachineContextProps<ServiceT> {
  service: ServiceT;
  isTransitioning: boolean;
}

/**
 * Determines the correct routing machine provider to make available for the app based on namespace
 * @param React.Children
 * @returns FunctionComponent<RouteMachineServiceProps>
 */
export const RouteMachineService: FunctionComponent<RouteMachineServiceProps> =
  ({ children }) => {
    const namespace = useNamespace();

    /**
     * Returns the appropriate router per experience by namespace
     */
    const RoutingMachine =
      useMemo((): FunctionComponent<RouteMachineServiceProps> => {
        switch (namespace) {
          case 'alliance':
            return EcommRoutingMachineProvider;
          case 'in-store/[uuid]':
            return InStoreRoutingMachineProvider;
          case 'cart/[cartID]':
            return CartRoutingMachineProvider;
          case 'rbc':
            return RBCRoutingMachineProvider;
          default:
            return EcommRoutingMachineProvider;
        }
      }, [namespace]);

    return <RoutingMachine>{(props) => children(props)}</RoutingMachine>;
  };
