import { useContext } from 'react';
import { StateFrom } from 'xstate';
import { useSelector } from '@xstate/react';
import {
  Address,
  Buyer,
  ContactOutgoing,
  Name,
  OutgoingBuyer,
} from '@ads-bread/shared/bread/codecs';
import { HandlerWrapper } from '../../lib/handlers/base';
import { BuyerMachineContext } from './BuyerMachineContext';
import { BuyerContext, buyerMachine } from './buyerMachine';

export type BuyerMachineSnapshot = StateFrom<typeof buyerMachine>;

export interface UseBuyerMachineValues extends BuyerContext {
  state: BuyerMachineSnapshot;
  createBuyer: HandlerWrapper<OutgoingBuyer, Buyer, ContactOutgoing>;
  updateBuyer: HandlerWrapper<
    OutgoingBuyer,
    Buyer,
    ContactOutgoing | undefined
  >;
  resetBuyerState: () => void;
  assignBuyerName: (buyerName: Name) => void;
  assignBuyerEmail: (email: string) => void;
  assignBuyerPhone: (phone: string) => void;
  assignBuyerIIN: (iin: string) => void;
  assignBuyerDOB: (dob: string) => void;
  assignBuyerShippingAddress: (shippingAddress: Address) => void;
  assignBuyerBillingAddress: (billingAddress: Address) => void;
}

/**
 * Hook to interact with the buyer machine context
 * @returns UseBuyerMachineValues
 */
export const useBuyerMachine = (): UseBuyerMachineValues => {
  const buyerService = useContext(BuyerMachineContext);

  if (!buyerService) {
    throw new Error('Buyer machine service is null!');
  }
  const state = useSelector(buyerService, (s) => s);

  const updateBuyerWrapper: HandlerWrapper<
    OutgoingBuyer,
    Buyer,
    ContactOutgoing | undefined
  > = async (updatedBuyer, contact) => {
    return new Promise((resolve, reject) => {
      buyerService.send({
        type: 'SEND_UPDATE_BUYER',
        params: {
          context: state.context,
          buyer: updatedBuyer,
          contact,
          resolve,
          reject,
        },
      });
    });
  };

  const createBuyerWrapper: HandlerWrapper<
    OutgoingBuyer,
    Buyer,
    ContactOutgoing
  > = async (newBuyer, contact) => {
    return new Promise((resolve, reject) => {
      buyerService.send({
        type: 'SEND_CREATE_BUYER',
        params: {
          buyer: newBuyer,
          contact,
          resolve,
          reject,
        },
      });
    });
  };

  const resetBuyerState = (): void => {
    buyerService.send({ type: 'SEND_RESET_BUYER_STATE' });
  };

  const assignBuyerName = (name: Name): void => {
    buyerService.send({
      type: 'SEND_UPDATE_BUYER_NAME',
      params: {
        buyerName: name,
      },
    });
  };

  const assignBuyerEmail = (buyerEmail: string): void => {
    buyerService.send({
      type: 'SEND_UPDATE_BUYER_EMAIL',
      params: {
        email: buyerEmail,
      },
    });
  };

  const assignBuyerPhone = (buyerPhone: string): void => {
    buyerService.send({
      type: 'SEND_UPDATE_BUYER_PHONE',
      params: {
        phone: buyerPhone,
      },
    });
  };

  const assignBuyerIIN = (buyerIIN: string): void => {
    buyerService.send({
      type: 'SEND_UPDATE_BUYER_IIN',
      params: {
        iin: buyerIIN,
      },
    });
  };

  const assignBuyerDOB = (buyerDOB: string): void => {
    buyerService.send({
      type: 'SEND_UPDATE_BUYER_DOB',
      params: {
        dob: buyerDOB,
      },
    });
  };

  const assignBuyerShippingAddress = (buyerShippingAddress: Address): void => {
    buyerService.send({
      type: 'SEND_UPDATE_BUYER_SHIPPING_ADDRESS',
      params: {
        shippingAddress: buyerShippingAddress,
      },
    });
  };

  const assignBuyerBillingAddress = (buyerBillingAddress: Address): void => {
    buyerService.send({
      type: 'SEND_UPDATE_BUYER_BILLING_ADDRESS',
      params: {
        billingAddress: buyerBillingAddress,
      },
    });
  };

  return {
    state,
    buyer: state.context.buyer,
    buyerName: state.context.buyerName,
    buyerID: state.context.buyerID,
    email: state.context.email,
    phone: state.context.phone,
    iin: state.context.iin,
    dob: state.context.dob,
    shippingAddress: state.context.shippingAddress,
    billingAddress: state.context.billingAddress,
    assignBuyerName,
    assignBuyerEmail,
    assignBuyerPhone,
    assignBuyerIIN,
    assignBuyerDOB,
    assignBuyerShippingAddress,
    assignBuyerBillingAddress,
    resetBuyerState,
    createBuyer: createBuyerWrapper,
    updateBuyer: updateBuyerWrapper,
  };
};
