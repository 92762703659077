import { useRouter } from 'next/router';
import { useSearchParams } from 'next/navigation';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 } from 'uuid';
import {
  Address,
  AggregatedMerchantProgramConfiguration,
  APIError,
  Cart,
  ExperienceKeys,
  MerchantConfig,
  MerchantPaymentProduct,
  MerchantProgramValues,
  MerchantDetails,
  Money,
  Name,
  Order,
  PaymentType,
  Program,
  FilteredApplication,
  FilteredCustomerCloseData,
  LocationResponse,
  DynamicDisclosure,
} from '@ads-bread/shared/bread/codecs';
import {
  AnalyticValues,
  LocationType,
  OneTrustConsentData,
  PlacementOptions,
  PromoOffer,
  toMoneyValue,
  usd,
  StorageKeys,
  getItemFromStorage,
  setItemInStorage,
  isValidUUID,
} from '@ads-bread/shared/bread/util';
import { ZoidContext } from 'zoid/dist/zoid.frameworks';
import {
  CheckoutXProps,
  defaultXProps as rawDefaultXProps,
} from '../lib/defaultXProps';
import { readXProps } from '../lib/readXProps';
import { getExperienceKeys } from '../lib/handlers/get-experience-keys';
import { getProgramData } from '../lib/handlers/get-program-data';
import { useFetch } from '../lib/hooks/apiFetch';
import { useMounted } from '../lib/hooks/useMounted';
import { logger } from '../lib/logger';
import { getCartPublic } from '../lib/handlers/get-cart-public';
import { getMerchantLocation } from '../lib/handlers/get-merchant-location';
import { findMerchantPaymentProduct } from '../lib/findMerchantPaymentProduct';
import { FCWithChildren } from '../lib/types';
import { Loader } from './LoadingManager';
import { useAppConfig } from './AppConfigContext';
import { FallbackErrorScreen } from './GlobalErrorBoundary';

type CustomXProps = {
  buyerID?: string;
};

declare const window: Window & {
  Cypress: any;
  handleFromCypress: (req: any) => Promise<any[]>;
  customXProps: CustomXProps;
};

export type OneTrustConsentEvent = CustomEvent<{ detail: string[] }>;

declare global {
  interface CustomEventMap {
    'consent.onetrust': OneTrustConsentEvent;
  }

  interface Window {
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (ev: CustomEventMap[K]) => void,
      options?: boolean | AddEventListenerOptions
    ): void;
  }
}

function createInstoreOrder(
  programResult: AggregatedMerchantProgramConfiguration
): Order {
  const minCartSizes = programResult.merchantPaymentProducts.map(
    (mpp) => mpp.merchantValues.minCartSize.value
  );

  const highestMinCartSize = Math.max(...minCartSizes);
  return {
    items: [],
    subTotal: usd(highestMinCartSize),
    totalDiscounts: usd(0),
    totalPrice: usd(highestMinCartSize),
    totalShipping: usd(0),
    totalTax: usd(0),
  };
}

type ContextXProps = {
  analytics: AnalyticValues;
  merchantOrigin: string;
  appID: string;
  billingAddress?: Address;
  buyerID?: string;
  displayInterestRateBPS?: number;
  email?: string;
  merchantID: string;
  phone?: string;
  programID: string;
  locationID: string;
  deviceID: string;
  cartID?: string;
  merchantConfig?: MerchantConfig;
  merchantPaymentProducts?: MerchantPaymentProduct[];
  merchantProgramValues?: MerchantProgramValues;
  merchantDetails?: MerchantDetails;
  program?: Program;
  name?: Name;
  dynamicDisclosure?: DynamicDisclosure;
  triggerOpenCardModal?: (placementInfo: {
    location?: string;
    placementId?: string;
  }) => void;
  onAddToCart?: (order?: Order) => void;
  onCustomerOpen?: (
    locationType: LocationType,
    placement: PlacementOptions
  ) => void;
  onCustomerClose?: (data: FilteredCustomerCloseData) => void;
  onApproved?: (data: FilteredApplication) => void;
  onOneTrustConsent?: (data: OneTrustConsentData) => void;
  onCheckout?: (data: FilteredApplication) => void;
  checkoutCompleteURL?: string | null;
  checkoutErrorURL?: string | null;
  isDefaultOrder: boolean;
  isDefaultXProps?: boolean;
  isEmbedded: boolean;
  isPreview: boolean;
  isLocationEnabled: boolean;
  allowCheckout: boolean;
  domID: string;
  orderReference?: string;
  order: Order;
  modal: boolean;
  promoOffers?: PromoOffer[];
  shippingAddress?: Address;
  zoidContext: ZoidContext | null;
  zoidClose: (() => Promise<void>) | null;
  zoidHide: (() => void) | null;
};

export const XPropsContext = createContext<ContextXProps | null>(null);

type Props = {
  checkoutXProps?: CheckoutXProps;
  removeLoader: (loader: Loader) => void;
};

export function flattenXProps(windowXProps: CheckoutXProps): ContextXProps {
  return {
    merchantOrigin: windowXProps.options.merchantOrigin,
    allowCheckout: windowXProps.options.allowCheckout,
    analytics: windowXProps.options.analytics,
    appID: windowXProps.options.appID,
    billingAddress: windowXProps.options.billingAddress,
    buyerID: windowXProps.options.buyerID,
    displayInterestRateBPS: windowXProps.options.displayInterestRateBPS,
    email: windowXProps.options.email,
    domID: windowXProps.options.domID,
    isDefaultOrder: windowXProps.options.isDefaultOrder,
    isDefaultXProps: windowXProps.isDefaultXProps,
    isEmbedded: windowXProps.options.isEmbedded,
    isPreview: windowXProps.options.isPreview,
    isLocationEnabled: windowXProps.options.isLocationEnabled,
    merchantConfig: windowXProps.options.merchantConfig,
    merchantID: windowXProps.options.merchantID,
    locationID: windowXProps.options.locationID,
    deviceID: windowXProps.options.deviceID,
    merchantPaymentProducts: windowXProps.options.merchantPaymentProducts,
    merchantProgramValues: windowXProps.options.merchantProgramValues,
    program: windowXProps.options.program,
    modal: windowXProps.options.modal,
    name: windowXProps.options.name,
    onAddToCart: windowXProps.options.onAddToCart,
    onCustomerOpen: windowXProps.options.onCustomerOpen,
    onCustomerClose: windowXProps.options.onCustomerClose,
    onApproved: windowXProps.options.onApproved,
    onOneTrustConsent: windowXProps.options.onOneTrustConsent,
    onCheckout: windowXProps.options.onCheckout,
    triggerOpenCardModal: windowXProps.options.triggerOpenCardModal,
    order: filterOrder(
      windowXProps.options.order,
      windowXProps.options.merchantDetails?.hipaaRestricted
    ),
    phone: windowXProps.options.phone,
    promoOffers: windowXProps.options.promoOffers,
    programID: windowXProps.options.programID,
    shippingAddress: windowXProps.options.shippingAddress,
    merchantDetails: windowXProps.options.merchantDetails,
    dynamicDisclosure: windowXProps.options.dynamicDisclosure,
    zoidContext: windowXProps.context,
    zoidClose: windowXProps.close,
    zoidHide: windowXProps.hide,
  };
}

function filterOrder(
  order: Order,
  hipaaRestricted: boolean | undefined
): Order {
  if (!hipaaRestricted) {
    return order;
  }
  return {
    items: [],
    subTotal: order.subTotal,
    totalDiscounts: order.totalDiscounts,
    totalPrice: order.totalPrice,
    totalShipping: order.totalShipping,
    totalTax: order.totalTax,
    fulfillmentType: order.fulfillmentType,
  };
}

export const XPropsProvider: FCWithChildren<Props> = ({
  children,
  checkoutXProps = null,
  removeLoader,
}) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [xProps, setXProps] = useState<ContextXProps | null>(null);
  const rawXProps = useRef(checkoutXProps);
  const { query, route } = useRouter();
  const searchParams = useSearchParams();
  const apiFetch = useFetch();
  const isMounted = useMounted();
  const { defaultMerchantConfig, appID } = useAppConfig();
  const defaultXProps: CheckoutXProps = {
    ...rawDefaultXProps,
    options: {
      ...rawDefaultXProps.options,
      merchantConfig: defaultMerchantConfig,
    },
  };

  if (
    (!xProps && checkoutXProps) ||
    (checkoutXProps && checkoutXProps !== rawXProps.current)
  ) {
    rawXProps.current = checkoutXProps;
    setXProps(flattenXProps(checkoutXProps));
  }

  const handleOnCustomerOpen = (flattenedXProps: ContextXProps) => {
    const {
      onCustomerOpen,
      analytics: { locationType },
      allowCheckout,
      domID,
      order,
    } = flattenedXProps;
    if (onCustomerOpen) {
      onCustomerOpen(locationType, {
        allowCheckout,
        domID,
        locationType,
        order,
      });
    }
  };

  const handleOneTrustConsent = (flattenedXProps: ContextXProps) => {
    const { onOneTrustConsent } = flattenedXProps;
    if (onOneTrustConsent) {
      window.addEventListener(
        'consent.onetrust',
        (data: CustomEventMap['consent.onetrust']) => {
          onOneTrustConsent(data.detail);
        }
      );
    }
  };

  useEffect(() => {
    const getCheckout = async () => {
      const isDirectExperienceRoute = ['/in-store', '/cart'].some((r) =>
        route.startsWith(r)
      );
      if (!isDirectExperienceRoute) {
        const { createCheckoutComponent } = await import(
          '@ads-bread/bread-sdk'
        );
        createCheckoutComponent('', false);
        const windowXProps = readXProps();
        if (windowXProps) {
          const flattenedXProps = flattenXProps(windowXProps);
          setXProps(flattenedXProps);
          handleOnCustomerOpen(flattenedXProps);
          handleOneTrustConsent(flattenedXProps);
        } else {
          // Set xprops to example defaults, we are not in an SDK iframe
          const mergedXProps =
            window.Cypress && window.customXProps
              ? {
                  ...defaultXProps,
                  options: { ...defaultXProps.options, ...window.customXProps },
                }
              : defaultXProps;
          const flattenedXProps =
            (checkoutXProps && flattenXProps(checkoutXProps)) ||
            flattenXProps(mergedXProps);
          setXProps(flattenedXProps);
          handleOnCustomerOpen(flattenedXProps);
        }
        removeLoader('X_PROPS');
        windowXProps?.onProps((props) => {
          // N.B. `props` needs to be cloned here in order for React to react to
          // changes in state of nested properties, e.g. `options.allowChecout`
          const flattenedXProps = flattenXProps(props);
          setXProps(flattenedXProps);
          handleOnCustomerOpen(flattenedXProps);
        });
      }
    };
    getCheckout();
  }, []);

  useEffect(() => {
    const setupProgramData = async () => {
      const { uuid, cartID } = query;
      const buyerIDQueryParam = searchParams?.get('buyerID');
      const programIDQueryParam = searchParams?.get('programID');
      const locationIDQueryParam = searchParams?.get('locationID');

      try {
        const maybeShortCodeOrMerchantID =
          typeof uuid === 'string' ? uuid : null;

        const isCart = route.startsWith('/cart');
        const isInStore = route.startsWith('/in-store');
        const isDirectExperienceRoute = isCart || isInStore;

        // short code passed instead of merchantID
        const isValidInStoreShortCode =
          !!maybeShortCodeOrMerchantID &&
          !isValidUUID(maybeShortCodeOrMerchantID);

        // merchantID passed instead of short-code
        const isValidInStoreMerchantID = isValidUUID(
          maybeShortCodeOrMerchantID
        );

        const isValidCartID = !!cartID && typeof cartID === 'string';

        // in-store with merchantID instead of short code
        const isValidInStoreMerchantIDSearchParams = !!(
          programIDQueryParam &&
          isValidInStoreMerchantID &&
          locationIDQueryParam
        );

        // Cart or in store configuration is valid
        const isValidQuery =
          isValidInStoreShortCode ||
          isValidInStoreMerchantIDSearchParams ||
          isValidCartID;

        if (!isDirectExperienceRoute || !isValidQuery || xProps) {
          return;
        }

        let deviceID = getItemFromStorage<string>(StorageKeys.DEVICE_ID);
        if (!deviceID) {
          deviceID = v4();
          setItemInStorage<string>(StorageKeys.DEVICE_ID, deviceID);
        }

        let cartData: Cart | null = null;
        let experienceData: ExperienceKeys | null = null;
        let locationData: LocationResponse | null = null;
        let apiError: APIError | null = null;

        if (isCart && isValidCartID) {
          ({ error: apiError, result: cartData } = await getCartPublic(
            apiFetch,
            cartID
          ));
        } else if (
          isInStore &&
          isValidInStoreShortCode &&
          !isValidInStoreMerchantIDSearchParams
        ) {
          ({ error: apiError, result: experienceData } =
            await getExperienceKeys(apiFetch, maybeShortCodeOrMerchantID));
        }

        if (apiError) {
          throw apiError;
        }

        // Ensure we have required params and are in the in store experience
        if (
          isInStore &&
          (experienceData?.merchantID || maybeShortCodeOrMerchantID) &&
          (experienceData?.locationID || locationIDQueryParam)
        ) {
          ({ error: apiError, result: locationData } =
            await getMerchantLocation(
              apiFetch,
              experienceData?.merchantID || maybeShortCodeOrMerchantID || '',
              experienceData?.locationID || locationIDQueryParam || ''
            ));
        }

        if (apiError) {
          throw apiError;
        }

        const { error: programError, result: programResult } =
          await getProgramData(
            apiFetch,
            cartData?.programID ||
              experienceData?.programID ||
              programIDQueryParam ||
              '',
            cartData?.merchantID ||
              experienceData?.merchantID ||
              maybeShortCodeOrMerchantID ||
              ''
          );

        if (programError) {
          throw programError;
        }

        if (programResult && isMounted.current) {
          setXProps({
            merchantOrigin: window.location.origin,
            appID: experienceData?.appID || appID,
            displayInterestRateBPS:
              programResult.program.displayInterestRateBPS,
            merchantID:
              cartData?.merchantID ||
              experienceData?.merchantID ||
              maybeShortCodeOrMerchantID ||
              '',
            programID:
              cartData?.programID ||
              experienceData?.programID ||
              programIDQueryParam ||
              '',
            locationID:
              experienceData?.locationID || locationIDQueryParam || '',
            merchantConfig: programResult.merchantConfig,
            merchantPaymentProducts: programResult.merchantPaymentProducts,
            merchantProgramValues: programResult.merchantProgramValues,
            merchantDetails: programResult.merchantDetails,
            program: programResult.program,
            orderReference: cartData?.orderReference || '',
            order: cartData?.order
              ? filterOrder(cartData.order, cartData.isHipaaRestricted)
              : createInstoreOrder(programResult),
            phone: cartData?.contact.phone || '',
            email: cartData?.contact.email || '',
            ...(isInStore &&
              buyerIDQueryParam && { buyerID: buyerIDQueryParam }),
            checkoutCompleteURL: cartData?.checkoutCompleteURL || null,
            checkoutErrorURL: cartData?.checkoutErrorURL || null,
            ...(cartData?.id && { cartID: cartData.id }),
            analytics: { experienceEntrypoint: '', locationType: 'Checkout' },
            deviceID,
            domID: '',
            isDefaultOrder: false,
            isPreview: false,
            isLocationEnabled: isInStore
              ? !!locationData?.location?.enabled
              : true,
            allowCheckout: true,
            modal: false,
            zoidContext: null,
            zoidClose: null,
            zoidHide: null,
            isEmbedded: false,
          });

          removeLoader('X_PROPS');
        }
      } catch (err) {
        logger.error(
          {
            err,
            shortCode: uuid,
            cartID,
            programID: programIDQueryParam,
            locationID: locationIDQueryParam,
          },
          'Error accessing program data'
        );
        setHasError(true);
      }
    };
    setupProgramData();
  }, [query]);

  if (hasError) {
    return <FallbackErrorScreen />;
  }

  return (
    <XPropsContext.Provider value={xProps}>{children}</XPropsContext.Provider>
  );
};

// A separate hook just for AppID so it can be consumed by apiFetch even before
// xProps are set. It maybe null but that is ok for API requests that do not
// require an AppID
export const useAppID = (): { appID: string | null } => {
  const xProps = useContext(XPropsContext);
  return { appID: xProps?.appID || null };
};

// A separate hook for just pulling the merchantOrigin from xProps.
export const useMerchantOrigin = (): { merchantOrigin: string } => {
  const xProps = useContext(XPropsContext);
  return { merchantOrigin: xProps?.merchantOrigin || '' };
};

// A separate hook just for merchantID so it can be consumed by apiFetch
export const useMerchantID = (): { merchantID: string | null } => {
  const xProps = useContext(XPropsContext);
  return { merchantID: xProps?.merchantID || null };
};

// A separate hook just for programID so it can be consumed by apiFetch
export const useProgramID = (): { programID: string | null } => {
  const xProps = useContext(XPropsContext);
  return { programID: xProps?.programID || null };
};

// A separate hook just for locationID so it can be passed to application create
export const useLocationID = (): string | null => {
  const xProps = useContext(XPropsContext);
  return xProps?.locationID || null;
};

// A separate hook just for cartID so it can be passed to application create
export const useCartID = (): string | null => {
  const xProps = useContext(XPropsContext);
  return xProps?.cartID || null;
};

// A separate hook for deviceID to it can be used in FeatureFlags requests
export const useDeviceID = (): string => {
  const xProps = useContext(XPropsContext);
  if (!xProps?.deviceID) {
    throw new Error('Cannot use XProps context via useDeviceID while null');
  }
  return xProps.deviceID;
};

// Determine if location is disabled (in store)
export const useIsLocationEnabled = (): boolean => {
  const xProps = useContext(XPropsContext);

  return !!xProps?.isLocationEnabled;
};

/**
 * For actions and context specific to Zoid.
 *
 **/
export const useSDKModalOptions = (): {
  /** whether the experience is rendered in an iframe or a browser popup.
   * null means it is embedded. */
  zoidContext: ZoidContext | null;
  /** if the experience is rendered in a modal or not */
  modal: boolean;
  /** hides the modal */
  zoidHide: (() => void) | null;
  /** closes the modal */
  zoidClose: (() => void) | null;
} => {
  const xProps = useContext(XPropsContext);
  return {
    zoidContext: xProps?.zoidContext || null,
    modal: !!xProps?.modal,
    zoidClose: xProps?.zoidClose || null,
    zoidHide: xProps?.zoidHide || null,
  };
};

export const useRawMerchantConfig = (): {
  merchantConfig?: MerchantConfig;
} => {
  const xProps = useContext(XPropsContext);
  return {
    merchantConfig: xProps?.merchantConfig,
  };
};

// Get order
export const useOrder = (): {
  order: Order;
  isDefaultOrder: boolean;
} => {
  const xProps = useContext(XPropsContext);
  if (!xProps?.order) {
    throw new Error('Cannot use XProps context via useOrder while null');
  }
  return { order: xProps.order, isDefaultOrder: xProps.isDefaultOrder };
};

// Access the order with no context safety checks
// Needed for use before xProps may be set
export const useRawOrder = (): {
  order: Order | undefined;
  isDefaultOrder: boolean;
} => {
  const xProps = useContext(XPropsContext);
  return { order: xProps?.order, isDefaultOrder: !!xProps?.isDefaultOrder };
};

/**
 * Get the merchants minimum cart size
 * @param merchantPaymentProducts
 * @returns GetMinCartSize
 */
export const getMinCartSize = (
  merchantPaymentProducts?: MerchantPaymentProduct[]
): Record<PaymentType, Money> => {
  const installmentProduct = findMerchantPaymentProduct(
    merchantPaymentProducts,
    'INSTALLMENTS'
  );

  const splitPayProduct = findMerchantPaymentProduct(
    merchantPaymentProducts,
    'SPLITPAY'
  );

  const installmentsMinCartSize: Money = {
    currency: installmentProduct?.merchantValues.minCartSize.currency || 'usd',
    value: toMoneyValue(
      installmentProduct?.merchantValues.minCartSize.value || 0
    ),
  };

  const splitPayMinCartSize: Money = {
    currency: splitPayProduct?.merchantValues.minCartSize.currency || 'usd',
    value: toMoneyValue(splitPayProduct?.merchantValues.minCartSize.value || 0),
  };

  return {
    INSTALLMENTS: installmentsMinCartSize,
    SPLITPAY: splitPayMinCartSize,
  };
};

// Get merchant payment products
export const useMerchantPaymentProducts = (): {
  merchantPaymentProducts: MerchantPaymentProduct[];
  hasInstallments: boolean;
  hasSplitPay: boolean;
  minCartSizes: Record<PaymentType, Money>;
} => {
  const xProps = useContext(XPropsContext);
  const hasInstallments = (xProps?.merchantPaymentProducts || []).some(
    (mpp) => mpp.paymentProduct.type === 'INSTALLMENTS'
  );

  const hasSplitPay = (xProps?.merchantPaymentProducts || []).some(
    (mpp) => mpp.paymentProduct.type === 'SPLITPAY'
  );

  const minCartSizes = useMemo(
    () => getMinCartSize(xProps?.merchantPaymentProducts),
    [xProps?.merchantPaymentProducts]
  );

  return {
    merchantPaymentProducts: xProps?.merchantPaymentProducts || [],
    minCartSizes,
    hasInstallments,
    hasSplitPay,
  };
};

// Get virtual card settings
export const useVirtualCard = (): {
  isVirtualCard: boolean;
  isMerchantTapToPayCapable: boolean;
  isMerchantKeyEntryCapable: boolean;
} => {
  const xProps = useContext(XPropsContext);

  if (!xProps?.merchantProgramValues || !xProps?.merchantDetails) {
    throw new Error('Cannot use useVirtualCard while context while null');
  }

  return {
    isVirtualCard:
      !!xProps.merchantProgramValues.policies?.virtualCardSettings?.enabled,
    isMerchantTapToPayCapable:
      !!xProps.merchantDetails.posCheckoutCapability?.includes('TapToPay'),
    isMerchantKeyEntryCapable:
      !!xProps.merchantDetails.posCheckoutCapability?.includes('KeyEntry'),
  };
};
export const useMerchantDetails = (): MerchantDetails => {
  const xProps = useContext(XPropsContext);

  if (!xProps?.merchantDetails) {
    throw new Error('Cannot use MerchantDetails while context while null');
  }

  return xProps?.merchantDetails;
};

type MaybeMerchantProgramValues = Partial<MerchantProgramValues>;

export const useMerchantProgramValues = (): MaybeMerchantProgramValues & {
  isDownPaymentEnabled: boolean;
} => {
  const xProps = useContext(XPropsContext);

  if (!xProps?.merchantProgramValues) {
    throw new Error(
      'Cannot use merchantProgramValues while context while null'
    );
  }

  return {
    ...xProps.merchantProgramValues,
    // Creates a convenience prop since this is deeply nested
    isDownPaymentEnabled:
      xProps.merchantProgramValues.derivedCapabilities.downPayment.installments,
  };
};

export const useProgramValues = (): Program => {
  const xProps = useContext(XPropsContext);
  if (!xProps?.program) {
    throw new Error('Cannot use program values while context while null');
  }

  return {
    ...xProps.program,
  };
};

// Get analytics values
export const useSDKAnalytics = (): AnalyticValues => {
  const xProps = useContext(XPropsContext);
  if (!xProps?.analytics) {
    throw new Error('Cannot use context while null');
  }
  return xProps.analytics;
};

// Get SDK options passed from PDP
export const useSDKOptions = (): {
  allowCheckout: boolean;
  isEmbedded: boolean;
  isPreview: boolean;
  domID: string | null;
} => {
  const xProps = useContext(XPropsContext);

  return {
    allowCheckout: !!xProps?.allowCheckout,
    isEmbedded: !!xProps?.isEmbedded,
    isPreview: !!xProps?.isPreview,
    domID: xProps?.domID || null,
  };
};

// Returns experience keys
export const useExperienceKeys = (): {
  appID: string;
  merchantID: string;
  locationID: string;
  programID: string;
} => {
  const xProps = useContext(XPropsContext);
  if (!xProps) {
    throw new Error('Cannot use context while null');
  }

  return {
    appID: xProps.appID,
    merchantID: xProps.merchantID,
    locationID: xProps.locationID,
    programID: xProps.programID,
  };
};

/**
 * This should only be used to populate AppDataContext after initialization
 * @returns Buyer xProps
 */
export const useBuyer = (): {
  name?: Name;
  email?: string;
  phone?: string;
  billingAddress?: Address;
  shippingAddress?: Address;
  buyerID?: string;
} => {
  const xProps = useContext(XPropsContext);
  return {
    name: xProps?.name,
    email: xProps?.email,
    phone: xProps?.phone,
    billingAddress: xProps?.billingAddress,
    shippingAddress: xProps?.shippingAddress,
    buyerID: xProps?.buyerID,
  };
};

// Get callbacks for events from the SDK
export const useSDKCallbacks = (): {
  onAddToCart?: (order?: Order) => void;
  onCheckout?: (data: FilteredApplication) => void;
  onApproved?: (data: FilteredApplication) => void;
  onOneTrustConsent?: (data: OneTrustConsentData) => void;
  onCustomerOpen?: (
    locationType: LocationType,
    placement: PlacementOptions
  ) => void;
  onCustomerClose?: (data: FilteredCustomerCloseData) => void;
  triggerOpenCardModal?: (placementInfo: {
    location?: string;
    placementId?: string;
  }) => void;
  checkoutCompleteURL?: string | null;
  checkoutErrorURL?: string | null;
} => {
  const xProps = useContext(XPropsContext);

  return {
    onAddToCart: xProps?.onAddToCart,
    onCheckout: xProps?.onCheckout,
    onApproved: xProps?.onApproved,
    onOneTrustConsent: xProps?.onOneTrustConsent,
    onCustomerOpen: xProps?.onCustomerOpen,
    onCustomerClose: xProps?.onCustomerClose,
    triggerOpenCardModal: xProps?.triggerOpenCardModal,
    checkoutCompleteURL: xProps?.checkoutCompleteURL,
    checkoutErrorURL: xProps?.checkoutErrorURL,
  };
};

// Get promo offers
export const useSDKPromoOffers = (): PromoOffer[] => {
  const xProps = useContext(XPropsContext);
  return xProps?.promoOffers || [];
};

// Check if props are set to default values
export const useIsDefaultSDKContextProps = (): boolean => {
  const xProps = useContext(XPropsContext);
  return !!xProps?.isDefaultXProps;
};

// Get dynamic disclosure
export const useDynamicDisclosure = (): DynamicDisclosure | null => {
  const xProps = useContext(XPropsContext);
  return xProps?.dynamicDisclosure || null;
};
