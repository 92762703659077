import { createContext } from 'react';
import { ActorRefFrom, fromPromise } from 'xstate';
import { useActorRef } from '@xstate/react';
import { decode } from 'jsonwebtoken';
import { AuthPayload } from '@ads-bread/shared/bread/codecs';
import { FCWithChildren } from '../../lib/types';
import { useFetch } from '../../lib/hooks/apiFetch';
import { useAuthTokenStore } from '../../lib/hooks/useAuthTokenStore';
import { useHasReviewedApplication } from '../../lib/hooks/useHasReviewedApplication';
import { isBreadJWT } from '../../lib/bread-jwt';
import { setAdditionalContext } from '../../lib/analytics';
import { logger } from '../../lib/logger';
import { logout } from '../../lib/handlers/logout';
import { sendCode } from '../../lib/handlers/send-code';
import { authorize } from '../../lib/handlers/auth';
import { useExperienceKeys, useBuyer } from '../XPropsContext';
import { authenticationMachine } from './authenticationMachine';
import {
  AuthenticationRequestParams,
  SendCodeParams,
  SendLogout,
  SendLogoutUnauthorized,
} from './types/events';
import { AuthorizeResult, SendCodeResult } from './types/actors';
import { assignOtpCode, assignRefID, resetContext } from './assigns';
import { AUTH_REASONS } from './constants/authReasons';

export type AuthenticationMachineActorRef = ActorRefFrom<
  typeof authenticationMachine
>;

export const AuthenticationMachineContext =
  createContext<AuthenticationMachineActorRef | null>(null);

export const AuthenticationMachineProvider: FCWithChildren = ({ children }) => {
  const { merchantID, programID } = useExperienceKeys();
  const { buyerID } = useBuyer();
  const { authToken, isAuthTokenActive, setAuthToken } = useAuthTokenStore();
  const apiFetch = useFetch();
  const { setHasReviewedApplication } = useHasReviewedApplication();

  const authenticationService = useActorRef(
    authenticationMachine.provide({
      guards: {
        isExchangedBuyer: (): boolean => {
          return !!buyerID;
        },
        isAuthTokenActive: (): boolean => {
          return isAuthTokenActive();
        },
        isAuthenticatedWithBuyer: (_, params): boolean => {
          return !!params.authRes.result?.buyerID;
        },
        isRetryWithPIIError: (_, params) => {
          return (
            params.authRes.error?.reason ===
            AUTH_REASONS.UNAUTHENTICATED_RETRY_WITH_PII
          );
        },
        isAuthenticatedAnonymous: (_, params) => {
          return !!params.authRes.result?.token;
        },
        isAuthenticatedAnonymousMismatchedBuyerPII: (_, params) => {
          return (
            !!params.authRes.result?.token && !params.authRes.result.buyerID
          );
        },
        isAuthenticatedMatchedBuyerPII: (_, params) => {
          return (
            !!params.authRes.result?.token && !!params.authRes.result.buyerID
          );
        },
        isMismatchedBuyerToken: (): boolean => {
          const decodedJwt = decode(authToken);
          if (isBreadJWT(decodedJwt)) {
            return decodedJwt.buyerID !== buyerID;
          }
          return false;
        },
      },
      actions: {
        assignOtpCode,
        assignRefID,
        resetContext,
        identifyAuthenticatedBuyer: (): void => {
          setAdditionalContext({
            returning_buyer: true,
          });
        },
        handleLogout: (): void => {
          setAuthToken('');
          setHasReviewedApplication(false);
        },
        handleSendCodeDone: (_, params): void => {
          params.resolve(params.sendCodeRes);
        },
        handleAuthenticatingDone: (_, params): void => {
          const token = params.authRes.result?.token;
          if (token) {
            setAuthToken(token);
          }
          params.resolve(params.authRes);
        },
        identifyReturningBuyer: (_, params) => {
          setAdditionalContext({
            returning_buyer: !!params.authRes.result?.buyerID,
          });
        },
        handleServiceError: (_, params): void => {
          params.reject(params.error);
        },
        logError: (_, params): void => {
          logger.error(
            { err: params.error, buyerID, merchantID },
            `Error in AuthenticationMachineContext: ${params.type}`
          );
        },
      },
      actors: {
        logout: fromPromise<void, SendLogout | SendLogoutUnauthorized>(
          async () => {
            await logout(apiFetch);
          }
        ),
        sendCode: fromPromise<SendCodeResult, SendCodeParams>(
          async ({ input }) => {
            try {
              const opts = input.options;
              const sendCodeRes = await sendCode(opts, apiFetch);

              return {
                sendCodeRes,
                resolve: input.resolve,
                reject: input.reject,
              };
            } catch (error) {
              throw { error, reject: input.reject };
            }
          }
        ),
        authorize: fromPromise<AuthorizeResult, AuthenticationRequestParams>(
          async ({ input }) => {
            try {
              const authPayload: AuthPayload = {
                merchantID,
                programID,
                buyerID: buyerID ?? '',
                referenceID: input.context.refID ?? '',
                credentials: {
                  ...(input.authorizeOptions.credentials
                    ? input.authorizeOptions.credentials
                    : {}),
                  code:
                    input.authorizeOptions.otpCode ||
                    input.context.otpCode ||
                    '',
                },
              };
              const authRes = await authorize(authPayload, apiFetch);

              return { authRes, resolve: input.resolve, reject: input.reject };
            } catch (error) {
              throw { error, reject: input.reject };
            }
          }
        ),
      },
    })
  );

  return (
    <AuthenticationMachineContext.Provider value={authenticationService}>
      {children}
    </AuthenticationMachineContext.Provider>
  );
};
