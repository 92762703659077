import { FunctionComponent, createContext, useMemo } from 'react';
import { ActorRefFrom, InspectionEvent, StateFrom } from 'xstate';
import { useActorRef } from '@xstate/react';
import { WalletStatus } from '@ads-bread/shared/bread/codecs';
import { isTapToPayCompatibleDevice } from '../../../../lib/user-agent';
import { useLoadingManager } from '../../../LoadingManager';
import { useFeatureFlags } from '../../../FeatureFlagsContext';
import { useHasReviewedApplication } from '../../../../lib/hooks/useHasReviewedApplication';
import { useBuyerMachine } from '../../../BuyerMachineContext';
import { useAuthentication } from '../../../AuthenticationMachineContext';
import { useApplicationMachine } from '../../../ApplicationMachineContext';
import { useAppData } from '../../../AppDataContext';
import { useIsLocationEnabled, useVirtualCard } from '../../../XPropsContext';
import { getLoadingIndicatorActions } from '../../actions';
import {
  RouteMachineContextProps,
  RouteMachineServiceProps,
} from '../../RouteMachineService';
import { getDefaultApplicationErrorRouteGuards } from '../../guards/applicationMachineErrors';
import { getDefaultBuyerErrorRouteGuards } from '../../guards/buyerMachineErrors';
import { getRoutePathConditionGuards } from '../../guards/routePathConditions';
import { useApplicationMachineStateEventEmitter } from '../../hooks/useApplicationMachineStateEventEmitter';
import { useAuthenticationMachineStateEventEmitter } from '../../hooks/useAuthenticationMachineStateEventEmitter';
import { useBuyerApplicationEventEmitter } from '../../hooks/useBuyerApplicationEventEmitter';
import { useBuyerMachineStateEventEmitter } from '../../hooks/useBuyerMachineStateEventEmitter';
import { useHandleStateRouteTransition } from '../../hooks/useHandleStateRouteTransition';
import { useRouteTransitionActions } from '../../hooks/useRouteTransitionActions';
import { useSyncRouterStateEventEmitter } from '../../hooks/useSyncRouterStateEventEmitter';
import { useInspectLogoutRouterEvent } from '../../hooks/useInspectLogoutRouterEvent';
import { routingMachine } from './inStoreRoutingMachine';

const persistedSearchParams = ['programID', 'locationID', 'buyerID'];

export type InStoreRoutingMachineSnapshot = StateFrom<typeof routingMachine>;

type InStoreRoutingMachineActorRef = ActorRefFrom<typeof routingMachine>;

export const InStoreRoutingMachineContext =
  createContext<RouteMachineContextProps<InStoreRoutingMachineActorRef | null> | null>(
    null
  );

export const InStoreRoutingMachineProvider: FunctionComponent<RouteMachineServiceProps> =
  ({ children }) => {
    const {
      isVirtualCard,
      isMerchantKeyEntryCapable,
      isMerchantTapToPayCapable,
    } = useVirtualCard();
    const isLocationEnabled = useIsLocationEnabled();
    const { state: authState } = useAuthentication();
    const { state: buyerState, buyerID } = useBuyerMachine();
    const {
      state: applicationState,
      idVerification: { backImageData },
    } = useApplicationMachine();
    const {
      data: { issuance },
    } = useAppData();
    const { hasReviewedApplication } = useHasReviewedApplication();
    const { addLoader, removeLoader } = useLoadingManager();
    const { enableAlloyJourney, isExchangedStreamlined } = useFeatureFlags();
    const inspectLogoutRouterEvent = useInspectLogoutRouterEvent();
    // Handle state route transitions
    const { isTransitioning, handleStateRouteTransition } =
      useHandleStateRouteTransition(persistedSearchParams);

    const routingMachineService = useActorRef(
      routingMachine.provide({
        guards: {
          isIDVerificationAlloy: () => enableAlloyJourney,
          isExchangedStreamlinedWithReviewedApplication: () => {
            return (
              hasReviewedApplication && !!buyerID && isExchangedStreamlined
            );
          },
          isExchangedNonStreamlined: () => {
            return !!buyerID && !isExchangedStreamlined;
          },
          isExchangedStreamlined: () => {
            return !!buyerID && isExchangedStreamlined;
          },
          isUnauthenticatedInitial: () => {
            return authState.matches({ unAuthenticated: 'initial' });
          },
          isAuthenticatedCompleteMismatchedBuyerPII: () => {
            return authState.matches({
              authenticated: { complete: 'mismatchedBuyerPII' },
            });
          },
          isBuyerReadyComplete: () => {
            return buyerState.matches({ ready: 'complete' });
          },
          hasBackImageData: () => {
            return !!backImageData;
          },
          isSplitPaySelected: () => {
            return applicationState.matches({
              ready: { approved: { selected: 'splitPay' } },
            });
          },
          isVirtualCardWithActiveWallet: () => {
            if (!isVirtualCard) {
              return false;
            }
            return !!issuance?.wallets?.some(
              (w) => w.status === WalletStatus.ACTIVE
            );
          },
          isVirtualCardWithTerminatedWallet: () => {
            if (!isVirtualCard) {
              return false;
            }
            return !!issuance?.wallets?.some(
              (w) =>
                w.status === WalletStatus.TERMINATED &&
                w.error === true &&
                !isMerchantKeyEntryCapable
            );
          },
          isLocationDisabled: () => {
            return !isLocationEnabled;
          },
          isVirtualCardApprovedApplication: () => {
            return applicationState.matches({
              ready: { approved: { inStore: 'virtualCard' } },
            });
          },
          isVirtualCardInvalidConfig: () => {
            if (!isVirtualCard) {
              return false;
            }
            return !isMerchantTapToPayCapable && !isMerchantKeyEntryCapable;
          },
          isVirtualCardUnsupportedDevice: () => {
            if (!isVirtualCard) {
              return false;
            }
            return !isMerchantKeyEntryCapable && !isTapToPayCompatibleDevice;
          },
          isPreparedInStoreApplication: () => {
            return applicationState.matches({
              ready: { approved: { inStore: 'prepared' } },
            });
          },
          // Route path condition guards
          ...getRoutePathConditionGuards(),
          // Application Error condition routing guards
          ...getDefaultApplicationErrorRouteGuards(),
          // Buyer Error condition routing guards
          ...getDefaultBuyerErrorRouteGuards(),
        },
        actions: {
          ...getLoadingIndicatorActions({ addLoader, removeLoader }),
        },
      }),
      {
        inspect: (inspectionEvent: InspectionEvent) => {
          handleStateRouteTransition(inspectionEvent);
          inspectLogoutRouterEvent(inspectionEvent);
        },
      }
    );

    // Shared event emitters
    useSyncRouterStateEventEmitter(routingMachineService);
    useBuyerApplicationEventEmitter(routingMachineService);
    useApplicationMachineStateEventEmitter(routingMachineService);
    useBuyerMachineStateEventEmitter(routingMachineService);
    useAuthenticationMachineStateEventEmitter(routingMachineService);

    const { forward, back, routeWithCondition } = useRouteTransitionActions(
      routingMachineService
    );

    const value = useMemo(() => {
      return { service: routingMachineService, isTransitioning };
    }, [routingMachineService, isTransitioning]);

    return (
      <InStoreRoutingMachineContext.Provider value={value}>
        {children({
          isTransitioning,
          forward,
          back,
          routeWithCondition,
        })}
      </InStoreRoutingMachineContext.Provider>
    );
  };
