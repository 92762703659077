import { assertEvent } from 'xstate';
import { ActionEventParams } from '../../../apply/lib/types';
import { ApplicationContext } from './applicationMachine';
import {
  ApplicationCheckoutActorDone,
  ApplicationMachineEvents,
  ApplicationPrepareCheckoutActorDone,
  CreateApplicationActorDone,
  ReCreateApplicationActorDone,
  ReCreateInStoreContingentApplicationActorDone,
  SendApplicationCheckoutParams,
  SendApplicationPrepareCheckoutParams,
  SendCreateApplication,
  SendEvaluateIDVerificationImagesParams,
  SendFetchApplicationParams,
  SendFetchLatestApplication,
  SendReCreateApplicationParams,
} from './types/events';
import {
  ApplicationCheckoutResult,
  ApplicationPrepareCheckoutResult,
  CreateApplicationResult,
  ReCreateApplicationResult,
  ReCreateInStoreContingentApplicationResult,
} from './types/actors';

/**
 * Gets input for fetch application actor
 * @param ActionEventParams<ApplicationContext,ApplicationMachineEvents>
 * @returns SendFetchApplicationParams
 */
export const getFetchApplicationInput = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  ApplicationMachineEvents
>): SendFetchApplicationParams => {
  assertEvent(event, 'SEND_FETCH_APPLICATION');

  return event.params;
};

/**
 * Gets event for fetch latest application actor
 * @param ActionEventParams<ApplicationContext,ApplicationMachineEvents>
 * @returns SendFetchLatestApplication
 */
export const getFetchLatestApplicationInput = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  ApplicationMachineEvents
>): SendFetchLatestApplication => {
  assertEvent(event, 'SEND_FETCH_LATEST_APPLICATION');

  return event;
};

/**
 * Gets event for create application actor
 * @param ActionEventParams<ApplicationContext,SendCreateApplication>
 * @returns SendCreateApplication
 */
export const getCreateApplicationInput = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  SendCreateApplication
>): SendCreateApplication => {
  return event;
};

/**
 * Gets input for re-create application actor
 * @param ActionEventParams<ApplicationContext,ApplicationMachineEvents>
 * @returns SendReCreateApplicationParams
 */
export const getReCreateApplicationInput = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  ApplicationMachineEvents
>): SendReCreateApplicationParams => {
  assertEvent(event, 'SEND_RECREATE_APPLICATION');

  return event.params;
};

/**
 * Gets input for evaluate ID verification images actor
 * @param ActionEventParams<ApplicationContext,ApplicationMachineEvents>
 * @returns SendEvaluateIDVerificationImagesParams
 */
export const getEvaluateIDVerificationImagesInput = ({
  context,
  event,
}: ActionEventParams<
  ApplicationContext,
  ApplicationMachineEvents
>): SendEvaluateIDVerificationImagesParams => {
  assertEvent(event, 'SEND_EVALUATE_ID_VERIFICATION_IMAGES');

  return {
    context,
    options: event.params.options,
    resolve: event.params.resolve,
    reject: event.params.reject,
  };
};

/**
 * Gets input for application checkout actor
 * @param ActionEventParams<ApplicationContext,ApplicationMachineEvents>
 * @returns SendApplicationCheckoutParams
 */
export const getApplicationCheckoutInput = ({
  context,
  event,
}: ActionEventParams<
  ApplicationContext,
  ApplicationMachineEvents
>): SendApplicationCheckoutParams => {
  assertEvent(event, 'SEND_APPLICATION_CHECKOUT');
  return {
    context,
    options: event.params.options,
    resolve: event.params.resolve,
    reject: event.params.reject,
  };
};

/**
 * Gets input for application prepare checkout actor
 * @param ActionEventParams<ApplicationContext, ApplicationMachineEvents>
 * @returns SendApplicationPrepareCheckoutParams
 */
export const getApplicationPrepareCheckoutInput = ({
  context,
  event,
}: ActionEventParams<
  ApplicationContext,
  ApplicationMachineEvents
>): SendApplicationPrepareCheckoutParams => {
  assertEvent(event, 'SEND_APPLICATION_PREPARE_CHECKOUT');
  return {
    context,
    options: event.params.options,
    resolve: event.params.resolve,
    reject: event.params.reject,
  };
};

/**
 * Gets input for prepare in store application actor
 * @param @param ActionEventParams<ApplicationContext, ApplicationMachineEvents>
 * @returns ApplicationContext
 */
export const getPrepareInStoreApplicationInput = ({
  context,
}: ActionEventParams<
  ApplicationContext,
  ApplicationMachineEvents
>): ApplicationContext => {
  return context;
};

/**
 * Gets input for prepare virtual card issuance actor
 * @param @param ActionEventParams<ApplicationContext, ApplicationMachineEvents>
 * @returns ApplicationContext
 */
export const getPrepareVirtualCadIssuanceInput = ({
  context,
}: ActionEventParams<
  ApplicationContext,
  ApplicationMachineEvents
>): ApplicationContext => {
  return context;
};

/** Gets input for create in store contingent application actor
 * @param ActionEventParams<ApplicationContext, CreateApplicationActorDone>
 * return CreateApplicationResult
 */
export const getReCreateInStoreContingentApplicationInput = ({
  event,
}: ActionEventParams<ApplicationContext, any>): CreateApplicationResult => {
  return event.output;
};

/**
 * Get error from application result
 * @param
 * ActionEventParams<
    ApplicationContext,
    | ApplicationPrepareCheckoutActorDone
    | ApplicationCheckoutActorDone
    | ReCreateApplicationActorDone
    | ReCreateInStoreContingentApplicationActorDone
    | CreateApplicationActorDone
  >
 * @returns
    | ApplicationPrepareCheckoutResult
    | ApplicationCheckoutResult
    | ReCreateApplicationResult
    | ReCreateInStoreContingentApplicationResult
    | CreateApplicationResult
 */
export const getEvaluateApplicationErrorInput = ({
  event,
}: ActionEventParams<
  ApplicationContext,
  | ApplicationPrepareCheckoutActorDone
  | ApplicationCheckoutActorDone
  | ReCreateApplicationActorDone
  | ReCreateInStoreContingentApplicationActorDone
  | CreateApplicationActorDone
>):
  | ApplicationPrepareCheckoutResult
  | ApplicationCheckoutResult
  | ReCreateApplicationResult
  | ReCreateInStoreContingentApplicationResult
  | CreateApplicationResult => {
  return event.output;
};
