import { assertEvent } from 'xstate';
import { ActionEventParams } from '../../../apply/lib/types';
import { Loader } from '../LoadingManager';
import {
  RouteMachineServiceEvents,
  SendRouteApplicationErrorParams,
  SendRouteBuyerErrorParams,
  SendRouteWithConditionParams,
  SendSyncRouterStateParams,
} from './types/events';

interface GetLoadingIndicatorActions {
  addLoadingIndicator: () => void;
  removeLoadingIndicator: () => void;
}
interface GetLoadingIndicatorActionsProps {
  addLoader: (loader: Loader) => void;
  removeLoader: (loader: Loader) => void;
}
/**
 * Shared actions to support adding and removing loaders the LoadingManager
 * @param GetLoadingIndicatorActionsProps
 * @returns GetLoadingIndicatorActions
 */
export const getLoadingIndicatorActions = ({
  addLoader,
  removeLoader,
}: GetLoadingIndicatorActionsProps): GetLoadingIndicatorActions => {
  return {
    addLoadingIndicator: () => {
      return addLoader('ROUTE_SERVICE');
    },
    removeLoadingIndicator: () => {
      return removeLoader('ROUTE_SERVICE');
    },
  };
};

/**
 * Get SendRouteWithConditionParams from SEND_ROUTE_WITH_CONDITION event
 * @param ActionEventParams<undefined, RouteMachineServiceEvents>
 * @returns SendRouteWithConditionParams
 */
export const getRoutePathConditionGuardProps = ({
  event,
}: ActionEventParams<
  unknown,
  RouteMachineServiceEvents
>): SendRouteWithConditionParams => {
  assertEvent(event, 'SEND_ROUTE_WITH_CONDITION');

  return event.params;
};

/**
 * Get SendSyncRouterStateParams from SEND_SYNC_ROUTER_STATE event
 * @param ActionEventParams<undefined, RouteMachineServiceEvents>
 * @returns SendSyncRouterStateParams
 */
export const getSyncRouterStateParams = (
  { event }: ActionEventParams<unknown, RouteMachineServiceEvents>,
  route: string
): SendSyncRouterStateParams => {
  assertEvent(event, 'SEND_SYNC_ROUTER_STATE');

  return {
    path: event.params.path,
    route,
  };
};

/**
 * Gets SendRouteApplicationErrorParams from SEND_ROUTE_APPLICATION_ERROR event
 * @param ActionEventParams<undefined, RouteMachineServiceEvents>
 * @returns SendRouteApplicationErrorParams
 */
export const getRouteApplicationErrorParams = ({
  event,
}: ActionEventParams<
  unknown,
  RouteMachineServiceEvents
>): SendRouteApplicationErrorParams => {
  assertEvent(event, 'SEND_ROUTE_APPLICATION_ERROR');

  return event.params;
};

/**
 * Gets SendRouteBuyerErrorParams from SEND_ROUTE_BUYER_ERROR event
 * @param ActionEventParams<undefined, RouteMachineServiceEvents>
 * @returns SendRouteBuyerErrorParams
 */
export const getRouteBuyerErrorParams = ({
  event,
}: ActionEventParams<
  unknown,
  RouteMachineServiceEvents
>): SendRouteBuyerErrorParams => {
  assertEvent(event, 'SEND_ROUTE_BUYER_ERROR');

  return event.params;
};
