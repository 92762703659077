import { useContext } from 'react';
import { StateValue } from 'xstate';
import { useNamespace } from '../../lib/hooks/useNamespace';
import { RouteMachineServiceScreenProps } from './RouteMachineService';
import { RoutePathCondition } from './constants/routePathCondition';
import { EcommRoutingMachineContext } from './contexts/EcommRoutingMachineContext/EcommRoutingMachineContext';
import { InStoreRoutingMachineContext } from './contexts/InStoreRoutingMachineContext/InStoreRoutingMachineContext';
import { CartRoutingMachineContext } from './contexts/CartRoutingMachineContext/CartRoutingMachineContext';
import { RBCRoutingMachineContext } from './contexts/RBCRoutingMachineContext/RBCRoutingMachineContext';

export interface UseRouteMachineServiceValues
  extends RouteMachineServiceScreenProps {
  stateValue: StateValue | undefined;
}

/**
 * Hook to expose context values of the current route machine
 * @returns UseRouteMachineServiceValues
 */
export const useRouteMachineService = (): UseRouteMachineServiceValues => {
  const namespace = useNamespace();

  const ecommRoutingMachineService = useContext(EcommRoutingMachineContext);
  const inStoreRoutingMachineService = useContext(InStoreRoutingMachineContext);
  const cartRoutingMachineService = useContext(CartRoutingMachineContext);
  const rbcRoutingMachineService = useContext(RBCRoutingMachineContext);

  /**
   * Gets the active routing machine service namespace
   */
  const getActiveMachineService = () => {
    switch (namespace) {
      case 'alliance':
        return ecommRoutingMachineService?.service;
      case 'in-store/[uuid]':
        return inStoreRoutingMachineService?.service;
      case 'cart/[cartID]':
        return cartRoutingMachineService?.service;
      case 'rbc':
        return rbcRoutingMachineService?.service;
      default:
        return ecommRoutingMachineService?.service;
    }
  };

  /**
   * Gets the active routing machine isTransitioning state
   */
  const getActiveMachineServiceTransitioningState = () => {
    switch (namespace) {
      case 'alliance':
        return !!ecommRoutingMachineService?.isTransitioning;
      case 'in-store/[uuid]':
        return !!inStoreRoutingMachineService?.isTransitioning;
      case 'cart/[cartID]':
        return !!cartRoutingMachineService?.isTransitioning;
      case 'rbc':
        return !!rbcRoutingMachineService?.isTransitioning;
      default:
        return !!ecommRoutingMachineService?.isTransitioning;
    }
  };

  /**
   * Gets the active routing machine state value
   */
  const getActiveMachineServiceState = () => {
    const service = getActiveMachineService();
    return service?.getSnapshot().value;
  };

  const forward = (): void => {
    const service = getActiveMachineService();
    service?.send({
      type: 'SEND_FORWARD',
    });
  };

  const back = (): void => {
    const service = getActiveMachineService();
    service?.send({
      type: 'SEND_BACK',
    });
  };

  const routeWithCondition = (cond: RoutePathCondition): void => {
    const service = getActiveMachineService();
    service?.send({
      type: 'SEND_ROUTE_WITH_CONDITION',
      params: {
        cond,
      },
    });
  };

  return {
    forward,
    back,
    routeWithCondition,
    isTransitioning: getActiveMachineServiceTransitioningState(),
    stateValue: getActiveMachineServiceState(),
  };
};
