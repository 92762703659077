import { FunctionComponent } from 'react';
import { useRouteMachineService } from './useRouteMachineService';

/**
 * Allow formatted view of the RouteMachineService state
 * @returns JSX.Element
 */
export const RouteMachineServiceSubscriber: FunctionComponent = () => {
  const { stateValue } = useRouteMachineService();
  return (
    <pre className="text-sm">
      {JSON.stringify({ value: stateValue }, null, 2)}
    </pre>
  );
};
