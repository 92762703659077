import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { InspectionEvent } from 'xstate';
import { isLogoutEvent, isLogoutUnauthorizedEvent } from '../utils';
import { useAppData } from '../../AppDataContext';
import { useApplicationMachine } from '../../ApplicationMachineContext';
import { useBuyerMachine } from '../../BuyerMachineContext';
import { useLoadingManager } from '../../LoadingManager';
import { useToast } from '../../Toast';

/**
 * Inspects routes raised events for logout actions and executes some side effect actions
 * for notifications, state resets, and loader removal
 *
 * @returns (inspectionEvent: InspectionEvent) => void)
 */
export const useInspectLogoutRouterEvent = (): ((
  inspectionEvent: InspectionEvent
) => void) => {
  const intl = useIntl();
  const { removeLoader } = useLoadingManager();
  const { notify } = useToast();
  const { resetBuyerState } = useBuyerMachine();
  const { reset: resetAppState } = useAppData();
  const { resetApplicationState } = useApplicationMachine();

  const handleLogoutEvent = useCallback(() => {
    resetBuyerState();
    resetApplicationState();
    resetAppState();
    removeLoader?.('ROUTE_SERVICE');
  }, [removeLoader, resetAppState, resetApplicationState, resetBuyerState]);

  const notifyUnauthorized = useCallback(() => {
    notify({
      title: intl.formatMessage({
        defaultMessage: 'Session expired',
        description: 'Logout banner session expired title',
      }),
      message: intl.formatMessage({
        defaultMessage:
          'Your session has expired. Please enter your email to log back in or to restart your application.',
        description: 'Logout banner session expired copy',
      }),
    });
  }, [intl, notify]);

  return useCallback((inspectionEvent: InspectionEvent) => {
    if (inspectionEvent.type !== '@xstate.microstep') {
      return;
    }

    if (isLogoutEvent(inspectionEvent.event)) {
      handleLogoutEvent();
    }

    // Handle unauthorized logout event
    if (isLogoutUnauthorizedEvent(inspectionEvent.event)) {
      handleLogoutEvent();
      notifyUnauthorized();
    }
  }, []);
};
