import { useContext } from 'react';
import { StateFrom } from 'xstate';
import { useSelector } from '@xstate/react';
import { AuthResult, CodeResult } from '@ads-bread/shared/bread/codecs';
import { HandlerWrapper } from '../../lib/handlers/base';
import { SendCodeOptions } from '../../lib/handlers/send-code';
import { authenticationMachine } from './authenticationMachine';
import { AuthenticationMachineContext } from './AuthenticationMachineContext';
import { AuthorizeOptions } from './types/events';

type AuthenticationMachineSnapshot = StateFrom<typeof authenticationMachine>;

export interface UseAuthenticationMachineValues {
  state: AuthenticationMachineSnapshot;
  logout: () => void;
  logoutUnauthorized: () => void;
  authorize: HandlerWrapper<AuthorizeOptions, AuthResult>;
  sendCode: HandlerWrapper<SendCodeOptions, CodeResult>;
}

/**
 * Hook to interact with the authentication machine context
 * @returns UseAuthenticationMachineValues
 */
export const useAuthentication = (): UseAuthenticationMachineValues => {
  const authenticationService = useContext(AuthenticationMachineContext);

  if (!authenticationService) {
    throw new Error('Authentication service is null!');
  }

  const state = useSelector(authenticationService, (s) => s);

  const logoutWrapper = () => {
    authenticationService.send({ type: 'SEND_LOGOUT' });
  };

  const logoutUnauthorizedWrapper = () => {
    authenticationService.send({ type: 'SEND_LOGOUT_UNAUTHORIZED' });
  };

  const authorizeWrapper: HandlerWrapper<AuthorizeOptions, AuthResult> = async (
    authorizeOptions
  ) => {
    return new Promise((res, rej) => {
      authenticationService.send({
        type: 'SEND_AUTHENTICATION_REQUEST',
        params: {
          context: state.context,
          authorizeOptions,
          resolve: res,
          reject: rej,
        },
      });
    });
  };

  const sendCodeWrapper: HandlerWrapper<SendCodeOptions, CodeResult> = async (
    options
  ) => {
    return new Promise((res, rej) => {
      authenticationService.send({
        type: 'SEND_CODE',
        params: {
          options,
          resolve: res,
          reject: rej,
        },
      });
    });
  };

  return {
    state,
    logout: logoutWrapper,
    logoutUnauthorized: logoutUnauthorizedWrapper,
    authorize: authorizeWrapper,
    sendCode: sendCodeWrapper,
  };
};
