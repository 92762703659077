import { assertEvent } from 'xstate';
import { APIError } from '@ads-bread/shared/bread/codecs';
import { ActionEventParams } from '../../../apply/lib/types';
import { BuyerContext } from './buyerMachine';
import {
  BuyerMachineEvents,
  CreateBuyerActorDone,
  FetchBuyerActorDone,
  SendBuyerReady,
  SendCreateBuyerParams,
  SendFetchBuyer,
  SendFetchingPartialBuyer,
  SendUpdateBuyerParams,
  UpdateBuyerActorDone,
} from './types/events';

/**
 * Gets input for create buyer actor
 * @param ActionEventParams<BuyerContext,BuyerMachineEvents>
 * @returns SendCreateBuyerParams
 */
export const getCreateBuyerInput = ({
  event,
}: ActionEventParams<
  BuyerContext,
  BuyerMachineEvents
>): SendCreateBuyerParams => {
  assertEvent(event, 'SEND_CREATE_BUYER');

  return event.params;
};

/**
 * Gets input for update buyer actor
 * @param ActionEventParams<BuyerContext,BuyerMachineEvents>
 * @returns SendUpdateBuyerParams
 */
export const getUpdateBuyerInput = ({
  event,
}: ActionEventParams<
  BuyerContext,
  BuyerMachineEvents
>): SendUpdateBuyerParams => {
  assertEvent(event, 'SEND_UPDATE_BUYER');

  return event.params;
};

/**
 * Gets event for fetch buyer actor
 * @param ActionEventParams<BuyerContext,BuyerMachineEvents>
 * @returns SendFetchBuyer
 */
export const getFetchBuyerInput = ({
  event,
}: ActionEventParams<BuyerContext, BuyerMachineEvents>): SendFetchBuyer => {
  assertEvent(event, 'SEND_FETCH_BUYER');

  return event;
};

/**
 * Gets event for fetch partial buyer actor
 * @param ActionEventParams<BuyerContext,SendFetchingPartialBuyer>
 * @returns SendUpdateBuyerParams
 */
export const getFetchPartialBuyerInput = ({
  event,
}: ActionEventParams<
  BuyerContext,
  BuyerMachineEvents
>): SendFetchingPartialBuyer => {
  assertEvent(event, 'SEND_FETCHING_PARTIAL_BUYER');

  return event;
};

/**
 * Gets event for prepare cart buyer actor
 * @param ActionEventParams<BuyerContext,SendFetchingPartialBuyer>
 * @returns SendBuyerReady
 */
export const getPrepareCartBuyerInput = ({
  event,
}: ActionEventParams<BuyerContext, BuyerMachineEvents>): SendBuyerReady => {
  assertEvent(event, 'SEND_BUYER_READY');

  return event;
};

/**
 * Get error from FetchBuyerActorDone | UpdateBuyerActorDone | CreateBuyerActorDone
 * @param
 * ActionEventParams<BuyerContext,FetchBuyerActorDone | UpdateBuyerActorDone | CreateBuyerActorDone>
 * @returns APIError | null
 */
export const getEvaluateBuyerErrorInput = ({
  event,
}: ActionEventParams<
  BuyerContext,
  FetchBuyerActorDone | UpdateBuyerActorDone | CreateBuyerActorDone
>): APIError | null => {
  return event.output.buyerRes.error;
};
