import { FunctionComponent, createContext, useMemo } from 'react';
import { ActorRefFrom, InspectionEvent, StateFrom } from 'xstate';
import { useActorRef } from '@xstate/react';
import { useLoadingManager } from '../../../LoadingManager';
import {
  IntroPageABPhaseTwoResult,
  useFeatureFlags,
} from '../../../FeatureFlagsContext';
import { useBuyerMachine } from '../../../BuyerMachineContext';
import { useAuthentication } from '../../../AuthenticationMachineContext';
import { useApplicationMachine } from '../../../ApplicationMachineContext';
import { getLoadingIndicatorActions } from '../../actions';
import {
  RouteMachineContextProps,
  RouteMachineServiceProps,
} from '../../RouteMachineService';
import { getDefaultApplicationErrorRouteGuards } from '../../guards/applicationMachineErrors';
import { getDefaultBuyerErrorRouteGuards } from '../../guards/buyerMachineErrors';
import { getRoutePathConditionGuards } from '../../guards/routePathConditions';
import { useApplicationMachineStateEventEmitter } from '../../hooks/useApplicationMachineStateEventEmitter';
import { useAuthenticationMachineStateEventEmitter } from '../../hooks/useAuthenticationMachineStateEventEmitter';
import { useBuyerApplicationEventEmitter } from '../../hooks/useBuyerApplicationEventEmitter';
import { useBuyerMachineStateEventEmitter } from '../../hooks/useBuyerMachineStateEventEmitter';
import { useHandleStateRouteTransition } from '../../hooks/useHandleStateRouteTransition';
import { useRouteTransitionActions } from '../../hooks/useRouteTransitionActions';
import { useSyncRouterStateEventEmitter } from '../../hooks/useSyncRouterStateEventEmitter';
import { useInspectLogoutRouterEvent } from '../../hooks/useInspectLogoutRouterEvent';
import { routingMachine } from './ecommRoutingMachine';

export type EcommRoutingMachineSnapshot = StateFrom<typeof routingMachine>;

type EcommRoutingMachineActorRef = ActorRefFrom<typeof routingMachine>;

export const EcommRoutingMachineContext =
  createContext<RouteMachineContextProps<EcommRoutingMachineActorRef | null> | null>(
    null
  );

/**
 * Provider to support Ecomm routing
 * @param React.Children
 * @returns FunctionComponent<RouteMachineServiceProps>
 */
export const EcommRoutingMachineProvider: FunctionComponent<RouteMachineServiceProps> =
  ({ children }) => {
    const { state: authState } = useAuthentication();
    const { state: buyerState, buyerID } = useBuyerMachine();
    const {
      idVerification: { backImageData },
    } = useApplicationMachine();
    const { enableAlloyJourney, introPageABPhaseTwo, isExchangedStreamlined } =
      useFeatureFlags();
    const { addLoader, removeLoader } = useLoadingManager();
    const inspectLogoutRouterEvent = useInspectLogoutRouterEvent();
    // Handle state route transitions
    const { isTransitioning, handleStateRouteTransition } =
      useHandleStateRouteTransition();

    const routingMachineService = useActorRef(
      routingMachine.provide({
        guards: {
          isIDVerificationAlloy: () => enableAlloyJourney,
          isExchangedNonStreamlined: () => {
            return !!buyerID && !isExchangedStreamlined;
          },
          isExchangedStreamlined: () => {
            return !!buyerID && isExchangedStreamlined;
          },
          isBuyerReadyComplete: () => {
            return buyerState.matches({ ready: 'complete' });
          },
          hasBackImageData: () => {
            return !!backImageData;
          },
          isIntroEmailAB: () => {
            return (
              introPageABPhaseTwo === IntroPageABPhaseTwoResult.EmailAdded ||
              introPageABPhaseTwo ===
                IntroPageABPhaseTwoResult.SingleStackDualSideBySideEmailAdded
            );
          },
          isUnauthenticatedInitial: () => {
            return authState.matches({ unAuthenticated: 'initial' });
          },
          // Route path condition guards
          ...getRoutePathConditionGuards(),
          // Application Error condition routing checks
          ...getDefaultApplicationErrorRouteGuards(),
          // Buyer Error condition routing checks
          ...getDefaultBuyerErrorRouteGuards(),
        },
        actions: {
          ...getLoadingIndicatorActions({ addLoader, removeLoader }),
        },
      }),
      {
        inspect: (inspectionEvent: InspectionEvent) => {
          handleStateRouteTransition(inspectionEvent);
          inspectLogoutRouterEvent(inspectionEvent);
        },
      }
    );

    // Shared event emitters
    useSyncRouterStateEventEmitter(routingMachineService);
    useBuyerApplicationEventEmitter(routingMachineService);
    useApplicationMachineStateEventEmitter(routingMachineService);
    useBuyerMachineStateEventEmitter(routingMachineService);
    useAuthenticationMachineStateEventEmitter(routingMachineService);

    const { forward, back, routeWithCondition } = useRouteTransitionActions(
      routingMachineService
    );

    const value = useMemo(() => {
      return { service: routingMachineService, isTransitioning };
    }, [routingMachineService, isTransitioning]);

    return (
      <EcommRoutingMachineContext.Provider value={value}>
        {children({
          isTransitioning,
          forward,
          back,
          routeWithCondition,
        })}
      </EcommRoutingMachineContext.Provider>
    );
  };
