import { useEffect } from 'react';
import { useAuthentication } from '../../AuthenticationMachineContext';
import { RouteMachineServiceEventEmitter } from '../types/events';

/**
 * Subscribes to the authentication machine state and emits events to be handled
 * based on those states.
 * @param routingMachineService RouteMachineServiceEventEmitter
 */
export const useAuthenticationMachineStateEventEmitter = (
  routingMachineService: RouteMachineServiceEventEmitter
): void => {
  const { state: authState } = useAuthentication();

  // Authentication state subscriber
  useEffect(() => {
    if (authState.matches({ unAuthenticated: 'complete' })) {
      routingMachineService.send({ type: 'SEND_RESET_ROUTER_STATE' });
    } else if (authState.matches({ unAuthenticated: 'unauthorized' })) {
      routingMachineService.send({
        type: 'SEND_RESET_ROUTER_STATE_UNAUTHORIZED',
      });
    } else if (authState.matches({ unAuthenticated: 'retryWithPII' })) {
      // Auth retry with PII event
      routingMachineService.send({
        type: 'SEND_ROUTE_AUTH_RETRY_WITH_PII',
      });
    }
  }, [authState]);
};
