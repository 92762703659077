import { assertEvent } from 'xstate';
import { ActionEventParams } from '../../../apply/lib/types';
import {
  AuthenticationMachineEvents,
  AuthenticationRequestParams,
  SendCodeParams,
  SendLogout,
  SendLogoutUnauthorized,
} from './types/events';
import { AuthenticationContext } from './authenticationMachine';

/**
 * Gets input for sendCode actor
 * @param ActionEventParams<AuthenticationContext, AuthenticationMachineEvents>
 * @returns SendCodeParams
 */
export const getSendCodeInput = ({
  event,
}: ActionEventParams<
  AuthenticationContext,
  AuthenticationMachineEvents
>): SendCodeParams => {
  assertEvent(event, 'SEND_CODE');
  return {
    options: event.params.options,
    resolve: event.params.resolve,
    reject: event.params.reject,
  };
};

/**
 * Gets input for logout actor
 * @param ActionEventParams<AuthenticationMachineEvents, AuthenticationMachineEvents>
 * @returns SendLogout | SendLogoutUnauthorized
 */
export const getLogoutInput = ({
  event,
}: ActionEventParams<AuthenticationContext, AuthenticationMachineEvents>):
  | SendLogout
  | SendLogoutUnauthorized => {
  assertEvent(event, ['SEND_LOGOUT', 'SEND_LOGOUT_UNAUTHORIZED']);
  return event;
};

/**
 * Get input for authorize actor
 * @param ActionEventParams<AuthenticationMachineEvents, AuthenticationMachineEvents>
 * @returns AuthenticationRequestParams
 */
export const getAuthorizeInput = ({
  event,
  context,
}: ActionEventParams<
  AuthenticationContext,
  AuthenticationMachineEvents
>): AuthenticationRequestParams => {
  assertEvent(event, 'SEND_AUTHENTICATION_REQUEST');
  return {
    context,
    authorizeOptions: event.params.authorizeOptions,
    resolve: event.params.resolve,
    reject: event.params.reject,
  };
};
