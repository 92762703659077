import React, { createContext, useContext, useEffect, useState } from 'react';
import { FlagResponse } from '@getbread/feature-flag-sdk';
import { getFeatureFlags } from '../lib/handlers/get-feature-flags';
import { logger } from '../lib/logger';
import { FCWithChildren } from '../lib/types';
import { setAdditionalContext } from '../lib/analytics';
import { useAppConfig } from './AppConfigContext';
import { useDeviceID, useExperienceKeys } from './XPropsContext';
import { Loader } from './LoadingManager';

export enum IntroPageABPhaseTwoResult {
  Control = 'control', //intro stack view. shown to population for merchants with feature flag
  EmailAdded = 'email_added',
  SingleStackDualSideBySideEmailAdded = 'single_stack_dual_side_by_side_email_added',
  SingleStackDualSideBySide = 'single_stack_dual_side_by_side',
  Off = 'off', //intro stack view. shown to  population for merchants without feature flag
}

export interface FeatureFlags {
  enableIDVerification: boolean;
  enableSplitPayAuthEnhancement: boolean;
  disableColoradoAPR: boolean;
  introPageABPhaseTwo: IntroPageABPhaseTwoResult;
  enableAlloyJourney: boolean;
  enableWebOTPAutofill: boolean;
  isExchangedStreamlined: boolean;
}

export const featureFlagDefaults: FeatureFlags = {
  enableIDVerification: false,
  enableSplitPayAuthEnhancement: false,
  disableColoradoAPR: false,
  introPageABPhaseTwo: IntroPageABPhaseTwoResult.Off,
  enableAlloyJourney: false,
  enableWebOTPAutofill: false,
  isExchangedStreamlined: false,
};

const FeatureFlagsContext = createContext<FeatureFlags>(featureFlagDefaults);

interface FeatureFlagsProviderProps {
  removeLoader: (loader: Loader) => void;
}

/**
 * Checks feature flag response for any flags that match a common stem.
 * If any of the shared flags are enabled this function will return true.
 * If there aren't any results or all of the shared flags are disabled it
 * will return false.
 *
 * @LORE Feature flags use the ID as if it were a UUID so certain changes
 * can't be made once it is created. There is also a desire to do phased
 * roll-outs where multiple feature flags sharing a common stem can controls
 * different subsets of our merchant base.
 *
 * @param flags - The response object from the feature flag service.
 * @param stem - The common stem that all flags for a specific functionality
 * should share. For example, 'my-project' would match 'my-project-rollout-1'.
 * @param defaultVal - The default value to return if no flags are found.
 */
const isMultiFlagEnabled = (
  flags: Record<string, FlagResponse>,
  stem: string,
  defaultVal = false
): boolean => {
  const newFlags = Object.entries(flags).filter(([key]) => key.includes(stem));

  if (!newFlags.length) {
    return defaultVal;
  }

  return newFlags.some(([, value]) => value.result === 'true');
};

export const FeatureFlagsProvider: FCWithChildren<FeatureFlagsProviderProps> =
  ({ removeLoader, children }) => {
    const [featureFlags, setFeatureFlags] =
      useState<FeatureFlags>(featureFlagDefaults);
    const deviceID = useDeviceID();
    const { merchantID, programID } = useExperienceKeys();
    const { apiUrl, tenantID, featureFlagsEnabled } = useAppConfig();

    useEffect(() => {
      const fetchFeatureFlags = async () => {
        let flagResults: FeatureFlags = featureFlagDefaults;
        let response: Record<string, FlagResponse> | null = null;

        try {
          response = await getFeatureFlags(
            apiUrl,
            merchantID,
            tenantID,
            deviceID,
            programID
          );

          const enableIDVerification =
            response['id_verification']?.result === 'true';
          const enableSplitPayAuthEnhancement =
            response['splitpay_payment_auth_enhancement']?.result === 'true';
          const disableColoradoAPR = response['ColoradoAPR']?.result === 'true';
          const introPageABPhaseTwo =
            (response['intro_page_ab_phase_two']
              ?.result as IntroPageABPhaseTwoResult) ||
            IntroPageABPhaseTwoResult.Off;
          const enableAlloyJourney = isMultiFlagEnabled(
            response,
            'enable-alloy'
          );

          const enableWebOTPAutofill =
            response['webotp_autofill']?.result === 'true';

          const isExchangedStreamlined =
            response['exchanged-streamlined']?.result === 'true';

          flagResults = {
            enableIDVerification,
            enableSplitPayAuthEnhancement,
            disableColoradoAPR,
            introPageABPhaseTwo,
            enableAlloyJourney,
            enableWebOTPAutofill,
            isExchangedStreamlined,
          };

          setFeatureFlags(flagResults);
        } catch (err) {
          logger.error({ error: err }, 'Error accessing feature flags!');
        } finally {
          setAdditionalContext({
            feature_flags: JSON.stringify(flagResults).replace(/{|}|"/g, ''),
          });
          removeLoader('FEATURE_FLAGS');
        }
      };

      if (featureFlagsEnabled) {
        fetchFeatureFlags();
      }
    }, []);

    return (
      <FeatureFlagsContext.Provider value={featureFlags}>
        {children}
      </FeatureFlagsContext.Provider>
    );
  };

export const useFeatureFlags = (): FeatureFlags => {
  return useContext(FeatureFlagsContext);
};
