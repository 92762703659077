import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useNamespace } from '../../../lib/hooks/useNamespace';
import { RouteMachineServiceEventEmitter } from '../types/events';
import { getStateValueFromRoute } from '../utils';

/**
 * Subscribes to router beforePopState to emit a sync state event
 * @param routingMachineService RouteMachineServiceEventEmitter
 */
export const useSyncRouterStateEventEmitter = (
  routingMachineService: RouteMachineServiceEventEmitter
): void => {
  const { query, beforePopState } = useRouter();
  const namespace = useNamespace();

  useEffect(() => {
    beforePopState(({ as: pathname }) => {
      routingMachineService.send({
        type: 'SEND_SYNC_ROUTER_STATE',
        params: {
          path: getStateValueFromRoute(pathname, namespace, query),
        },
      });
      return true;
    });
  }, []);
};
