import { useCallback } from 'react';
import { RouteMachineServiceScreenProps } from '../RouteMachineService';
import { RoutePathCondition } from '../constants/routePathCondition';
import { RouteMachineServiceEventEmitter } from '../types/events';

type UseRouteTransitionActions = Omit<
  RouteMachineServiceScreenProps,
  'isTransitioning'
>;

/**
 * Returns actions to raise events related to route navigation
 * @param routingMachineService
 * @returns UseRouteTransitionActions
 */
export const useRouteTransitionActions = (
  routingMachineService: RouteMachineServiceEventEmitter
): UseRouteTransitionActions => {
  const forward = useCallback(() => {
    routingMachineService.send({
      type: 'SEND_FORWARD',
    });
  }, [routingMachineService]);

  const back = useCallback(() => {
    routingMachineService.send({
      type: 'SEND_BACK',
    });
  }, [routingMachineService]);

  const routeWithCondition = useCallback(
    (cond: RoutePathCondition) => {
      routingMachineService.send({
        type: 'SEND_ROUTE_WITH_CONDITION',
        params: {
          cond,
        },
      });
    },
    [routingMachineService]
  );

  return {
    forward,
    back,
    routeWithCondition,
  };
};
