import { TransitionConfigOrTarget, MachineContext } from 'xstate';
import { ActionEventParams } from '../../../apply/lib/types';
import {
  SendSyncRouterState,
  SendResetRouterState,
  SendResetRouterStateUnAuthorized,
  SendRouteApplicationError,
  SendRouteBuyerError,
  RouteMachineServiceEvents,
  SendSyncRouterStateParams,
  SendRouteBuyerErrorParams,
  SendRouteApplicationErrorParams,
} from './types/events';
import {
  getRouteApplicationErrorParams,
  getRouteBuyerErrorParams,
  getSyncRouterStateParams,
} from './actions';

/**
 * Shared guard to check if the current route is equal to the state node
 * @param ActionEventParams<unknown, RouteMachineServiceEvents>,
 * @param SendSyncRouterStateParams
 * @returns boolean
 */
export const isRouteEqualToStateNode = (
  _: ActionEventParams<unknown, RouteMachineServiceEvents>,
  params: SendSyncRouterStateParams
): boolean => {
  return params.path === params.route;
};
/**
 * Allows you to describe a transition config or target
 * TransitionConfigOrTarget<
 *    TContext extends MachineContext,
 *    TExpressionEvent extends EventObject,
 *    TEvent extends EventObject,
 *    TActor extends ProvidedActor,
 *    TAction extends ParameterizedObject,
 *    TGuard extends ParameterizedObject,
 *    TDelay extends string,
 *    TEmitted extends EventObject,
 *    TMeta extends MetaObject
 *  >
 */

type SyncStateTargetsGuard = {
  type: 'isRouteEqualToStateNode';
  params: SendSyncRouterStateParams;
};

type SendResetRouterStateUnauthorizedAction = {
  type: 'addLoadingIndicator';
  params: unknown;
};

type SendResetRouterStateAction = {
  type: 'addLoadingIndicator';
  params: unknown;
};

type SendRouteBuyerErrorGuard = {
  type: 'isBuyerVerificationError';
  params: SendRouteBuyerErrorParams;
};

type SendRouteApplicationErrorGuard = {
  type:
    | 'isAgeAlabamaMilitaryError'
    | 'isAgeIneligibleError'
    | 'isBuyerHashedFailedError'
    | 'isBuyerSkippedInstallmentsError'
    | 'isBuyerStatusIneligibleError'
    | 'isCreditDenialError'
    | 'isCreditFreezeError'
    | 'isKYCDenialError'
    | 'isLocationIneligibleError'
    | 'isMaxCardIneligibleError'
    | 'isNeedsActionWarningError'
    | 'isOFACDenialError'
    | 'isOutstandingLoansIneligibleError'
    | 'isPreviousDenialIneligibleError'
    | 'poBoxAddressIneligibleError'
    | 'isInvalidAddressNoMatchIneligibleError'
    | 'isSanctionsDenialError'
    | 'isFraudAlertDenialError'
    | 'isFraudDenialError'
    | 'isCapacityRecheckError'
    | 'isIDVerificationFailure'
    | 'isIDVerificationMaxAttemptsExceeded'
    | 'isIDVerificationNeedsActionWarning';
  params: SendRouteApplicationErrorParams;
};

interface RoutingMachineLevelEvents {
  SEND_SYNC_ROUTER_STATE?: TransitionConfigOrTarget<
    MachineContext,
    SendSyncRouterState,
    RouteMachineServiceEvents,
    never,
    never,
    SyncStateTargetsGuard,
    never,
    never,
    never
  >;
  SEND_RESET_ROUTER_STATE?: TransitionConfigOrTarget<
    MachineContext,
    SendResetRouterState,
    RouteMachineServiceEvents,
    never,
    SendResetRouterStateAction,
    never,
    never,
    never,
    never
  >;
  SEND_RESET_ROUTER_STATE_UNAUTHORIZED?: TransitionConfigOrTarget<
    MachineContext,
    SendResetRouterStateUnAuthorized,
    RouteMachineServiceEvents,
    never,
    SendResetRouterStateUnauthorizedAction,
    never,
    never,
    never,
    never
  >;
  SEND_ROUTE_APPLICATION_ERROR?: TransitionConfigOrTarget<
    MachineContext,
    SendRouteApplicationError,
    RouteMachineServiceEvents,
    never,
    never,
    SendRouteApplicationErrorGuard,
    never,
    never,
    never
  >;
  SEND_ROUTE_BUYER_ERROR?: TransitionConfigOrTarget<
    MachineContext,
    SendRouteBuyerError,
    RouteMachineServiceEvents,
    never,
    never,
    SendRouteBuyerErrorGuard,
    never,
    never,
    never
  >;
}

/**
 * Get shared top level events for routing machine. These handle top level machine
 * state error events and sync state events for browser back actions.
 * @param Routes Routes enum from machine
 * @returns RoutingMachineLevelEvents
 */
export function getRoutingMachineLevelEvents(
  Routes: Record<string, string>
): RoutingMachineLevelEvents {
  return {
    SEND_SYNC_ROUTER_STATE: Object.values(Routes).map((route) => ({
      target: `.${route}`,
      guard: {
        type: 'isRouteEqualToStateNode',
        params: (args) => getSyncRouterStateParams(args, route),
      },
    })),
    SEND_RESET_ROUTER_STATE: {
      actions: [
        {
          type: 'addLoadingIndicator',
        },
      ],
      target: `.${Routes.Email}`,
    },
    SEND_RESET_ROUTER_STATE_UNAUTHORIZED: {
      actions: [
        {
          type: 'addLoadingIndicator',
        },
      ],
      target: `.${Routes.Email}`,
    },
    SEND_ROUTE_APPLICATION_ERROR: [
      {
        guard: {
          type: 'isAgeAlabamaMilitaryError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.AgeAlabamaMilitary}`,
      },
      {
        guard: {
          type: 'isAgeIneligibleError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.AgeIneligible}`,
      },
      {
        guard: {
          type: 'isBuyerHashedFailedError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.BuyerHashFailed}`,
      },
      {
        guard: {
          type: 'isBuyerSkippedInstallmentsError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.BuyerSkippedInstallments}`,
      },
      {
        guard: {
          type: 'isBuyerStatusIneligibleError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.BuyerStatusIneligible}`,
      },
      {
        guard: {
          type: 'isCreditDenialError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.CreditDenial}`,
      },
      {
        guard: {
          type: 'isCreditFreezeError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.CreditFreeze}`,
      },
      {
        guard: {
          type: 'isKYCDenialError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.KycDenial}`,
      },
      {
        guard: {
          type: 'isLocationIneligibleError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.LocationIneligible}`,
      },
      {
        guard: {
          type: 'isMaxCardIneligibleError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.MaxCardIneligible}`,
      },
      {
        guard: {
          type: 'isNeedsActionWarningError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.NeedsActionWarning}`,
      },
      {
        guard: {
          type: 'isOFACDenialError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.OFACDenial}`,
      },
      {
        guard: {
          type: 'isOutstandingLoansIneligibleError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.OutstandingLoansIneligible}`,
      },
      {
        guard: {
          type: 'isPreviousDenialIneligibleError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.PreviousDenialIneligible}`,
      },
      {
        guard: {
          type: 'poBoxAddressIneligibleError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.POBoxAddressIneligible}`,
      },
      {
        guard: {
          type: 'isInvalidAddressNoMatchIneligibleError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.InvalidAddressNoMatchIneligible}`,
      },
      {
        guard: {
          type: 'isSanctionsDenialError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.SanctionDenial}`,
      },
      {
        guard: {
          type: 'isFraudAlertDenialError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.FraudAlertDenial}`,
      },
      {
        guard: {
          type: 'isFraudDenialError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.FraudDenial}`,
      },
      {
        guard: {
          type: 'isCapacityRecheckError',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.CapacityRecheck}`,
      },
      {
        guard: {
          type: 'isIDVerificationFailure',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.IDVerificationFailure}`,
      },
      {
        guard: {
          type: 'isIDVerificationMaxAttemptsExceeded',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.IDVerificationMaxAttemptsExceeded}`,
      },
      {
        guard: {
          type: 'isIDVerificationNeedsActionWarning',
          params: getRouteApplicationErrorParams,
        },
        target: `.${Routes.IDVerificationNeedsActionWarning}`,
      },
      {
        target: `.${Routes.Unknown}`,
      },
    ],
    SEND_ROUTE_BUYER_ERROR: [
      {
        guard: {
          type: 'isBuyerVerificationError',
          params: getRouteBuyerErrorParams,
        },
        target: `.${Routes.VerifiedEmail}`,
      },
      {
        target: `.${Routes.Unknown}`,
      },
    ],
  };
}
