import { ActionEventParams } from '../../../../apply/lib/types';
import { RoutePathCondition } from '../constants/routePathCondition';
import {
  RouteMachineServiceEvents,
  SendRouteWithConditionParams,
} from '../types/events';

export type RoutePathConditionGuards = {
  isBillingAddressRouteCondition: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteWithConditionParams
  ) => boolean;
  isIDVerificationUploadFrontRouteCondition: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteWithConditionParams
  ) => boolean;
  isIDVerificationUploadBackRouteCondition: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteWithConditionParams
  ) => boolean;
  isVirtualCardErrorRouteCondition: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteWithConditionParams
  ) => boolean;
  isVirtualCardFailedRouteCondition: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteWithConditionParams
  ) => boolean;
  isPaymentRouteCondition: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteWithConditionParams
  ) => boolean;
  isUnknownErrorRouteCondition: (
    _: ActionEventParams<unknown, RouteMachineServiceEvents>,
    params: SendRouteWithConditionParams
  ) => boolean;
};

/**
 * Shared Guards for route path condition event handling
 * @returns RoutePathConditionGuards
 */
export const getRoutePathConditionGuards = (): RoutePathConditionGuards => {
  return {
    isBillingAddressRouteCondition: (_, params) => {
      return RoutePathCondition.BillingAddress === params.cond;
    },
    isIDVerificationUploadFrontRouteCondition: (_, params) => {
      return RoutePathCondition.IDVerificationUploadFront === params.cond;
    },
    isIDVerificationUploadBackRouteCondition: (_, params) => {
      return RoutePathCondition.IDVerificationUploadBack === params.cond;
    },
    isVirtualCardErrorRouteCondition: (_, params) => {
      return RoutePathCondition.VirtualCardError === params.cond;
    },
    isVirtualCardFailedRouteCondition: (_, params) => {
      return RoutePathCondition.VirtualCardErrorFailed === params.cond;
    },
    isPaymentRouteCondition: (_, params) => {
      return RoutePathCondition.Payment === params.cond;
    },
    isUnknownErrorRouteCondition: (_, params) => {
      return RoutePathCondition.UnknownError === params.cond;
    },
  };
};
