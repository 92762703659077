/**
 * There are some instances where we need to route conditionally.
 * This should be used in rare circumstances, but is sometimes necessary
 */
export enum RoutePathCondition {
  BillingAddress = '/billing-address',
  IDVerificationUploadFront = '/id-verification/upload-front',
  IDVerificationUploadBack = '/id-verification/upload-back',
  VirtualCardError = '/card-error',
  VirtualCardErrorFailed = '/card-error-failed',
  Payment = '/payment',
  UnknownError = '/unknown-error',
}
